import { Grid, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React from 'react';
import PaymentDetails from './PaymentDetails';
import ShippingDetails from './ShippingDetails';

export default function ReviewOrder() {
    const { values: formValues } = useFormikContext();
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Resumo da assinatura
            </Typography>
            <Grid container spacing={2}>
                <ShippingDetails formValues={formValues} />
                <PaymentDetails formValues={formValues} />
            </Grid>
        </React.Fragment>
    );
}
