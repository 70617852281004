const rules = {
    user: {
        static: [],
    },

    admin: {
        static: [
            'dashboard:view',
            'drawer-admin-items:view',
            'tickets-manager:showall',
            'user-modal:editProfile',
            'user-modal:editQueues',
            'ticket-options:deleteTicket',
            'ticket-options:transferWhatsapp',
            'contacts-page:deleteContact',
            'contact-page:exports',
            'connections-page:actionButtons',
            'connections-page:addConnection',
            'connections-page:editOrDeleteConnection',
        ],
    },
};

export default rules;
