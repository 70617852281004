import api from '../../services/api';

const useDocumentGenerator = () => {
    const generate = async ({ templateId, ...data }) => {
        const { data: responseData } = await api.request({
            url: `/documents/templates/${templateId}/generate`,
            method: 'post',
            data,
            responseType: 'blob',
        });
        return responseData;
    };

    return {
        generate,
    };
};

export default useDocumentGenerator;
