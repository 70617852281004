import { addListToDropdown, Collection, createDropdown, Plugin, ButtonView, ViewModel, Widget } from 'ckeditor5';
import { variableUtils } from '../../../utils/variableUtils';


const variableCategories = variableUtils()

export default class Variables extends Plugin {
    static get requires() {
        return [Widget];
    }

    init() {
        const editor = this.editor;

        editor.ui.componentFactory.add('userVariables', (locale) => {
            const dropdown = createDropdown(locale, ButtonView);

            dropdown.buttonView.set({
                withText: true,
                label: 'Variáveis',
                class: 'user-variables-button',
            });

            const items = new Collection();

            for (const category in variableCategories) {
                items.add({
                    type: 'button',
                    model: new ViewModel({
                        label: category,
                        withText: true,
                        isEnabled: false,
                        class: 'category-label',
                    }),
                });

                variableCategories[category].forEach((variable) => {
                    items.add({
                        type: 'button',
                        model: new ViewModel({
                            withText: true,
                            label: variable.label,
                            value: variable.value,
                        }),
                    });
                });

                items.add({
                    type: 'separator',
                    model: new ViewModel({}),
                });
            }

            addListToDropdown(dropdown, items);

            dropdown.listenTo(dropdown, 'execute', (evt) => {
                console.log(evt.source.value);
                editor.model.change((writer) => {
                    editor.model.insertContent(writer.createText(`{{${evt.source.value}}}`));
                });
            });

            dropdown.buttonView.on('execute', () => {
                dropdown.isOpen = !dropdown.isOpen;
            });

            return dropdown;
        });
    }
}
