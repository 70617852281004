import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    makeStyles
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import { Calendar } from '../(molecules)/Calendar';
import useExternalCalendar from '../../hooks/useExternalCalendar';
import { i18n } from '../../translate/i18n';

const useStyles = makeStyles(() => ({
    selectContainer: {
        width: '100%',
        textAlign: 'left',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ExternalCalendarUpdateCalendarNameModal = ({ open, onClose, onSuccess, connection }) => {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [selected, setSelected] = useState(null);
    const [calendars, setCalendars] = useState([]);
    const { listCalendars } = useExternalCalendar();

    useEffect(() => {
        async function fetchData() {
            const data = await listCalendars(connection.id);
            setCalendars(data);
            if (connection.calendarId) {
                setSelected(data.find((calendar) => calendar.id === connection.calendarId));
            }
            setLoading(false);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
        const value = e.target.value;
        setSelected(calendars.find((calendar) => calendar.id === value));
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth scroll="paper">
            <DialogTitle>{i18n.t('connections.externalCalendar.updateCalendarName.title')}</DialogTitle>
            <DialogContent>
                <FormControl className={classes.selectContainer} margin="dense" variant="outlined">
                    <InputLabel id="calendar-label">
                        {i18n.t('connections.externalCalendar.updateCalendarName.calendar')}
                    </InputLabel>
                    <Select
                        labelId="calendar-label"
                        disabled={isSubmitting || !calendars?.length}
                        value={selected?.id || (isLoading ? i18n.t('loading') : '')}
                        onChange={handleChange}
                        label={i18n.t('connections.externalCalendar.updateCalendarName.calendar')}
                        margin="dense"
                        variant="outlined"
                    >
                        {isLoading && (
                            <MenuItem disabled value={i18n.t('loading')}>
                                {i18n.t('loading')}
                            </MenuItem>
                        )}
                        {calendars?.map((calendar) => (
                            <MenuItem key={calendar.id} value={calendar.id}>
                                <Calendar calendar={calendar} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    disabled={!selected || isSubmitting}
                    onClick={async () => {
                        setSubmitting(true);
                        if (onSuccess) {
                            await onSuccess(selected.id);
                        } else {
                            onClose();
                        }
                        setSubmitting(false);
                    }}
                    color="primary"
                >
                    {i18n.t('connections.externalCalendar.updateCalendarName.save')}
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
                <Button variant="outlined" onClick={onClose} color="secondary">
                    {i18n.t('connections.externalCalendar.updateCalendarName.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExternalCalendarUpdateCalendarNameModal;
