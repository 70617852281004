import api from '../services/api';

const variableCategories = {
    'Informações Pessoais': [
        { label: 'Nome', value: 'contact.name' },
        { label: 'Telefone', value: 'contact.number' },
        { label: 'Email', value: 'contact.email' },
        { label: 'Documento', value: 'contact.document' },
        { label: 'RG', value: 'contact.nationalId' },
        { label: 'Estado Civil', value: 'contact.maritalStatus' },
        { label: 'Nacionalidade', value: 'contact.nationality' },
    ],
    'Data - Nascimento / Abertura': [
        { label: 'Data Completa (Ex: 01 de Janeiro de 2024)', value: 'contactDate.dayAndMonthYear' },
        { label: 'Data por Extenso (Ex: 01 de Janeiro)', value: 'contactDate.dayAndMonth' },
        { label: 'Formato DD/MM/AAAA', value: 'contactDate.ddmmyyyy' },
        { label: 'Formato DD/MM/AA', value: 'contactDate.ddmmyy' },
    ],
    'Endereço': [
        { label: 'Endereço', value: 'contact.address' },
        { label: 'CEP', value: 'contact.zipCode' },
        { label: 'N° (Endereço)', value: 'contact.residentialNumber' },
        { label: 'Complemento (Endereço)', value: 'contact.complement' },
        { label: 'Cidade', value: 'contact.city' },
        { label: 'Estado', value: 'contact.state' },
        { label: 'Bairro', value: 'contact.neighborhood' },
    ],
    'Profissional': [
        { label: 'Profissão', value: 'contact.profession' },
    ],
    'Configurações de data': [
        { label: 'Dia (Número)', value: 'date.day' },
        { label: 'Dia da Semana (Ex: quarta-feira)', value: 'date.weekDay' },
        { label: 'Mês (Nome Completo)', value: 'date.stringMonth' },
        { label: 'Mês (Número)', value: 'date.numberMonth' },
        { label: 'Ano (Últimos Dois Dígitos)', value: 'date.shortYear' },
        { label: 'Ano (Quatro Dígitos)', value: 'date.completeYear' },
        { label: 'Data Completa (Ex: 01 de Janeiro de 2024)', value: 'date.dayAndMonthYear' },
        { label: 'Data por Extenso (Ex: 01 de Janeiro)', value: 'date.dayAndMonth' },
        { label: 'Formato DD/MM/AAAA', value: 'date.ddmmyyyy' },
        { label: 'Formato DD/MM/AA', value: 'date.ddmmyy' },
    ],
    'Campos Globais': []
};

let lastCallTime = 0;

function updateExtraKeys(newLabels) {
    const updatedExtraKeys = newLabels.map(name => ({
        label: name,
        value: `contact.extraInfo.${name}`
    }));

    variableCategories['Campos Globais'] = updatedExtraKeys;

    const existingLabels = variableCategories['Campos Globais'].map(item => item.label);
    variableCategories['Campos Globais'] = variableCategories['Campos Globais'].filter(item => newLabels.includes(item.label));
}

async function fetchAndUpdateExtraKeys() {
    try {
        const { data } = await api.get('/global-extra-info');
        console.info(data);

        const newLabels = data.map(item => item.name);
        updateExtraKeys(newLabels);

        console.info('Campos Globais atualizadas:', variableCategories['Campos Globais']);
        return true;
    } catch (error) {
        console.error('Erro ao atualizar Campos Globais:', error);
        return false;
    }
}


export async function getVariablesWithUserData() {
    const currentTime = Date.now();
    if (currentTime - lastCallTime < 1000) {
        console.warn('A função getVariablesWithUserData não pode ser chamada mais de uma vez por segundo.');
        return false;
    }

    lastCallTime = currentTime; 
    return fetchAndUpdateExtraKeys();
}

export function variableUtils() {
    return variableCategories;
}
