import { Chip, makeStyles } from '@material-ui/core';
import React from 'react';
import { i18n } from '../../translate/i18n';
import OutlinedDiv from '../OutlinedDiv';

const useStyles = makeStyles((theme) => ({
    chip: {
        margin: theme.spacing(0.5),
        cursor: 'pointer',
    },
}));

export const messageVariables = [
    {
        name: i18n.t('messageVariablesPicker.vars.contactFirstName'),
        value: '{{firstName}}',
        enabledForType: ['COMMON', 'EMAIL_FILTER_COMMENT'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.contactName'),
        value: '{{name}} ',
        enabledForType: ['COMMON', 'EMAIL_FILTER_COMMENT'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.user'),
        value: '{{userName}} ',
        enabledForType: ['COMMON'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.greeting'),
        value: '{{ms}} ',
        enabledForType: ['COMMON', 'EMAIL_FILTER_COMMENT'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.protocolNumber'),
        value: '{{protocol}} ',
        enabledForType: ['COMMON'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.date'),
        value: '{{date}} ',
        enabledForType: ['COMMON', 'EMAIL_FILTER_COMMENT'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.hour'),
        value: '{{hour}} ',
        enabledForType: ['COMMON', 'EMAIL_FILTER_COMMENT'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.ticket_id'),
        value: '{{ticket_id}} ',
        enabledForType: ['COMMON'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.queue'),
        value: '{{queue}} ',
        enabledForType: ['COMMON'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.connection'),
        value: '{{connection}} ',
        enabledForType: ['COMMON'],
    },
    {
        name: i18n.t('messageVariablesPicker.vars.filter'),
        value: '{{filter}} ',
        enabledForType: ['EMAIL_FILTER_COMMENT'],
    },
];

const MessageVariablesPicker = ({ onClick, disabled, type = 'COMMON' }) => {
    const classes = useStyles();

    const handleClick = (e, value) => {
        e.preventDefault();
        if (disabled) return;
        onClick(value);
    };

    return (
        <OutlinedDiv margin="dense" fullWidth label={i18n.t('messageVariablesPicker.label')} disabled={disabled}>
            {messageVariables.map((msgVar) => (
                <Chip
                    key={msgVar.value}
                    onMouseDown={(e) => handleClick(e, msgVar.value)}
                    label={msgVar.name}
                    size="small"
                    disabled={!msgVar.enabledForType.includes(type)}
                    className={classes.chip}
                    color="primary"
                />
            ))}
        </OutlinedDiv>
    );
};

export default MessageVariablesPicker;
