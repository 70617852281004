import { grey } from '@material-ui/core/colors';
import styled from 'styled-components';

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    border: 1px dashed ${grey[500]};
    padding: 25px;
`;
