import React, { useContext, useEffect, useState } from 'react';

import { Field, Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import TabPanel from '../../components/TabPanel';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { i18n } from '../../translate/i18n';

import { FormControl, Tab, Tabs } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { capitalize, isArray } from 'lodash';
import moment from 'moment';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import QueueSelect from '../QueueSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectContainer: {
        width: '100%',
        textAlign: 'left',
    },
    form: {
        width: '100%', // Utiliza 100% da largura disponível
        margin: theme.spacing(2),
    },
    recurrenceContainer: {
        backgroundColor: '#f1f8e9', // Cor de fundo semelhante à do Google Agenda
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
        maxWidth: '600px', // Ajuste conforme necessário
        margin: '0 auto', // Centraliza na tela
    },
    recurrenceLabel: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
    },
    container: {
        marginBottom: theme.spacing(2),
    },
    repeatEvery: {
        marginBottom: theme.spacing(2),
    },
    repeatType: {
        marginBottom: theme.spacing(2),
    },
    repeatOn: {
        marginBottom: theme.spacing(2),
    },
    weekDays: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    ends: {
        marginBottom: theme.spacing(2),
    },
    endsOptions: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    selectDaysModal: {
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        border: '1px solid black',
        fontSize: '10px',
        fontWeight: '500',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '8px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
}));

const ScheduleSchema = Yup.object().shape({
    body: Yup.string().min(5, 'Mensagem muito curta').required('Obrigatório'),
    contactId: Yup.number().required('Obrigatório'),
    sendAt: Yup.string().required('Obrigatório'),
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, cleanContact, reload }) => {
    const classes = useStyles();
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const initialState = {
        body: '',
        contactId: '',
        sendAt: moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm'),
        sentAt: '',
        repeatEvery: '',
        repeatDailyInput: '',
        repeatCount: '',
    };

    const initialContact = {
        id: '',
        name: '',
    };

    const [attachment, setAttachment] = useState(null);
    const [isTicketOpen, setIsTicketOpen] = useState('');
    const [isAttributeOpen, setAttributeOpen] = useState('');
    const [schedule, setSchedule] = useState(initialState);
    const [contacts, setContacts] = useState([initialContact]);
    const [campaignEditable] = useState(true);
    const [currentContact, setCurrentContact] = useState(initialContact);
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [tab, setTab] = useState('scheduleModal');
    const [, setRepeatEvery] = useState('');
    const dias = [
        { pt: 'Domingo', en: 'Sunday' },
        { pt: 'Segunda', en: 'Monday' },
        { pt: 'Terça', en: 'Tuesday' },
        { pt: 'Quarta', en: 'Wednesday' },
        { pt: 'Quinta', en: 'Thursday' },
        { pt: 'Sexta', en: 'Friday' },
        { pt: 'Sábado', en: 'Saturday' },
    ];
    const [selecionados, setSelecionados] = useState([]);

    const toggleDia = (index) => {
        const dia = dias[index].en;
        const novosSelecionados = [...selecionados];
        const diaIndex = novosSelecionados.findIndex((d) => d === dia); // Corrigido aqui

        if (diaIndex < 0) {
            novosSelecionados.push(dia); // Adiciona o nome do dia em inglês
        } else {
            novosSelecionados.splice(diaIndex, 1);
        }

        setSelecionados(novosSelecionados);
    };

    useEffect(() => {
        if (contactId && contacts.length) {
            const contact = contacts.find((c) => c.id === contactId);
            if (contact) {
                setCurrentContact(contact);
            }
        }
    }, [contactId, contacts]);

    useEffect(() => {
        const { companyId } = user;
        if (open) {
            try {
                (async () => {
                    const { data: contactList } = await api.get('/contacts/list', {
                        params: { companyId: companyId },
                    });
                    let customList = contactList.map((c) => ({ id: c.id, name: c.name }));
                    if (isArray(customList)) {
                        setContacts([{ id: '', name: '' }, ...customList]);
                    }
                    if (contactId) {
                        setSchedule((prevState) => {
                            return { ...prevState, contactId };
                        });
                    }

                    if (!scheduleId) return;

                    const { data } = await api.get(`/schedules/${scheduleId}`);
                    setSchedule((prevState) => {
                        return {
                            ...prevState,
                            ...data,
                            sendAt: moment(data.sendAt).format('YYYY-MM-DDTHH:mm'),
                        };
                    });
                    setCurrentContact(data.contact);
                })();
            } catch (err) {
                toastError(err);
            }
        }
    }, [scheduleId, contactId, open, user]);

    const handleClose = () => {
        onClose();
        setSchedule(initialState);
        setCurrentContact(initialContact);
        setSchedule(initialState);
        setSelectedQueueIds([]);
        setIsTicketOpen('');
        setAttributeOpen('');
    };

    const handleSaveSchedule = async (values) => {
        if (selecionados.length === 0) {
            toastError('Para Relizar o agendamento selecione pelo menos 1 dia da semana.');
            return;
        }
        const scheduleData = {
            ...values,
            userId: user.id,
            isTicketOpen: isTicketOpen,
            isAttributeOpen: isAttributeOpen === 'true' ? user.id : isAttributeOpen,
            queueIdSchedule: selectedQueueIds,
            selectDaysRecorrenci: selecionados.join(', '),
        };
        try {
            const formData = new FormData();
            formData.append('file', attachment);
            formData.append('scheduleData', JSON.stringify(scheduleData));

            if (scheduleId) {
                await api.put(`/schedules/${scheduleId}`, formData);
            } else {
                await api.post('/schedules', formData);
            }
            toast.success(i18n.t('scheduleModal.success'));
            if (typeof reload == 'function') {
                reload();
            }
            if (contactId) {
                if (typeof cleanContact === 'function') {
                    cleanContact();
                    history.push('/schedules');
                }
            }
        } catch (err) {
            toastError(err);
        }
        setCurrentContact(initialContact);
        setSchedule(initialState);
        setSelectedQueueIds([]);
        setIsTicketOpen('');
        setAttributeOpen('');
        handleClose();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setAttachment(file);
    };

    const handleRemoveAttachment = () => {
        setAttachment(null);
    };
    //   const handleSetOpenTicket = async (values) => {
    //     setIsTicketOpen(values);
    //   };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {schedule.status === 'ERRO' ? 'Erro de Envio' : `Mensagem ${capitalize(schedule.status)}`}
                </DialogTitle>
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(_, v) => setTab(v)}
                    aria-label="disabled tabs example"
                >
                    <Tab label="Agendamento" value="scheduleModal" />
                </Tabs>
                <TabPanel className={classes.container} value={tab} name={'scheduleModal'}>
                    <Formik
                        initialValues={schedule}
                        enableReinitialize={true}
                        validationSchema={ScheduleSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSaveSchedule(values);
                                actions.setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <DialogContent dividers>
                                    <div className={classes.multFieldLine}>
                                        <FormControl variant="standard" fullWidth>
                                            <Autocomplete
                                                fullWidth
                                                value={currentContact}
                                                options={contacts}
                                                onChange={(e, contact) => {
                                                    const contactId = contact ? contact.id : '';
                                                    setSchedule({ ...schedule, contactId });
                                                    setCurrentContact(contact ? contact : initialContact);
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => {
                                                    return value.id === option.id;
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params} variant="standard" placeholder="Contato" />
                                                )}
                                            />
                                        </FormControl>
                                    </div>
                                    <br />
                                    <div className={classes.multFieldLine}>
                                        <FormControl className={classes.selectContainer}>
                                            <InputLabel id="new-ticket">Abre Ticket?</InputLabel>
                                            <Select
                                                labelId="new-ticket"
                                                id="new-ticket"
                                                onChange={async (e) => {
                                                    setIsTicketOpen(e.target.value);
                                                }}
                                            >
                                                <MenuItem value={'true'}>Sim</MenuItem>
                                                <MenuItem value={'false'}>Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <br />
                                    <div>
                                        <FormControl className={classes.selectContainer}>
                                            <InputLabel id="attribute-user">Atribui ao Usuário?</InputLabel>
                                            <Select
                                                labelId="attribute-user"
                                                id="attribute-user"
                                                onChange={async (e) => {
                                                    setAttributeOpen(e.target.value);
                                                }}
                                            >
                                                <MenuItem value={'true'}>Sim</MenuItem>
                                                <MenuItem value={'false'}>Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <br />
                                    <div>
                                        <QueueSelect
                                            selectedQueueIds={selectedQueueIds}
                                            onChange={(selectedIds) => setSelectedQueueIds(selectedIds)}
                                        />
                                    </div>
                                    <br />
                                    <div className={classes.multFieldLine}>
                                        <FormControl className={classes.selectContainer}>
                                            <InputLabel id="repeat-every">Enviar por...</InputLabel>
                                            <Select
                                                labelId="repeat-every"
                                                id="repeat-every"
                                                value={values.repeatEvery}
                                                onChange={(e) => {
                                                    setRepeatEvery(e.target.value);
                                                    setFieldValue('repeatEvery', e.target.value);
                                                }}
                                            >
                                                {[...Array(30)].map((_, index) => (
                                                    <MenuItem key={index + 1} value={index + 1}>
                                                        {index + 1} dia{index + 1 !== 1 && 's'}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem value="9999999">Todo dia</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <br />
                                    {values.repeatEvery && (
                                        <div style={{ display: 'inline-flex', justifyContent: 'space-between' }}>
                                            {dias.map((dia, index) => (
                                                <div
                                                    className={classes.selectDaysModal}
                                                    key={index}
                                                    onClick={() => toggleDia(index)}
                                                    style={{
                                                        backgroundColor: selecionados.includes(dia.en)
                                                            ? `#3287C9`
                                                            : 'transparent',
                                                        color: selecionados.includes(dia.en) ? `white` : 'black',
                                                    }}
                                                >
                                                    {dia.pt[0]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <br />
                                    {/* <div className={classes.multFieldLine}>
                    <FormControl className={classes.selectContainer}>
                      <InputLabel id="repeat-count">Quantidade de repetições</InputLabel>
                      <Select
                        labelId="repeat-count"
                        id="repeat-count"
                        value={values.repeatCount}
                        onChange={(e) => {
                          setRepeatEvery(e.target.value);
                          setFieldValue("repeatCount", e.target.value);
                        }}
                      >
                        {[...Array(10)].map((_, index) => (
                          <MenuItem key={index + 1} value={index + 1}>
                            {index + 1} envio{index + 1 !== 1 && "s"}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}
                                    <div className={classes.multFieldLine}>
                                        <Field
                                            as={TextField}
                                            minRows={9}
                                            multiline={true}
                                            label={i18n.t('scheduleModal.form.body')}
                                            name="body"
                                            error={touched.body && Boolean(errors.body)}
                                            helperText={touched.body && errors.body}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        />
                                    </div>
                                    <br />
                                    <div className={classes.multFieldLine}>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('scheduleModal.form.sendAt')}
                                            type="datetime-local"
                                            name="sendAt"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            error={touched.sendAt && Boolean(errors.sendAt)}
                                            helperText={touched.sendAt && errors.sendAt}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </div>
                                    {(schedule.mediaPath || attachment) && (
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item>
                                                <Button startIcon={<AttachFileIcon />}>
                                                    {attachment != null ? attachment.name : schedule.mediaName}
                                                </Button>
                                            </Grid>
                                            {campaignEditable && (
                                                <Grid item>
                                                    <IconButton onClick={handleRemoveAttachment} color="secondary">
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="secondary"
                                        disabled={isSubmitting}
                                        variant="outlined"
                                    >
                                        {i18n.t('scheduleModal.buttons.cancel')}
                                    </Button>
                                    <div className={classes.multFieldLine}>
                                        <input
                                            accept="*"
                                            style={{ display: 'none' }}
                                            id="mediaPath"
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <label htmlFor="mediaPath">
                                            <Button variant="contained" color="default" component="span">
                                                Anexar Arquivo
                                            </Button>
                                        </label>
                                    </div>

                                    {(schedule.sentAt === null || schedule.sentAt === '') && (
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {scheduleId
                                                ? `${i18n.t('scheduleModal.buttons.okEdit')}`
                                                : `${i18n.t('scheduleModal.buttons.okAdd')}`}
                                            {isSubmitting && (
                                                <CircularProgress size={24} className={classes.buttonProgress} />
                                            )}
                                        </Button>
                                    )}
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </TabPanel>
            </Dialog>
        </div>
    );
};

export default ScheduleModal;
