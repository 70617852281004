import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';

const HandleBoxLinkPreview = ({ link, fromMe }) => {
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    const imageUrl = link.images[0]; // O URL da imagem

    useEffect(() => {
        const fetchImageDimensions = async () => {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const img = new Image();
                const url = URL.createObjectURL(blob);

                img.onload = () => {
                    setImageDimensions({ width: img.width, height: img.height });
                    URL.revokeObjectURL(url); // Liberar o URL após o uso
                };

                img.src = url;
            } catch (error) {
                console.error('Error fetching image:', error);
                // Defina dimensões padrão em caso de erro
                setImageDimensions({ width: 161, height: 500 }); // Ajuste conforme necessário
            }
        };

        fetchImageDimensions();
    }, [imageUrl]); // Dependência para reexecutar o efeito se a URL da imagem mudar

    console.info({ imageDimensions });

    const isImageWide = imageDimensions.width >= imageDimensions.height || imageDimensions.width >= imageDimensions.height + 100;
    console.info({ link })
    return (
        <Box
            onClick={() => window.open(link?.url, '_blank')}
            style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                padding: '10px',
                backgroundColor: fromMe ? '#02443d' : 'rgb(117 117 117 / 35%)',
                borderRadius: '5px',
                alignItems: 'center',
            }}
        >
            <Box style={{ flex: 1 }}>
                <Typography variant="body1" style={{ fontSize: '15px' }} gutterBottom>
                    {link.title}
                </Typography>
                <Typography variant="body2" style={{ fontSize: '10px' }} gutterBottom>
                    {link.description}
                </Typography>
            </Box>
        </Box>
    );
};

export default HandleBoxLinkPreview;
