import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { i18n } from '../../translate/i18n';
import api from '../../services/api';
import toastError from '../../errors/toastError';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    paper: { maxWidth: "400px" },
    backDrop: {
        backdropFilter: "blur(3px)",
        backgroundColor: 'rgba(0,0,30,0.4)'
    },
}));

const ChangeCompanyModal = ({ open, onClose, onConfirm }) => {
    const classes = useStyles();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [companies, setCompanies] = useState([]);
    const [value, setValue] = useState(null);

    const handleClose = () => {
        onClose(false);
    };

    const handleConfirm = async () => {
        if (!value?.id) return;
        setIsSubmitting(true);
        try {

            localStorage.removeItem('token');
            localStorage.removeItem('companyId');


            const { data } = await api.post('/support/access-company', {
                params: { companyId: value.id, email: 'suporte@softlogsoftware.com.br' }
            });

            api.defaults.headers.Authorization = undefined;

            setTimeout(() => {
                localStorage.setItem('token', JSON.stringify(data.token));
                localStorage.setItem('companyId', value.id);
                api.defaults.headers.Authorization = `Bearer ${JSON.stringify(data.token)}`;
                window.location.reload()
            }, 500);

        } catch (error) {
            toastError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (open) {
            (async () => {
                setIsSubmitting(true);
                try {
                    const { data } = await api.get('/support/companies');
                    setCompanies(data);
                } catch (error) {
                    console.error(error);
                    toastError(error);
                } finally {
                    setIsSubmitting(false);
                }
            })()
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            fullWidth
            classes={{ paper: classes.paper }}
            onClose={() => handleClose()}
            BackdropProps={{ classes: { root: classes.backDrop } }}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{'Acesso suporte'}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Autocomplete
                        size="small"
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        options={companies} // Usando as empresas listadas
                        getOptionLabel={(option) => option.name} // Atribuir o campo que deve aparecer
                        onInputChange={(event, newInputValue) => {
                            setSearchParam(newInputValue); // Atualiza searchParam ao digitar
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Buscar Empresa" variant="outlined" />
                        )}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => handleClose()}
                    color="default"
                    disabled={isSubmitting}
                >
                    {i18n.t('confirmationModal.buttons.cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={async () => handleConfirm()}
                    disabled={isSubmitting}
                    color="secondary"
                >
                    Acessar
                    {isSubmitting && <CircularProgress size={24} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeCompanyModal;
