import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

const useStyle = makeStyles((theme) => ({
    root: {
        '& p': {
            overflowWrap: 'anywhere',
        },
    },
}));

const EmailContactValue = ({ value, emailContact, expanded = false }) => {
    const classes = useStyle();
    const [showAll, setShowAll] = useState(false);
    if (!value) return <span />;
    if (value.length > 500) {
        if (!showAll && !expanded) {
            return (
                <div className={classes.root}>
                    <Typography variant="body2">{value.substring(0, 500)}...</Typography>
                    <Button color="primary" onClick={() => setShowAll(true)} size="small">
                        Ver mais
                    </Button>
                </div>
            );
        } else {
            return (
                <div className={classes.root}>
                    <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{
                            __html: value
                                .replaceAll('[br /]', '<br />')
                                .replaceAll(
                                    emailContact.contactFilter.value,
                                    `<strong>${emailContact.contactFilter.value}</strong>`
                                ),
                        }}
                    />
                    {!expanded && (
                        <Button color="primary" onClick={() => setShowAll(false)} size="small">
                            Ver menos
                        </Button>
                    )}
                </div>
            );
        }
    }
    return <span>{value}</span>;
};

export default EmailContactValue;
