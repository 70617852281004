import api from '../../services/api';

const useEmailSettings = () => {
    const findById = async (id) => {
        const { data } = await api.request({
            url: `/email-settings/${id}`,
            method: 'GET',
        });
        return data;
    };

    const list = async (params) => {
        const { data } = await api.request({
            url: '/email-settings/all',
            method: 'GET',
            params,
        });
        return data;
    };

    const save = async (data) => {
        const { data: responseData } = await api.request({
            url: '/email-settings',
            method: 'POST',
            data,
        });
        return responseData;
    };

    const update = async (data) => {
        const { data: responseData } = await api.request({
            url: `/email-settings/${data.id}`,
            method: 'PUT',
            data,
        });
        return responseData;
    };

    const remove = async (id) => {
        const { data } = await api.request({
            url: `/email-settings/${id}`,
            method: 'DELETE',
        });
        return data;
    };

    const connectionValidation = async (data) => {
        console.log(data);
        const { data: responseData } = await api.request({
            url: '/email-settings/validate-connection',
            method: 'POST',
            data,
        });
        return responseData;
    };

    return {
        findById,
        list,
        save,
        update,
        remove,
        connectionValidation,
    };
};

export default useEmailSettings;
