import styled from 'styled-components';

export const FlexBoxRow = styled.div.attrs((props) => ({
    gap: props.gap,
}))`
    display: flex;
    flex-direction: row;
    gap: ${({ gap }) => gap || '0'};
`;

export const FlexBoxColumn = styled.div.attrs((props) => ({
    gap: props.gap,
}))`
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap || '0'};
`;
