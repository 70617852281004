import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Field, FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import toastError from '../../errors/toastError';
import useEmailFilter from '../../hooks/useEmailFilter';
import useEmailSettings from '../../hooks/useEmailSettings';
import { i18n } from '../../translate/i18n';

const EmailFilterSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    rules: Yup.array()
        .of(
            Yup.object().shape({
                type: Yup.string().oneOf(['FROM', 'TO', 'SUBJECT', 'BODY']).required('Campo obrigatório'),
                mode: Yup.string().oneOf(['TEXT', 'REGEX']).required('Campo obrigatório'),
                criteria: Yup.string().required('Campo obrigatório'),
            })
        )
        .min(1),
});

const initialState = {
    emailSettingsId: '',
    name: '',
    rules: [],
};

const EmailFilterModal = ({ open, onClose, emailFilterId }) => {
    const { update, save, find } = useEmailFilter();
    const { list: listEmailSettings } = useEmailSettings();
    const [filter, setFilter] = useState(initialState);
    const [connections, setConnections] = useState([]);

    useEffect(() => {
        const fetchAll = async () => {
            try {
                setConnections(await listEmailSettings());

                if (!emailFilterId) {
                    return;
                }

                setFilter(await find(emailFilterId));
            } catch (err) {
                toastError(err);
            }
        };
        fetchAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailFilterId]);

    const handleSaveFilter = async (values) => {
        try {
            if (values.id) {
                await update(values);
            } else {
                await save(values);
            }
            toast.success(i18n.t('emailFilter.modal.form.success'));
            onClose();
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };

    const handleClose = () => {
        onClose();
        setFilter(initialState);
    };

    const RulesTable = ({ push, remove, touched, errors, values }) => (
        <div>
            {values.rules?.map((rule, index) => {
                const hasError = touched.rules && touched.rules[index] && errors.rules;
                return (
                    <div key={index}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" margin="dense" fullWidth>
                                    <InputLabel id="emailFilter-modal-form-rule-type-selection-label">
                                        {i18n.t('emailFilter.modal.form.rule.type')}
                                    </InputLabel>
                                    <Field
                                        as={Select}
                                        label={i18n.t('emailFilter.modal.form.rule.type')}
                                        placeholder={i18n.t('emailFilter.modal.form.rule.type')}
                                        labelId="emailFilter-modal-form-rule-type-selection-label"
                                        name={`rules[${index}].type`}
                                        error={hasError && Boolean(errors.rules[index]?.type)}
                                        helperText={hasError && errors.rules[index]?.type}
                                        variant="outlined"
                                    >
                                        <MenuItem value="FROM">De</MenuItem>
                                        <MenuItem value="SUBJECT">Assunto</MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl variant="outlined" margin="dense" fullWidth>
                                    <InputLabel id="emailFilter-modal-form-rule-mode-selection-label">
                                        {i18n.t('emailFilter.modal.form.rule.type')}
                                    </InputLabel>
                                    <Field
                                        as={Select}
                                        label={i18n.t('emailFilter.modal.form.rule.mode')}
                                        placeholder={i18n.t('emailFilter.modal.form.rule.mode')}
                                        labelId="emailFilter-modal-form-rule-mode-selection-label"
                                        name={`rules[${index}].mode`}
                                        error={hasError && Boolean(errors.rules[index]?.mode)}
                                        helperText={hasError && errors.rules[index]?.mode}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    >
                                        <MenuItem value="TEXT">Texto</MenuItem>
                                        <MenuItem value="REGEX">Regex</MenuItem>
                                    </Field>
                                </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                                <Field
                                    as={TextField}
                                    label={i18n.t('emailFilter.modal.form.rule.criteria')}
                                    name={`rules[${index}].criteria`}
                                    error={hasError && Boolean(errors.rules[index]?.criteria)}
                                    helperText={hasError && errors.rules[index]?.criteria}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton onClick={() => remove(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
            <Button onClick={() => push({ type: '', mode: '', criteria: '' })}>
                {i18n.t('emailFilter.modal.form.rule.buttons.add')}
            </Button>
        </div>
    );

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth scroll="paper">
            <DialogTitle>
                {emailFilterId
                    ? `${i18n.t('emailFilter.modal.title.update')} #${emailFilterId}`
                    : i18n.t('emailFilter.modal.title.add')}
            </DialogTitle>
            <Formik
                initialValues={filter}
                validationSchema={EmailFilterSchema}
                validateOnMount
                validateOnBlur
                enableReinitialize
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSaveFilter(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ values, isValid, touched, errors, isSubmitting }) => (
                    <Form
                        style={{
                            width: '100%',
                        }}
                    >
                        <DialogContent dividers>
                            <Grid spacing={2} container>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" margin="dense" fullWidth>
                                        <InputLabel id="emailFilter-modal-form-connection-selection-label">
                                            {i18n.t('emailFilter.modal.form.connection')}
                                        </InputLabel>
                                        <Field
                                            as={Select}
                                            label={i18n.t('emailFilter.modal.form.connection')}
                                            placeholder={i18n.t('emailFilter.modal.form.connection')}
                                            labelId="emailFilter-modal-form-connection-selection-label"
                                            name="emailSettingsId"
                                            error={touched.emailSettingsId && Boolean(errors.emailSettingsId)}
                                            helperText={touched.emailSettingsId && errors.emailSettingsId}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        >
                                            <MenuItem value={''}>
                                                <em>Selecione</em>
                                            </MenuItem>
                                            {connections.map((connection) => (
                                                <MenuItem key={connection.id} value={connection.id}>
                                                    {connection.username} - {connection.host}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t('emailFilter.modal.form.name')}
                                        name="name"
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FieldArray name="rules">
                                        {(props) => (
                                            <RulesTable {...props} touched={touched} errors={errors} values={values} />
                                        )}
                                    </FieldArray>
                                </Grid>{' '}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                                {i18n.t('emailFilter.modal.buttons.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting || !isValid}
                                variant="contained"
                            >
                                {emailFilterId
                                    ? i18n.t('emailFilter.modal.buttons.okUpdate')
                                    : i18n.t('emailFilter.modal.buttons.okAdd')}
                                {isSubmitting && <CircularProgress size={24} />}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default React.memo(EmailFilterModal);
