import { formatCNPJ, formatCPF, isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';

export const formatDocument = (document) => {
    if (!document) {
        return '';
    }

    if (isValidCPF(document)) {
        return formatCPF(document);
    }

    if (isValidCNPJ(document)) {
        return formatCNPJ(document);
    }

    return document;
};
