import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import toastError from '../../errors/toastError';
import api from '../../services/api';

export function ContactsFilter({ onFiltered, contactId }) {
    const [contacts, setContacts] = useState([]);
    const [selected, setSelected] = useState([]);
    const [tries, setTries] = useState(0);

    useEffect(() => {
        loadContacts();
    }, []);

    useEffect(() => {
        if (selected && selected.id === contactId) {
            return;
        }

        if (!contactId) {
            setSelected(null);
            return;
        }

        if (contactId && !contacts.some((c) => c.id === contactId) && tries < 3) {
            setTries(tries + 1);
            loadContacts();
            return;
        }

        if (contactId && contacts.some((c) => c.id === contactId)) {
            const contact = contacts.find((c) => c.id === contactId);
            setSelected(contact);
            onFiltered(contact);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contacts, contactId]);

    const loadContacts = async () => {
        try {
            const { data } = await api.get(`/contacts/list`);
            setContacts(data);
        } catch (err) {
            toastError(err);
        }
    };

    const onChange = async (value) => {
        setSelected(value);
        onFiltered(value);
    };

    return (
        <Autocomplete
            size="small"
            options={contacts}
            value={selected}
            onChange={(e, v, r) => onChange(v)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Contatos" />}
        />
    );
}
