import { Button, Grid, TextField, Typography, useTheme } from '@material-ui/core';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import api from '../../services/api';
import './button.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: true,
            text: 'Atendimentos',
            position: 'left',
        },
        datalabels: {
            display: true,
            anchor: 'start',
            offset: -30,
            align: 'start',
            color: '#fff',
            textStrokeColor: '#000',
            textStrokeWidth: 2,
            font: {
                size: 20,
                weight: 'bold',
            },
        },
    },
};

export const ChatsUser = () => {
    // const classes = useStyles();
    const theme = useTheme();
    const [initialDate, setInitialDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [finalDate, setFinalDate] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [ticketsData, setTicketsData] = useState({ data: [] });

    const companyId = localStorage.getItem('companyId');

    useEffect(() => {
        handleGetTicketsInformation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dataCharts = {
        labels: ticketsData && ticketsData?.data.length > 0 && ticketsData?.data.map((item) => item.nome),
        datasets: [
            {
                data:
                    ticketsData?.data.length > 0 &&
                    ticketsData?.data.map((item) => {
                        return item.quantidade;
                    }),
                backgroundColor: '#9200bf',
            },
        ],
    };

    const handleGetTicketsInformation = async () => {
        try {
            const { data } = await api.get(
                `/dashboard/ticketsUsers?initialDate=${initialDate}&finalDate=${finalDate}&companyId=${companyId}`
            );
            setTicketsData(data);
        } catch (error) {
            toast.error('Erro ao obter informação do atendimento!');
        }
    };

    return (
        <>
            <Typography component="h2" variant="h6" color="primary" gutterBottom>
                Total de Atendimento por usuário
            </Typography>
            <Grid container spacing={2} style={{ paddingTop: '15px', paddingBottom: '25px' }}>
                <Grid xs={3} item>
                    <TextField
                        defaultValue={initialDate}
                        type="date"
                        label="Data Inicial"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            setInitialDate(e.target.value);
                        }}
                    />
                </Grid>
                <Grid xs={3} item>
                    <TextField
                        defaultValue={finalDate}
                        type="date"
                        label="Data Final"
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => {
                            setFinalDate(e.target.value);
                        }}
                    />
                </Grid>
                <Grid xs={1} item>
                    <Button onClick={handleGetTicketsInformation} variant="contained">
                        Filtrar
                    </Button>
                </Grid>
            </Grid>
            <Bar
                options={{
                    ...options,
                    ...(theme.mode === 'dark' && {
                        plugins: {
                            ...options.plugins,
                            title: {
                                color: 'white',
                            },
                        },
                        scales: {
                            xAxis: {
                                ticks: {
                                    color: 'white',
                                },
                                grid: {
                                    color: 'gray',
                                },
                            },
                            yAxis: {
                                ticks: {
                                    color: 'white',
                                },
                                grid: {
                                    color: 'gray',
                                },
                            },
                        },
                    }),
                }}
                data={dataCharts}
                style={{ maxWidth: '100%', maxHeight: '280px' }}
            />
        </>
    );
};
