import React, { useContext, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    makeStyles,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import { green } from '@material-ui/core/colors';

import { i18n } from '../../translate/i18n';

import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import useWhatsApps from '../../hooks/useWhatsApps';
import api from '../../services/api';
import { Can } from '../Can';
import QueueSelect from '../QueueSelect';
import TabPanel from '../TabPanel';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    btnWrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    tab: {
        minWidth: 120,
        width: 120,
    },
    tabWrapper: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        minHeight: '60svh',
    },
    tabGeneral: {
        gap: theme.spacing(2),
    },
}));

const UserSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Muito Curto!').max(50, 'Muito Longo!').required('Requerido'),
    password: Yup.string().min(5, 'Muito Curto!').max(50, 'Muito Longo!'),
    email: Yup.string().email('Email inválido').required('Requerido'),
});

const UserModal = ({ open, onClose, userId }) => {
    const classes = useStyles();

    const initialState = {
        name: '',
        email: '',
        password: '',
        profile: 'user',
        startWork: '',
        endWork: '',
        farewellMessage: '',
    };

    const optionState = {
        isDefaultHistory: false,
        isDefaultGroups: false,
        isDefaultSemQueue: false,
        isSignatureRequired: false,
        isAllowedToSpy: false,
        isAllowedToCloseTickets: false,
    };

    const { user: loggedInUser } = useContext(AuthContext);

    const [user, setUser] = useState(initialState);
    const [tab, setTab] = useState('general');
    const [selectedQueueIds, setSelectedQueueIds] = useState([]);
    const [showPassword, setShowPassword] = useState(false);
    const [whatsappId, setWhatsappId] = useState(false);
    const [option, setOption] = useState(optionState);
    const { loading, whatsApps } = useWhatsApps();
    const startWorkRef = useRef();
    const endWorkRef = useRef();

    useEffect(() => {
        const fetchUser = async () => {
            if (!userId) return;
            try {
                const { data } = await api.get(`/users/${userId}`);
                setUser((prevState) => {
                    return { ...prevState, ...data };
                });
                const userQueueIds = data.queues?.map((queue) => queue.id);
                setSelectedQueueIds(userQueueIds);
                setWhatsappId(data.whatsappId ? data.whatsappId : '');

                const optionsUserSettigs = data?.profileState;

                if (optionsUserSettigs) {
                    setOption((prevState) => {
                        const updatedOptions = { ...prevState };

                        // Define todas as opções como false
                        Object.keys(updatedOptions).forEach((option) => {
                            updatedOptions[option] = false;
                        });

                        // Obtém as opções específicas do perfil e define como true
                        const profileOptions = optionsUserSettigs.split(',');
                        profileOptions.forEach((option) => {
                            const trimmedOption = option.trim();
                            if (Object.keys(updatedOptions).includes(trimmedOption)) {
                                updatedOptions[trimmedOption] = true;
                            }
                        });
                        return updatedOptions;
                    });
                }
            } catch (err) {
                toastError(err);
            }
        };

        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, open]);

    const handleClose = () => {
        onClose();
        setUser(initialState);
        setOption(optionState);
    };

    useEffect(() => {
        validateCheckboxes(option);
    }, [option]);

    const handleSaveUser = async (values) => {
        let profileState = validateCheckboxes(option);
        if (profileState === '' || profileState === undefined) {
            profileState = null;
        }
        const userData = { ...values, whatsappId, queueIds: selectedQueueIds, profileState: profileState };
        try {
            if (userId) {
                await api.put(`/users/${userId}`, userData);
            } else {
                await api.post('/users', userData);
            }
            handleClose();
            toast.success(i18n.t('userModal.success'));
        } catch (err) {
            toastError(err);
        }
    };

    const validateCheckboxes = (option) => {
        const checkedOptions = [];

        if (option.isDefaultHistory) {
            checkedOptions.push('isDefaultHistory');
        }

        if (option.isDefaultGroups) {
            checkedOptions.push('isDefaultGroups');
        }

        if (option.isDefaultSemQueue) {
            checkedOptions.push('isDefaultSemQueue');
        }

        if (option.isSignatureRequired) {
            checkedOptions.push('isSignatureRequired');
        }

        if (option.isAllowedToSpy) {
            checkedOptions.push('isAllowedToSpy');
        }

        if (option.isAllowedToCloseTickets) {
            checkedOptions.push('isAllowedToCloseTickets');
        }

        const concatenatedOptions = checkedOptions.join(', ');
        return concatenatedOptions;
    };

    const handleSwitchChange = (fieldName, checked) => {
        setOption((prevState) => ({
            ...prevState,
            [fieldName]: checked,
        }));
    };

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {userId ? `${i18n.t('userModal.title.edit')}` : `${i18n.t('userModal.title.add')}`}
                </DialogTitle>
                <Formik
                    initialValues={user}
                    enableReinitialize={true}
                    validationSchema={UserSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveUser(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, touched, errors, isSubmitting, handleChange }) => (
                        <Form>
                            <DialogContent dividers>
                                <Tabs
                                    value={tab}
                                    onChange={handleChangeTab}
                                    variant="fullWidth"
                                    indicatorColor="primary"
                                    textColor="primary"
                                    aria-label="icon label tabs example"
                                >
                                    <Tab
                                        value={'general'}
                                        // icon={<ChatIcon />}
                                        label={i18n.t('userModal.tabs.general.title')}
                                        classes={{ root: classes.tab }}
                                    />
                                    <Tab
                                        value={'permissions'}
                                        // icon={<DoneAllIcon />}
                                        label={i18n.t('userModal.tabs.permissions.title')}
                                        classes={{ root: classes.tab }}
                                    />
                                </Tabs>
                                <TabPanel
                                    value={tab}
                                    name="general"
                                    className={clsx(classes.tabWrapper, classes.tabGeneral)}
                                >
                                    <div className={classes.multFieldLine}>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('userModal.form.name')}
                                            autoFocus
                                            name="name"
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        />
                                        <Field
                                            as={TextField}
                                            name="password"
                                            variant="outlined"
                                            margin="dense"
                                            label={i18n.t('userModal.form.password')}
                                            error={touched.password && Boolean(errors.password)}
                                            helperText={touched.password && errors.password}
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword((e) => !e)}
                                                        >
                                                            {showPassword ? (
                                                                <VisibilityOff color="secondary" />
                                                            ) : (
                                                                <Visibility color="secondary" />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                        />
                                    </div>
                                    <div className={classes.multFieldLine}>
                                        <Field
                                            as={TextField}
                                            label={i18n.t('userModal.form.email')}
                                            name="email"
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        />
                                        <FormControl variant="outlined" className={classes.formControl} margin="dense">
                                            <Can
                                                role={loggedInUser.profile}
                                                perform="user-modal:editProfile"
                                                yes={() => (
                                                    <>
                                                        <InputLabel id="profile-selection-input-label">
                                                            {i18n.t('userModal.form.profile')}
                                                        </InputLabel>

                                                        <Field
                                                            as={Select}
                                                            label={i18n.t('userModal.form.profile')}
                                                            name="profile"
                                                            labelId="profile-selection-label"
                                                            id="profile-selection"
                                                            onChange={(e) => {
                                                                const isAdmin = e.target.value === 'admin';
                                                                setOption({
                                                                    isDefaultHistory: isAdmin,
                                                                    isDefaultGroups: isAdmin,
                                                                    isDefaultSemQueue: isAdmin,
                                                                });
                                                                handleChange(e);
                                                            }}
                                                            required
                                                        >
                                                            <MenuItem value="admin">Admin</MenuItem>
                                                            <MenuItem value="user">User</MenuItem>
                                                        </Field>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </div>

                                    <Can
                                        role={loggedInUser.profile}
                                        perform="user-modal:editQueues"
                                        yes={() => (
                                            <QueueSelect
                                                selectedQueueIds={selectedQueueIds}
                                                onChange={(values) => setSelectedQueueIds(values)}
                                            />
                                        )}
                                    />
                                    <Can
                                        role={loggedInUser.profile}
                                        perform="user-modal:editQueues"
                                        yes={() =>
                                            !loading && (
                                                <FormControl
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={classes.maxWidth}
                                                    fullWidth
                                                >
                                                    <InputLabel>{i18n.t('userModal.form.whatsapp')}</InputLabel>
                                                    <Field
                                                        as={Select}
                                                        value={whatsappId}
                                                        onChange={(e) => setWhatsappId(e.target.value)}
                                                        label={i18n.t('userModal.form.whatsapp')}
                                                    >
                                                        <MenuItem value={''}>&nbsp;</MenuItem>
                                                        {whatsApps.map((whatsapp) => (
                                                            <MenuItem key={whatsapp.id} value={whatsapp.id}>
                                                                {whatsapp.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                            )
                                        }
                                    />
                                    <Can
                                        role={loggedInUser.profile}
                                        perform="user-modal:editProfile"
                                        yes={() =>
                                            !loading && (
                                                <form className={classes.container} noValidate>
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t('userModal.form.startWork')}
                                                        type="time"
                                                        ampm={false}
                                                        defaultValue="00:00"
                                                        inputRef={startWorkRef}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 600, // 5 min
                                                        }}
                                                        fullWidth
                                                        name="startWork"
                                                        error={touched.startWork && Boolean(errors.startWork)}
                                                        helperText={touched.startWork && errors.startWork}
                                                        variant="outlined"
                                                        margin="dense"
                                                        className={classes.textField}
                                                    />
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t('userModal.form.endWork')}
                                                        type="time"
                                                        ampm={false}
                                                        defaultValue="23:59"
                                                        inputRef={endWorkRef}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 600, // 5 min
                                                        }}
                                                        fullWidth
                                                        name="endWork"
                                                        error={touched.endWork && Boolean(errors.endWork)}
                                                        helperText={touched.endWork && errors.endWork}
                                                        variant="outlined"
                                                        margin="dense"
                                                        className={classes.textField}
                                                    />
                                                </form>
                                            )
                                        }
                                    />
                                    <Field
                                        as={TextField}
                                        label={i18n.t('userModal.form.farewellMessage')}
                                        type="farewellMessage"
                                        multiline
                                        minRows={4}
                                        fullWidth
                                        name="farewellMessage"
                                        error={touched.farewellMessage && Boolean(errors.farewellMessage)}
                                        helperText={touched.farewellMessage && errors.farewellMessage}
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </TabPanel>
                                <TabPanel value={tab} name="permissions" className={classes.tabWrapper}>
                                    <Grid style={{ paddingTop: 10 }} item>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="isDefaultHistory"
                                                    checked={option.isDefaultHistory}
                                                    onChange={(e) =>
                                                        handleSwitchChange('isDefaultHistory', e.target.checked)
                                                    }
                                                />
                                            }
                                            label={i18n.t('userModal.tabs.permissions.isDefaultHistory')}
                                        />
                                    </Grid>
                                    <Grid style={{ paddingTop: 10 }} item>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="isDefaultGroups"
                                                    checked={option.isDefaultGroups}
                                                    onChange={(e) =>
                                                        handleSwitchChange('isDefaultGroups', e.target.checked)
                                                    }
                                                />
                                            }
                                            label={i18n.t('userModal.tabs.permissions.isDefaultGroups')}
                                        />
                                    </Grid>
                                    <Grid style={{ paddingTop: 10 }} item>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="isDefaultSemQueue"
                                                    checked={option.isDefaultSemQueue}
                                                    onChange={(e) =>
                                                        handleSwitchChange('isDefaultSemQueue', e.target.checked)
                                                    }
                                                />
                                            }
                                            label={i18n.t('userModal.tabs.permissions.isDefaultSemQueue')}
                                        />
                                    </Grid>
                                    <Grid style={{ paddingTop: 10 }} item>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="isSignatureRequired"
                                                    checked={option.isSignatureRequired}
                                                    onChange={(e) =>
                                                        handleSwitchChange('isSignatureRequired', e.target.checked)
                                                    }
                                                />
                                            }
                                            label={i18n.t('userModal.tabs.permissions.isSignatureRequired')}
                                        />
                                    </Grid>
                                    <Grid style={{ paddingTop: 10 }} item>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="isAllowedToSpy"
                                                    checked={option.isAllowedToSpy}
                                                    onChange={(e) =>
                                                        handleSwitchChange('isAllowedToSpy', e.target.checked)
                                                    }
                                                />
                                            }
                                            label={i18n.t('userModal.tabs.permissions.isAllowedToSpy')}
                                        />
                                    </Grid>
                                    <Grid style={{ paddingTop: 10 }} item>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="isAllowedToCloseTickets"
                                                    checked={option.isAllowedToCloseTickets}
                                                    onChange={(e) =>
                                                        handleSwitchChange('isAllowedToCloseTickets', e.target.checked)
                                                    }
                                                />
                                            }
                                            label={i18n.t('userModal.tabs.permissions.isAllowedToCloseTickets')}
                                        />
                                    </Grid>
                                </TabPanel>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t('userModal.buttons.cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {userId
                                        ? `${i18n.t('userModal.buttons.okEdit')}`
                                        : `${i18n.t('userModal.buttons.okAdd')}`}
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default UserModal;
