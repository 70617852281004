import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Field, FieldArray, FastField, Form, Formik } from 'formik';
import { IMaskInput } from 'react-imask';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { format } from 'date-fns';

import { isValidCNPJ, isValidCPF } from '@brazilian-utils/brazilian-utils';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { i18n } from '../../translate/i18n';

import { Box, FormControl, Grid, InputLabel, ListSubheader, Divider, MenuItem, Select, Paper, Tooltip } from '@material-ui/core';
import toastError from '../../errors/toastError';
import usePlans from '../../hooks/usePlans';
import api from '../../services/api';
import DocumentGeneratorForm from '../DocumentGeneratorForm';
import { CEPMask, CpfCnpjMask, RgMask } from '../MaskInputs';
import axios from 'axios';
import UFsBrasil from '../../utils/UFsBrasil';
import DividerChip from '../DividerChip';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ContactSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Muito Curto!').max(50, 'Muito Longo!').required('Requerido'),
    number: Yup.string().min(8, 'Muito Curto!').max(50, 'Muito Longo!'),
    email: Yup.string().email('Email inválido!'),
    document: Yup.string()
        .nullable()
        .test('valid-document', 'Documento inválido!', function (value) {
            const { path, createError } = this;
            if (value) {
                if (!isValidCPF(value) && !isValidCNPJ(value)) {
                    return createError({ path, message: 'Documento inválido!' });
                }
            }
            return true;
        }),
});

const initialState = {
    name: '',
    number: '',
    email: '',
    date: '',
    document: '',
    disableBot: false,
    extraInfo: [],
    nationalId: '',
    nationality: '',
    maritalStatus: '',
    profession: '',
    zipCode: '',
    address: '',
    residentialNumber: '',
    complement: '',
    city: '',
    state: '',
};

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMounted = useRef(true);
    const [contact, setContact] = useState(initialState);
    const [planConfig, setPlanConfig] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const { getPlanCompany } = usePlans();
    const [firstFind, setFirstFind] = useState(true);

    useEffect(() => {
        async function fetchData() {
            const companyId = localStorage.getItem('companyId');
            const planConfigs = await getPlanCompany(undefined, companyId);
            setPlanConfig(planConfigs);
        }
        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, []);

    const processContactData = (data) => {
        if (data?.extraInfo) {
            const sortedExtraInfo = data.extraInfo.sort((a, b) => {
                if (a.order !== null && b.order !== null) { return a.order - b.order; }
                if (a.order !== null) { return -1; }
                if (b.order !== null) { return 1; }
                return new Date(a.createdAt) - new Date(b.createdAt);
            });
            setContact({ ...data, extraInfo: sortedExtraInfo });
        } else {
            setContact(data);
        }
    };

    useEffect(() => {
        const fetchContact = async () => {
            if (initialValues) {
                setContact((prevState) => ({ ...prevState, ...initialValues }));
            }

            if (!contactId) return;

            try {
                let { data } = await api.get(`/contacts/${contactId}`);
                if (isMounted.current) {
                    if (!!data.zipCode) setFirstFind(false);
                    let formattedValue = data;
                    if (!!formattedValue?.date) formattedValue = { ...formattedValue, date: format(new Date(data.date), 'yyyy-MM-dd') }
                    setTimeout(() => {
                        processContactData(formattedValue);
                    }, 200);
                }
            } catch (err) {
                toastError(err);
            }
        };

        fetchContact();
    }, [contactId, open, initialValues]);

    useEffect(() => {
        const fetchGlobalExtraInfo = async () => {
            try {
                const { data } = await api.get('/global-extra-info');
                const mapValues = data.map(e => ({
                    name: e.name,
                    value: e.value,
                    isGlobal: true
                }));
                setContact(prev => ({
                    ...prev,
                    extraInfo: mapValues
                }));
            } catch (error) {
                console.error(error);
            }
        };

        if (open && !contactId) {
            fetchGlobalExtraInfo();
        };

    }, [open]);


    const handleClose = () => {
        onClose();
        setContact(initialState);
        setTabIndex(0);
    };

    const handleSaveContact = async (values) => {
        try {
            if (contactId) {
                await api.put(`/contacts/${contactId}`, values);
                handleClose();
            } else {
                const { data } = await api.post('/contacts', values);
                if (onSave) {
                    onSave(data);
                }
                handleClose();
            }
            toast.success(i18n.t('contactModal.success'));
        } catch (err) {
            toastError(err);
        }
    };

    const handleFindCEP = async (value) => {
        try {
            if (value && value.length === 9 && firstFind) {
                const { data } = await axios.get(`https://viacep.com.br/ws/${value}/json/`)
                setContact((prevState) => ({
                    ...prevState,
                    zipCode: value,
                    address: data.logradouro,
                    city: data.localidade,
                    state: data.uf,
                }));
            } else {
                setFirstFind(true);
            }
        } catch (error) {
            toastError("Ocorreu um erro ao buscar o CEP");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setContact((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleChangeExtraInfo = useCallback((e) => {
        const { name, value } = e.target;
        const indexMatch = name.match(/extraInfo\[(\d+)]\.(.+)/);
        if (indexMatch) {
            const index = parseInt(indexMatch[1], 10);
            const property = indexMatch[2];
            setContact((prevState) => {
                const updatedExtraInfo = [...prevState.extraInfo];
                updatedExtraInfo[index] = {
                    ...updatedExtraInfo[index],
                    [property]: value,
                };
                return { ...prevState, extraInfo: updatedExtraInfo };
            });
        }
    }, []);

    const handleSizeMultiline = (text) => !!(text?.length > 60)

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;
        const items = Array.from(contact.extraInfo);
        const [movedItem] = items.splice(source.index, 1);
        if (!movedItem?.name || movedItem?.name?.length < 1) return toastError("Você deve definir um nome para o campo antes de mover")
        items.splice(destination.index, 0, movedItem);
        const updatedItems = items.map((item, index) => ({
            ...item,
            order: index + 1,
        }));
        setContact((prevState) => ({ ...prevState, extraInfo: updatedItems }));
    };

    const handleChangeValues = (e, callback) => {
        const { name, value } = e.target;
        setContact((prevState) => ({ ...prevState, [name]: value }));
        callback(e)
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '100%',
                        maxWidth: "720px"
                    },
                }}
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {contactId ? `${i18n.t('contactModal.title.edit')}` : `${i18n.t('contactModal.title.add')}`}
                </DialogTitle>
                <Formik
                    initialValues={contact}
                    enableReinitialize
                    validationSchema={ContactSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveContact({ ...values, maritalStatus: contact.maritalStatus });
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ values, handleChange, errors, touched, isSubmitting }) => (
                        <Form>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            <DialogContent dividers>
                                                <Paper square elevation={0}>
                                                    <Tabs
                                                        value={tabIndex}
                                                        textColor="primary"
                                                        variant="fullWidth"
                                                        onChange={(event, newValue) => setTabIndex(newValue)}
                                                    >
                                                        <Tab label={i18n.t('contactModal.form.mainInfo')} />
                                                        <Tab label={i18n.t('contactModal.form.extraInfo')} />
                                                    </Tabs>
                                                </Paper>
                                                {tabIndex === 0 && (
                                                    <>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={12}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.name')}
                                                                    name="name"
                                                                    autoFocus
                                                                    error={touched.name && Boolean(errors.name)}
                                                                    helperText={touched.name && errors.name}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.name}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.number')}
                                                                    name="number"
                                                                    error={touched.number && Boolean(errors.number)}
                                                                    helperText={touched.number && errors.number}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.number}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.email')}
                                                                    name="email"
                                                                    error={touched.email && Boolean(errors.email)}
                                                                    helperText={touched.email && errors.email}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.email}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <DividerChip>Informações Pessoais</DividerChip>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={5}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.profession')}
                                                                    name="profession"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.profession}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <RgMask
                                                                    label={i18n.t('contactModal.form.nationalId')}
                                                                    name="nationalId"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.nationalId}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <CpfCnpjMask
                                                                    label="CPF/CNPJ"
                                                                    name="document"
                                                                    error={touched.document && Boolean(errors.document)}
                                                                    helperText={touched.document && errors.document}
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.document}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <TextField
                                                                    label={contact?.document?.length > 14 ? i18n.t('contactModal.form.registrationDate') : i18n.t('contactModal.form.dateBirth')}
                                                                    name="date"
                                                                    type="date"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.date}
                                                                    defaultValue={contact.date}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.nationality')}
                                                                    name="nationality"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.nationality}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={5}>
                                                                <FormControl variant="outlined" margin="dense" fullWidth>
                                                                    <InputLabel id="maritalStatus-input-select">
                                                                        {i18n.t('contactModal.form.maritalStatus')}
                                                                    </InputLabel>
                                                                    <Select
                                                                        name="maritalStatus"
                                                                        value={contact.maritalStatus}
                                                                        onChange={e => handleChangeValues(e, handleChange)}
                                                                        labelId="maritalStatus-input-select"
                                                                        label={i18n.t('contactModal.form.maritalStatus')}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    >
                                                                        <MenuItem value="solteiro">Solteiro</MenuItem>
                                                                        <MenuItem value="casado">Casado</MenuItem>
                                                                        <MenuItem value="divorciado">Divorciado</MenuItem>
                                                                        <MenuItem value="separado_judicialmente">Separado Judicialmente</MenuItem>
                                                                        <MenuItem value="viuvo">Viúvo</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <Field
                                                                    as={CEPMask}
                                                                    onBlur={e => handleFindCEP(e.target.value)}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                    label={i18n.t('contactModal.form.zipCode')}
                                                                    name="zipCode"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    value={contact.zipCode}
                                                                    InputLabelProps={{ shrink: true }}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={7}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.address')}
                                                                    name="address"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.address}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={2}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.residentialNumber')}
                                                                    name="residentialNumber"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.residentialNumber}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.complement')}
                                                                    name="complement"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.complement}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.neighborhood')}
                                                                    name="neighborhood"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.neighborhood}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <TextField
                                                                    label={i18n.t('contactModal.form.city')}
                                                                    name="city"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    value={contact.city}
                                                                    onChange={e => handleChangeValues(e, handleChange)}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={2}>
                                                                <FormControl
                                                                    size="small"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    style={{ marginTop: '8px' }}
                                                                >
                                                                    <InputLabel htmlFor="state-input-select">{i18n.t('contactModal.form.state')}</InputLabel>
                                                                    <Field
                                                                        name="state"
                                                                        as={Select}
                                                                        value={contact.state}
                                                                        onChange={e => setContact(prevState => ({ ...prevState, state: e.target.value }))}
                                                                        labelId="state-input-select"
                                                                        label={i18n.t('contactModal.form.state')}
                                                                        renderValue={(selected) => selected}
                                                                        InputLabelProps={{ shrink: true }}
                                                                    >
                                                                        <ListSubheader>Sudeste</ListSubheader>
                                                                        {UFsBrasil.sudeste.map(uf => (
                                                                            <MenuItem key={uf.code} value={uf.code}>
                                                                                {`${uf.code} - ${uf.name}`}
                                                                            </MenuItem>
                                                                        ))}

                                                                        <ListSubheader>Norte</ListSubheader>
                                                                        {UFsBrasil.norte.map(uf => (
                                                                            <MenuItem key={uf.code} value={uf.code}>
                                                                                {`${uf.code} - ${uf.name}`}
                                                                            </MenuItem>
                                                                        ))}

                                                                        <ListSubheader>Nordeste</ListSubheader>
                                                                        {UFsBrasil.nordeste.map(uf => (
                                                                            <MenuItem key={uf.code} value={uf.code}>
                                                                                {`${uf.code} - ${uf.name}`}
                                                                            </MenuItem>
                                                                        ))}

                                                                        <ListSubheader>Centro-Oeste</ListSubheader>
                                                                        {UFsBrasil.centro_oeste.map(uf => (
                                                                            <MenuItem key={uf.code} value={uf.code}>
                                                                                {`${uf.code} - ${uf.name}`}
                                                                            </MenuItem>
                                                                        ))}

                                                                        <ListSubheader>Sul</ListSubheader>
                                                                        {UFsBrasil.sul.map(uf => (
                                                                            <MenuItem key={uf.code} value={uf.code}>
                                                                                {`${uf.code} - ${uf.name}`}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Field>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </>

                                                )}
                                                {tabIndex === 1 && (
                                                    <>
                                                        <FieldArray name="extraInfo">
                                                            {({ push, remove }) => (
                                                                <>
                                                                    {values.extraInfo && values.extraInfo.length > 0 && values.extraInfo.map((info, index) => (
                                                                        <Draggable key={`${index}-info`} draggableId={`${index}-info`} index={index}>
                                                                            {(provided) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={{
                                                                                        ...provided.draggableProps.style,
                                                                                        marginBottom: '8px'
                                                                                    }}
                                                                                >
                                                                                    <Grid container spacing={2}>
                                                                                        <Grid item xs={12} spacing={2}>
                                                                                            <Box
                                                                                                style={{
                                                                                                    backgroundColor: theme.mode === "dark" ? 'rgb(82 81 81)' : 'rgb(241 241 241)',
                                                                                                    padding: '16px',
                                                                                                    borderRadius: '4px',
                                                                                                    display: 'flex',
                                                                                                    flexDirection: 'row',
                                                                                                    alignItems: 'flex-start',
                                                                                                    position: 'relative'
                                                                                                }}
                                                                                            >
                                                                                                <Grid container spacing={2} alignItems="flex-start">
                                                                                                    <Grid item xs={12} sm={handleSizeMultiline(info?.value) ? 12 : 4}>
                                                                                                        <FastField
                                                                                                            as={TextField}
                                                                                                            name={`extraInfo[${index}].order`}
                                                                                                            value={info.order || index + 1}
                                                                                                            onChange={handleChangeExtraInfo}
                                                                                                            style={{ display: "none" }}
                                                                                                        />
                                                                                                        <FastField
                                                                                                            as={TextField}
                                                                                                            disabled={info?.isGlobal}
                                                                                                            label={i18n.t('contactModal.form.extraName')}
                                                                                                            name={`extraInfo[${index}].name`}
                                                                                                            variant="outlined"
                                                                                                            margin="dense"
                                                                                                            fullWidth
                                                                                                            value={info.name}
                                                                                                            onChange={handleChangeExtraInfo}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Grid item xs={12} sm={handleSizeMultiline(info?.value) ? 12 : 8}>
                                                                                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                            <FastField
                                                                                                                as={TextField}
                                                                                                                label={i18n.t('contactModal.form.extraValue')}
                                                                                                                name={`extraInfo[${index}].value`}
                                                                                                                variant="outlined"
                                                                                                                margin="dense"
                                                                                                                maxRows={7}
                                                                                                                multiline={handleSizeMultiline(info?.value)}
                                                                                                                fullWidth
                                                                                                                value={info.value}
                                                                                                                onChange={handleChangeExtraInfo}
                                                                                                            />
                                                                                                            <Tooltip title={info.isGlobal ? "Este campo não pode ser deletado pois é um campo global" : "Deletar campo"}>
                                                                                                                <Box>
                                                                                                                    <IconButton size="small" disabled={info?.isGlobal} onClick={() => remove(index)}>
                                                                                                                        <DeleteOutlineIcon />
                                                                                                                    </IconButton>
                                                                                                                </Box>
                                                                                                            </Tooltip>
                                                                                                        </Box>
                                                                                                    </Grid>
                                                                                                </Grid>
                                                                                            </Box>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    <div className={classes.extraAttr}>
                                                                        <Button
                                                                            style={{ flex: 1, marginTop: 8 }}
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            onClick={() => push({ name: '', value: '' })}
                                                                        >
                                                                            {`+ ${i18n.t('contactModal.buttons.addExtraInfo')}`}
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                        {planConfig?.plan?.useDocumentGenerator && (
                                                            <>
                                                                <Divider style={{ marginTop: '16px' }} />
                                                                <DocumentGeneratorForm contact={contact} />
                                                            </>
                                                        )}
                                                        <Typography style={{ marginBottom: 8, marginTop: 12 }} variant="subtitle1">
                                                            <Switch
                                                                size="small"
                                                                checked={contact.disableBot}
                                                                onChange={() => setContact(prevState => ({ ...prevState, disableBot: !prevState.disableBot }))}
                                                                name="showAllTickets"
                                                                color="primary"
                                                            />
                                                            Desabilitar chatbot para esse contato
                                                        </Typography>
                                                    </>
                                                )}
                                            </DialogContent>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t('contactModal.buttons.cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {contactId ? `${i18n.t('contactModal.buttons.okEdit')}` : `${i18n.t('contactModal.buttons.okAdd')}`}
                                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>

            </Dialog>
        </div >
    );
};

export default ContactModal;
