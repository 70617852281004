import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';

import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

import { ptBR } from '@material-ui/core/locale';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ColorModeContext from './layout/themeContext';

import { AuthContext, AuthProvider } from './context/Auth/AuthContext';
import useUsers from './hooks/useUsers';
import Routes from './routes';

const queryClient = new QueryClient();

const AuthApp = () => {
    const { user, isAuth } = useContext(AuthContext);
    const { handleThemeModeChange } = useUsers();
    const [locale, setLocale] = useState();
    const [mode, setMode] = useState('light');

    useEffect(() => {
        if (isAuth) {
            if (user?.themeMode) {
                setMode(user.themeMode);
                return;
            }
        }

        setMode('light');
    }, [user, mode, isAuth]);

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                handleThemeModeChange(mode === 'light' ? 'dark' : 'light');
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mode]
    );

    const theme = createTheme(
        {
            props: {
                MuiBadge: {
                    overlap: 'rectangular',
                },
            },
            scrollbarStyles: {
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                    backgroundColor: '#e8e8e8',
                },
            },
            scrollbarStylesSoft: {
                '&::-webkit-scrollbar': {
                    width: '8px',
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: mode === 'light' ? '#F3F3F3' : '#333333',
                },
            },
            palette: {
                type: mode,
                primary: { main: '#3287C9' },
                textPrimary: mode === 'light' ? '#3287C9' : '#FFFFFF',
                borderPrimary: mode === 'light' ? '#3287C9' : '#FFFFFF',
                dark: { main: mode === 'light' ? '#333333' : '#F3F3F3' },
                light: { main: mode === 'light' ? '#F3F3F3' : '#333333' },
                tabHeaderBackground: mode === 'light' ? '#EEE' : '#666',
                optionsBackground: mode === 'light' ? '#fafafa' : '#333',
                fancyBackground: mode === 'light' ? '#fafafa' : '#333',
                total: mode === 'light' ? '#fff' : '#222',
                messageIcons: mode === 'light' ? 'grey' : '#F3F3F3',
                inputBackground: mode === 'light' ? '#FFFFFF' : '#333',
                barraSuperior: '#3287C9',
            },
            mode,
        },
        locale
    );

    useEffect(() => {
        const i18nlocale = localStorage.getItem('i18nextLng');
        const browserLocale = i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === 'ptBR') {
            setLocale(ptBR);
        }
    }, []);

    return (
        <ColorModeContext.Provider value={{ colorMode }}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <Routes />
                </QueryClientProvider>
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

const App = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <AuthApp />
            </AuthProvider>
        </BrowserRouter>
    );
};

export default App;
