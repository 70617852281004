import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceworker from './serviceWorker';

import App from './App';

ReactDOM.render(
    <CssBaseline>
        <App />
    </CssBaseline>,
    document.getElementById('root')
);

serviceworker.register();
