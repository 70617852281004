import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    makeStyles,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import ButtonWithSpinner from '../ButtonWithSpinner';
import ConfirmationModal from '../ConfirmationModal';

import { Edit as EditIcon } from '@material-ui/icons';

import { toast } from 'react-toastify';
import usePlans from '../../hooks/usePlans';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    mainPaper: {
        width: '100%',
        flex: 1,
        padding: theme.spacing(2),
    },
    fullWidth: {
        width: '100%',
    },
    tableContainer: {
        width: '100%',
        overflowX: 'scroll',
        ...theme.scrollbarStyles,
    },
    textfield: {
        width: '100%',
    },
    textRight: {
        textAlign: 'right',
    },
    row: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    control: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    buttonContainer: {
        textAlign: 'right',
        padding: theme.spacing(1),
    },
}));

export function PlanManagerForm(props) {
    const { onSubmit, onDelete, onCancel, initialValue, loading } = props;
    const classes = useStyles();

    const [record, setRecord] = useState({
        name: '',
        users: 0,
        connections: 0,
        queues: 0,
        amount: 0,
        useEmail: true,
        useWhatsapp: true,
        useFacebook: true,
        useInstagram: true,
        useCampaigns: true,
        useSchedules: true,
        useInternalChat: true,
        useExternalApi: true,
        useExternalCalendar: true,
        useDocumentGenerator: true,
    });

    useEffect(() => {
        setRecord(initialValue);
    }, [initialValue]);

    const handleSubmit = async (data) => {
        onSubmit(data);
    };

    return (
        <Formik
            enableReinitialize
            className={classes.fullWidth}
            initialValues={record}
            onSubmit={(values, { resetForm }) =>
                setTimeout(() => {
                    handleSubmit(values);
                    resetForm();
                }, 500)
            }
        >
            {(values) => (
                <Form className={classes.fullWidth}>
                    <Grid spacing={2} justifyContent="flex-end" container>
                        {/* NOME */}
                        <Grid xs={12} sm={6} md={2} item>
                            <Field
                                as={TextField}
                                label="Nome"
                                name="name"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                            />
                        </Grid>

                        {/* USUARIOS */}
                        <Grid xs={12} sm={6} md={1} item>
                            <Field
                                as={TextField}
                                label="Usuários"
                                name="users"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="number"
                            />
                        </Grid>

                        {/* CONEXOES */}
                        <Grid xs={12} sm={6} md={1} item>
                            <Field
                                as={TextField}
                                label="Conexões"
                                name="connections"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="number"
                            />
                        </Grid>

                        {/* FILAS */}
                        <Grid xs={12} sm={6} md={1} item>
                            <Field
                                as={TextField}
                                label="Filas"
                                name="queues"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="number"
                            />
                        </Grid>

                        {/* VALOR */}
                        <Grid xs={12} sm={6} md={1} item>
                            <Field
                                as={TextField}
                                label="Valor"
                                name="amount"
                                variant="outlined"
                                className={classes.fullWidth}
                                margin="dense"
                                type="string"
                            />
                        </Grid>

                        {/* WHATSAPP */}
                        <Grid xs={12} sm={6} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useWhatsapp-selection">Whatsapp</InputLabel>
                                <Field
                                    as={Select}
                                    id="useWhatsapp-selection"
                                    label="Whatsapp"
                                    labelId="useWhatsapp-selection-label"
                                    name="useWhatsapp"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* FACEBOOK */}
                        <Grid xs={12} sm={6} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useFacebook-selection">Facebook</InputLabel>
                                <Field
                                    as={Select}
                                    id="useFacebook-selection"
                                    label="Facebook"
                                    labelId="useFacebook-selection-label"
                                    name="useFacebook"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* INSTAGRAM */}
                        <Grid xs={12} sm={6} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useInstagram-selection">Instagram</InputLabel>
                                <Field
                                    as={Select}
                                    id="useInstagram-selection"
                                    label="Instagram"
                                    labelId="useInstagram-selection-label"
                                    name="useInstagram"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* CAMPANHAS */}
                        <Grid xs={12} sm={6} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useCampaigns-selection">Campanhas</InputLabel>
                                <Field
                                    as={Select}
                                    id="useCampaigns-selection"
                                    label="Campanhas"
                                    labelId="useCampaigns-selection-label"
                                    name="useCampaigns"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* DOCUMENT GENERATOR */}
                        <Grid xs={12} sm={6} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useDocumentGenerator-selection">Gerador de Documentos</InputLabel>
                                <Field
                                    as={Select}
                                    id="useDocumentGenerator-selection"
                                    label="useDocumentGenerator"
                                    labelId="useDocumentGenerator-selection-label"
                                    name="useDocumentGenerator"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* EMAIL */}
                        <Grid xs={12} sm={6} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useEmail-selection">Email</InputLabel>
                                <Field
                                    as={Select}
                                    id="useEmail-selection"
                                    label="useEmail"
                                    labelId="useEmail-selection-label"
                                    name="useEmail"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* EXTERNAL CALENDAR */}
                        <Grid xs={12} sm={6} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useExternalCalendar-selection">Agendas</InputLabel>
                                <Field
                                    as={Select}
                                    id="useExternalCalendar-selection"
                                    label="useExternalCalendar"
                                    labelId="useExternalCalendar-selection-label"
                                    name="useExternalCalendar"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* AGENDAMENTOS */}
                        <Grid xs={12} sm={8} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useSchedules-selection">Agendamentos</InputLabel>
                                <Field
                                    as={Select}
                                    id="useSchedules-selection"
                                    label="Agendamentos"
                                    labelId="useSchedules-selection-label"
                                    name="useSchedules"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* CHAT INTERNO */}
                        <Grid xs={12} sm={8} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useInternalChat-selection">Chat Interno</InputLabel>
                                <Field
                                    as={Select}
                                    id="useInternalChat-selection"
                                    label="Chat Interno"
                                    labelId="useInternalChat-selection-label"
                                    name="useInternalChat"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        {/* API Externa */}
                        <Grid xs={12} sm={8} md={2} item>
                            <FormControl margin="dense" variant="outlined" fullWidth>
                                <InputLabel htmlFor="useExternalApi-selection">API Externa</InputLabel>
                                <Field
                                    as={Select}
                                    id="useExternalApi-selection"
                                    label="API Externa"
                                    labelId="useExternalApi-selection-label"
                                    name="useExternalApi"
                                    margin="dense"
                                >
                                    <MenuItem value={true}>Habilitadas</MenuItem>
                                    <MenuItem value={false}>Desabilitadas</MenuItem>
                                </Field>
                            </FormControl>
                        </Grid>

                        <Grid sm={3} md={1} item>
                            <ButtonWithSpinner
                                className={classes.fullWidth}
                                loading={loading}
                                onClick={() => onCancel()}
                                variant="contained"
                            >
                                Limpar
                            </ButtonWithSpinner>
                        </Grid>
                        {record.id !== undefined ? (
                            <Grid sm={3} md={1} item>
                                <ButtonWithSpinner
                                    className={classes.fullWidth}
                                    loading={loading}
                                    onClick={() => onDelete(record)}
                                    variant="contained"
                                    color="secondary"
                                >
                                    Excluir
                                </ButtonWithSpinner>
                            </Grid>
                        ) : null}
                        <Grid sm={3} md={1} item>
                            <ButtonWithSpinner
                                className={classes.fullWidth}
                                loading={loading}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Salvar
                            </ButtonWithSpinner>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

export function PlansManagerGrid(props) {
    const { records, onSelect } = props;
    const classes = useStyles();

    const handleActiveText = (value) => {
        return value === false ? 'Não' : 'Sim';
    };

    return (
        <Paper className={classes.tableContainer}>
            <Table
                className={classes.fullWidth}
                // size="small"
                padding="none"
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell align="center" style={{ width: '1%' }}>
                            #
                        </TableCell>
                        <TableCell align="left">Nome</TableCell>
                        <TableCell align="center">Usuários</TableCell>
                        <TableCell align="center">Conexões</TableCell>
                        <TableCell align="center">Filas</TableCell>
                        <TableCell align="center">Valor</TableCell>
                        <TableCell align="center">Whatsapp</TableCell>
                        <TableCell align="center">Facebook</TableCell>
                        <TableCell align="center">Instagram</TableCell>
                        <TableCell align="center">
                            Gerador <br /> de Documentos
                        </TableCell>
                        <TableCell align="center">Email</TableCell>
                        <TableCell align="center">Agendas</TableCell>
                        <TableCell align="center">Campanhas</TableCell>
                        <TableCell align="center">Agendamentos</TableCell>
                        <TableCell align="center">Chat Interno</TableCell>
                        <TableCell align="center">API Externa</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align="center" style={{ width: '1%' }}>
                                <IconButton onClick={() => onSelect(row)} aria-label="delete">
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="left">{row.name || '-'}</TableCell>
                            <TableCell align="center">{row.users || '-'}</TableCell>
                            <TableCell align="center">{row.connections || '-'}</TableCell>
                            <TableCell align="center">{row.queues || '-'}</TableCell>
                            <TableCell align="center">
                                ${' '}
                                {row.amount
                                    ? row.amount.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                    : '00.00'}
                            </TableCell>
                            <TableCell align="center">{handleActiveText(row.useWhatsapp)}</TableCell>
                            <TableCell align="center">{handleActiveText(row.useFacebook)}</TableCell>
                            <TableCell align="center">{handleActiveText(row.useInstagram)}</TableCell>
                            <TableCell align="center">{handleActiveText(row.useDocumentGenerator)}</TableCell>
                            <TableCell align="center">{handleActiveText(row.useEmail)}</TableCell>
                            <TableCell align="center">{handleActiveText(row.useExternalCalendar)}</TableCell>
                            <TableCell align="center">{handleActiveText(row.useCampaigns)}</TableCell>
                            <TableCell align="center">{handleActiveText(row.useSchedules)}</TableCell>
                            <TableCell align="center">{handleActiveText(row.useInternalChat)}</TableCell>
                            <TableCell align="center">{handleActiveText(row.useExternalApi)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}

export default function PlansManager() {
    const classes = useStyles();
    const { list, save, update, remove } = usePlans();

    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [record, setRecord] = useState({
        name: '',
        users: 0,
        connections: 0,
        queues: 0,
        amount: 0,
        useEmail: true,
        useWhatsapp: true,
        useFacebook: true,
        useInstagram: true,
        useCampaigns: true,
        useSchedules: true,
        useInternalChat: true,
        useExternalApi: true,
        useExternalCalendar: true,
        useDocumentGenerator: true,
    });

    useEffect(() => {
        async function fetchData() {
            await loadPlans();
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPlans = async () => {
        setLoading(true);
        try {
            const planList = await list();
            setRecords(planList);
        } catch (e) {
            toast.error('Não foi possível carregar a lista de registros');
        }
        setLoading(false);
    };

    const handleSubmit = async (data) => {
        console.log(data);
        setLoading(true);
        try {
            if (data.id !== undefined) {
                await update(data);
            } else {
                await save(data);
            }
            await loadPlans();
            handleCancel();
            toast.success('Operação realizada com sucesso!');
        } catch (e) {
            toast.error(
                'Não foi possível realizar a operação. Verifique se já existe uma plano com o mesmo nome ou se os campos foram preenchidos corretamente'
            );
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await remove(record.id);
            await loadPlans();
            handleCancel();
            toast.success('Operação realizada com sucesso!');
        } catch (e) {
            toast.error('Não foi possível realizar a operação');
        }
        setLoading(false);
    };

    const handleOpenDeleteDialog = () => {
        setShowConfirmDialog(true);
    };

    const handleCancel = () => {
        setRecord({
            name: '',
            users: 0,
            connections: 0,
            queues: 0,
            amount: 0,
            useEmail: true,
            useWhatsapp: true,
            useFacebook: true,
            useInstagram: true,
            useCampaigns: true,
            useSchedules: true,
            useInternalChat: true,
            useExternalApi: true,
            useExternalCalendar: true,
            useDocumentGenerator: true,
        });
    };

    const handleSelect = (data) => {
        let useEmail = data.useEmail === false ? false : true;
        let useWhatsapp = data.useWhatsapp === false ? false : true;
        let useFacebook = data.useFacebook === false ? false : true;
        let useInstagram = data.useInstagram === false ? false : true;
        let useCampaigns = data.useCampaigns === false ? false : true;
        let useSchedules = data.useSchedules === false ? false : true;
        let useInternalChat = data.useInternalChat === false ? false : true;
        let useExternalApi = data.useExternalApi === false ? false : true;
        let useExternalCalendar = data.useExternalCalendar === false ? false : true;
        let useDocumentGenerator = data.useDocumentGenerator === false ? false : true;

        setRecord({
            id: data.id,
            name: data.name || '',
            users: data.users || 0,
            connections: data.connections || 0,
            queues: data.queues || 0,
            amount: data.amount.toLocaleString('pt-br', { minimumFractionDigits: 2 }) || 0,
            useEmail,
            useWhatsapp,
            useFacebook,
            useInstagram,
            useCampaigns,
            useSchedules,
            useInternalChat,
            useExternalApi,
            useExternalCalendar,
            useDocumentGenerator,
        });
    };

    return (
        <Paper className={classes.mainPaper} elevation={0}>
            <Grid spacing={2} container>
                <Grid xs={12} item>
                    <PlanManagerForm
                        initialValue={record}
                        onDelete={handleOpenDeleteDialog}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        loading={loading}
                    />
                </Grid>
                <Grid xs={12} item>
                    <PlansManagerGrid records={records} onSelect={handleSelect} />
                </Grid>
            </Grid>
            <ConfirmationModal
                title="Exclusão de Registro"
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={() => handleDelete()}
            >
                Deseja realmente excluir esse registro?
            </ConfirmationModal>
        </Paper>
    );
}
