import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Paper, makeStyles, Typography, Button, Box } from '@material-ui/core';
import { isNil } from 'lodash';
import { AuthContext } from '../../context/Auth/AuthContext';
import { ReplyMessageProvider } from '../../context/ReplyingMessage/ReplyingMessageContext';
import toastError from '../../errors/toastError';
import socket from '../../hooks/useSocket';
import api from '../../services/api';
import ContactDrawer from '../ContactDrawer';
import MessageInput from '../MessageInputCustom/';
import MessagesList from '../MessagesList';
import { TagsContainer } from '../TagsContainer';
import TicketActionButtons from '../TicketActionButtonsCustom';
import TicketHeader from '../TicketHeader';
import TicketInfo from '../TicketInfo';
import getUUIDFromURL from '../../helpers/getUUIDFromURL';
import isConnectedWhatsapp from '../../utils/isConnectedWhatsapp';
import NotConnectionModal from '../NotConnectionModal';

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
    },
    mainWrapper: {
        flex: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: '0',
        marginRight: -drawerWidth,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    blur: {
        backdropFilter: 'blur(5px)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
    },
    errorContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        zIndex: 2,
        backgroundColor: theme.mode === 'dark' ? '#424242' : '#f0f2f5b5',
        borderRadius: 8,
        padding: theme.spacing(3),
        boxShadow: theme.shadows[5],
        width: '80%',
        maxWidth: 400,
    },
    errorMessage: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
    },
    errorButton: {
        color: '#fff',
        backgroundColor: theme.palette.error.dark,
        '&:hover': {
            backgroundColor: theme.palette.error.main,
        },
    },
    mainWrapperShift: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
}));

const Ticket = () => {
    const ticketId = getUUIDFromURL();
    const history = useHistory();
    const classes = useStyles();
    const { user } = useContext(AuthContext);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [contact, setContact] = useState({});
    const [ticket, setTicket] = useState({});
    const [isConnected, setIsConnected] = useState(true);
    const [openConnectionModal, setOpenConnectionModal] = useState(false);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTicket = async () => {
                try {
                    if (!isNil(ticketId) && ticketId !== 'undefined') {
                        const { data } = await api.get('/tickets/u/' + ticketId);
                        setContact(data.contact);
                        setTicket(data);
                        setLoading(false);
                    }
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };
            fetchTicket();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [ticketId, user, history]);

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');

        socket.emit('joinChatBox', `${ticket.id}`)

        socket.on(`company-${companyId}-ticket`, (data) => {
            if (data.action === 'update' && data.ticket?.uuid === ticket.uuid) {
                setTicket(data.ticket);
            }

            if (data.action === 'delete' && data.ticket?.uuid === ticket.uuid) {
                toast.success('Ticket deleted successfully.');
                history.push('/tickets');
            }
        });

        socket.on(`company-${companyId}-contact`, (data) => {
            if (data.action === 'update') {
                setContact((prevState) => {
                    if (prevState.id === data.contact?.id) {
                        return { ...prevState, ...data.contact };
                    }
                    return prevState;
                });
            }
        });

        return () => { };
    }, [ticketId, ticket, history]);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const renderTicketInfo = () => {
        if (ticket.user !== undefined) {
            return <TicketInfo contact={contact} ticket={ticket} onClick={handleDrawerOpen} />;
        }
    };

    const renderMessagesList = () => {
        return (
            <>
                <MessagesList ticket={ticket} ticketId={ticket.id} isGroup={ticket.isGroup} isSpying={false}></MessagesList>
                <MessageInput ticketId={ticket.id} ticketStatus={ticket.status} />
            </>
        );
    };

    useEffect(() => {
        if (ticket && !loading) {
            setIsConnected(isConnectedWhatsapp(ticket));
        }
    }, [ticket, loading]);

    return (
        <div className={classes.root} id="drawer-container">
            <Paper
                variant="outlined"
                elevation={0}
                className={clsx(classes.mainWrapper, {
                    [classes.mainWrapperShift]: drawerOpen,
                })}
            >
                {!isConnected && <div className={classes.blur} />}
                <TicketHeader loading={loading}>
                    {renderTicketInfo()}
                    <TicketActionButtons ticket={ticket} />
                </TicketHeader>
                <Paper>
                    <TagsContainer ticket={ticket} />
                </Paper>
                <ReplyMessageProvider>{renderMessagesList()}</ReplyMessageProvider>
            </Paper>

            {!isConnected && (
                <Box className={classes.errorContainer}>
                    <Typography variant="h6" className={classes.errorMessage}>
                        Whatsapp desconectado
                    </Typography>
                    <Button
                        variant="contained"
                        className={classes.errorButton}
                        onClick={() => { setOpenConnectionModal(true) }}
                    >
                        Corrigir
                    </Button>
                </Box>
            )}

            <ContactDrawer
                open={drawerOpen}
                handleDrawerClose={handleDrawerClose}
                contact={contact}
                loading={loading}
                ticket={ticket}
            />
            <NotConnectionModal
                ticketId={ticket.id}
                open={openConnectionModal}
                onClose={setOpenConnectionModal}
                onConfirm={console.info}
            />
        </div>
    );
};

export default Ticket;
