import React, { useContext, useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { Grid, ListItemText, MenuItem, Select } from '@material-ui/core';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ButtonWithSpinner from '../ButtonWithSpinner';
import ContactModal from '../ContactModal';

const filter = createFilterOptions({
    trim: true,
});

const NewTicketModal = ({ modalOpen, onClose, initialContact }) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [selectedContact, setSelectedContact] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState('');
    const [newContact, setNewContact] = useState({});
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const { user } = useContext(AuthContext);


    const ticketWithoutQueue = localStorage.getItem("ticketWithoutQueue") === 'false';

    useEffect(() => {
        if (initialContact?.id !== undefined) {
            setOptions([initialContact]);
            setSelectedContact(initialContact);
        }
    }, [initialContact]);

    useEffect(() => {
        if (!modalOpen || searchParam.length < 3) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get('contacts', {
                        params: { searchParam },
                    });
                    setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, modalOpen]);

    const handleClose = () => {
        onClose();
        setSearchParam('');
        setSelectedContact(null);
    };

    const handleSaveTicket = async (contactId) => {
        if (!contactId) return;
        if (selectedQueue === '') {
            toast.error('Selecione uma fila');
            return;
        }
        setLoading(true);
        try {
            const queueId = selectedQueue !== '' ? selectedQueue : null;
            const { data: ticket } = await api.post('/tickets', {
                contactId: contactId,
                queueId,
                userId: user.id,
                status: 'open',
            });
            onClose(ticket);
        } catch (err) {
            toastError(err);
        }
        setLoading(false);
    };

    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
            setSelectedContact(newValue);
        } else if (newValue?.name) {
            setNewContact({ name: newValue.name });
            setContactModalOpen(true);
        }
    };

    const handleCloseContactModal = () => {
        setContactModalOpen(false);
    };

    const handleAddNewContactTicket = (contact) => {
        handleSaveTicket(contact.id);
    };

    const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);

        if (params.inputValue !== '' && !loading && searchParam.length >= 3) {
            filtered.push({
                name: `${params.inputValue}`,
            });
        }

        return filtered;
    };

    const renderOption = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${i18n.t('newTicketModal.add')} ${option.name}`;
        }
    };

    const renderOptionLabel = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    const renderContactAutocomplete = () => {
        if (initialContact === undefined || initialContact.id === undefined) {
            return (
                <Grid xs={12} item>
                    <Autocomplete
                        fullWidth
                        options={options}
                        loading={loading}
                        clearOnBlur
                        autoHighlight
                        freeSolo
                        clearOnEscape
                        getOptionLabel={renderOptionLabel}
                        renderOption={renderOption}
                        filterOptions={createAddContactOption}
                        onChange={(e, newValue) => handleSelectOption(e, newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={i18n.t('newTicketModal.fieldLabel')}
                                variant="outlined"
                                autoFocus
                                onChange={(e) => setSearchParam(e.target.value)}
                                onKeyPress={(e) => {
                                    if (loading || !selectedContact) return;
                                    else if (e.key === 'Enter') {
                                        handleSaveTicket(selectedContact.id);
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Grid>
            );
        }
        return null;
    };

    return (
        <>
            <ContactModal
                open={contactModalOpen}
                initialValues={newContact}
                onClose={handleCloseContactModal}
                onSave={handleAddNewContactTicket}
            ></ContactModal>
            <Dialog open={modalOpen} onClose={handleClose}>
                <DialogTitle id="form-dialog-title">{i18n.t('newTicketModal.title')}</DialogTitle>
                <DialogContent dividers>
                    <Grid style={{ width: 300 }} container spacing={2}>
                        {renderContactAutocomplete()}
                        <Grid xs={12} item>
                            <Select
                                fullWidth
                                displayEmpty
                                variant="outlined"
                                value={selectedQueue}
                                onChange={(e) => {
                                    setSelectedQueue(e.target.value);
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                renderValue={() => {
                                    if (selectedQueue === '') {
                                        return 'Selecione uma fila';
                                    }
                                    const queue = user.queues.find((q) => q.id === selectedQueue);
                                    return queue.name;
                                }}
                            >
                                {user.queues?.length > 0 &&
                                    user.queues.map((queue, key) => (
                                        <MenuItem dense key={key} value={queue.id}>
                                            <ListItemText primary={queue.name} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={loading} variant="outlined">
                        {i18n.t('newTicketModal.buttons.cancel')}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="button"
                        disabled={!selectedContact || (!selectedQueue && ticketWithoutQueue)}
                        onClick={() => handleSaveTicket(selectedContact.id)}
                        color="primary"
                        loading={loading}
                    >
                        {i18n.t('newTicketModal.buttons.ok')}
                    </ButtonWithSpinner>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default NewTicketModal;
