function getUUIDFromURL() {
    const url = window.location.href;
    const regex = /\/tickets\/([a-f\d-]+)$/i;
    const match = url.match(regex);

    if (match) {
        return match[1];
    } else {
        return null; // Returns null if the UUID is not found in the URL.
    }
}

export default getUUIDFromURL;