import api from '../../services/api';

const useEmailFilter = () => {
    const find = async (id) => {
        const { data } = await api.request({
            url: `/emails/filters/${id}`,
            method: 'GET',
        });
        return data;
    };

    const findEmail = async (id) => {
        const { data } = await api.request({
            url: `/emails/${id}`,
            method: 'GET',
        });
        return data;
    };

    const list = async (params) => {
        const { data } = await api.request({
            url: '/emails/filters',
            method: 'GET',
            params,
        });
        return data;
    };

    const listEmails = async ({ id, pageNumber }) => {
        const { data } = await api.request({
            url: `/emails/filters/${id}/emails`,
            method: 'GET',
            params: {
                pageNumber,
            },
        });
        return data;
    };

    const save = async (data) => {
        const { data: responseData } = await api.request({
            url: '/emails/filters',
            method: 'POST',
            data,
        });
        return responseData;
    };

    const remove = async (id) => {
        const { data } = await api.request({
            url: `/emails/filters/${id}`,
            method: 'DELETE',
        });
        return data;
    };

    const update = async (data) => {
        const { data: responseData } = await api.request({
            url: `/emails/filters/${data.id}`,
            method: 'PUT',
            data,
        });
        return responseData;
    };

    const updateStatus = async (id, status) => {
        const { data } = await api.request({
            url: `/emails/filters/${id}/status`,
            method: 'PUT',
            data: {
                status,
            },
        });
        return data;
    };

    const sync = async (id) => {
        await api.request({
            url: `/emails/filters/${id}/sync`,
            method: 'GET',
        });
    };

    return {
        find,
        list,
        save,
        sync,
        update,
        remove,
        listEmails,
        findEmail,
        updateStatus,
    };
};

export default useEmailFilter;
