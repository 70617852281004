import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';

import { i18n } from '../../translate/i18n';

const ConfirmationModal = ({ title, children, open, onClose, onConfirm, onCancel }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    return (
        <Dialog open={open} onClose={() => (onCancel ? onCancel() : onClose(false))} aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent dividers>
                <Typography>{children}</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => (onCancel ? onCancel() : onClose(false))}
                    color="default"
                    disabled={isSubmitting}
                >
                    {i18n.t('confirmationModal.buttons.cancel')}
                </Button>
                <Button
                    variant="contained"
                    onClick={async () => {
                        setIsSubmitting(true);
                        !onCancel && onClose(false);
                        await onConfirm();
                        setIsSubmitting(false);
                    }}
                    disabled={isSubmitting}
                    color="secondary"
                >
                    {i18n.t('confirmationModal.buttons.confirm')}
                    {isSubmitting && <CircularProgress size={24} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationModal;
