import { Box, FormControlLabel, IconButton } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import SaveIcon from '@material-ui/icons/Save';
import { Field, FieldArray, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ConfirmationModal from '../ConfirmationModal';
import CustomToolTip from '../ToolTips';
import FileUpload from '../FileUpload';

const QueueSchema = Yup.object().shape({
    options: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().min(4, 'Muito Curto').required('Requerido'),
            })
        )
        .required('Deve ter amigos'),
});

const useStyles = makeStyles((theme) => ({
    greetingMessage: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    Box: {
        cursor: 'pointer',
        alignItems: 'center',
    },
}));

function getStepContent(step) {
    return <VerticalLinearStepper chatBotId={step} />;
}

function removeMediaUrlKey(obj) {
    if (Array.isArray(obj)) {
        obj.forEach(item => removeMediaUrlKey(item));
    } else if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
            if (key === 'mediaUrl') {
                delete obj[key];
            } else {
                removeMediaUrlKey(obj[key]);
            }
        }
    }
}

export default function VerticalLinearStepper(props) {
    const initialState = {
        name: '',
        greetingMessage: '',
        options: [],
        mediaUrl: null
    };

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(-1);
    const [steps, setSteps] = React.useState(initialState);
    const [loading, setLoading] = React.useState(false);
    const [isStepContent, setIsStepContent] = React.useState(true);
    const [isNameEdit, setIsNamedEdit] = React.useState(null);
    const [isGreetingMessageEdit, setGreetingMessageEdit] = React.useState(null);
    const [selectedQueue, setSelectedQueue] = React.useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
    const [filesData, setFilesData] = React.useState({ chatbot: {} });

    const handleSaveBot = async (values) => {


        
        try {
            if (props.chatBotId) {
                await api.put(`/chatbot/${props.chatBotId}`, values);
            } else {
                await api.post('/chatbot', values);
            }

            toast.success('Bot salvo com sucesso!');
            // setActiveStep(-1)
            const { data } = await api.get(`/chatbot/${props.chatBotId}`);

            setSteps(initialState);
            setSteps(data);
            setIsNamedEdit(null);
            setGreetingMessageEdit(null);
            setSteps(data);
        } catch (err) {
            toastError(err);
        }
    };

    const handleSaveFile = async (chatbotId, file) => {
        const formData = new FormData();
        formData.append('medias', file);
        await api.post(`/chatbot/m/${chatbotId}`, formData);
    };

    const handleDeleteFile = async (chatbotId) => {
        const formData = new FormData();
        formData.append('medias', null);
        await api.post(`/chatbot/m/${chatbotId}`, formData);
    };

    React.useEffect(() => {
        setLoading(true);

        const delayDebounceFn = setTimeout(() => {
            const fetchList = async () => {
                try {
                    const { data } = await api.get(`/chatbot/${props.chatBotId}`);
                    setSteps(data);
                    setLoading(false);
                } catch (err) {
                    console.log(err);
                }
            };
            fetchList();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [props.chatBotId]);

    React.useEffect(() => {
        if (activeStep === isNameEdit) {
            setIsStepContent(false);
        } else {
            setIsStepContent(true);
        }
    }, [isNameEdit, activeStep]);

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false);
        setSelectedQueue(null);
    };

    const handleDeleteQueue = async (queueId) => {
        try {
            await api.delete(`/chatbot/${queueId}`);
            const { data } = await api.get(`/chatbot/${props.chatBotId}`);
            setSteps(initialState);
            setSteps(data);
            setIsNamedEdit(null);
            setGreetingMessageEdit(null);
            setSteps(data);
            toast.success(i18n.t('Fila excluída com sucesso!'));
        } catch (err) {
            toastError(err);
        }
        setSelectedQueue(null);
    };

    const handleSaveFileInQueue = async (id, isChatbot, file) => {
        if (isChatbot && id) {
            try {
                if (file) {
                    // Se o file não for vazio, atualiza ou adiciona
                    await handleSaveFile(id, file);
                    setFilesData(prevState => {
                        const updatedChatbot = { ...prevState.chatbot };
                        updatedChatbot[id] = file;
                        return {
                            ...prevState,
                            chatbot: updatedChatbot
                        };
                    });
                } else {
                    // Se o file for vazio, remove o item
                    await handleDeleteFile(id);
                    setFilesData(prevState => {
                        const updatedChatbot = { ...prevState.chatbot };
                        delete updatedChatbot[id];
                        return {
                            ...prevState,
                            chatbot: updatedChatbot
                        };
                    });
                }
            } catch (error) {
                console.error(error);
            } finally {
                console.info("Finalizou upload")
            }
        }
    };


    return (
        <div className={classes.root}>
            <ConfirmationModal
                title={selectedQueue && `${i18n.t('queues.confirmationModal.deleteTitle')} ${selectedQueue.name}?`}
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteQueue(selectedQueue.id)}
            >
                {i18n.t('Tem certeza? Todas as opções internas também serão excluídas')}
            </ConfirmationModal>

            {!loading && (
                <div>
                    <Formik
                        initialValues={steps}
                        validateOnChange={false}
                        enableReinitialize={true}
                        validationSchema={QueueSchema}
                        render={({ touched, errors, isSubmitting, values, handleSubmit }) => (
                            <FieldArray name="options">
                                {({ push, remove }) => (
                                    <>
                                        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                                            {values.options &&
                                                values.options.length > 0 &&
                                                values.options.map((info, index) => (
                                                    <Step
                                                        key={`${info.id ? info.id : index}-options`}
                                                        onClick={() => setActiveStep(index)}
                                                    >
                                                        <StepLabel key={`${info.id}-options`}>
                                                            {isNameEdit !== index && steps.options[index]?.name ? (
                                                                <div
                                                                    className={classes.greetingMessage}
                                                                    variant="body1"
                                                                >
                                                                    {values.options[index].name}

                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setIsNamedEdit(index);
                                                                            setIsStepContent(false);
                                                                        }}
                                                                    >
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setSelectedQueue(info);
                                                                            setConfirmModalOpen(true);
                                                                        }}
                                                                        size="small"
                                                                    >
                                                                        <DeleteOutline />
                                                                    </IconButton>
                                                                    <Box>
                                                                        <FileUpload
                                                                            typeComponent="chatbot"
                                                                            onChange={file => {
                                                                                handleSaveFileInQueue(values.options[index].id, true, file)
                                                                            }}
                                                                            file={values.options[index].mediaUrl}
                                                                            styleComponent={{
                                                                                borderRadius: '6px',
                                                                                height: '30px'
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <Field
                                                                        as={TextField}
                                                                        name={`options[${index}].name`}
                                                                        variant="standard"
                                                                        color="primary"
                                                                        disabled={isSubmitting}
                                                                        autoFocus
                                                                        error={
                                                                            touched?.options?.[index]?.name &&
                                                                            Boolean(errors.options?.[index]?.name)
                                                                        }
                                                                        className={classes.textField}
                                                                    />

                                                                    <FormControlLabel
                                                                        control={
                                                                            <Field
                                                                                as={Switch}
                                                                                color="primary"
                                                                                name={`options[${index}].isAgent`}
                                                                                checked={
                                                                                    values.options[index].isAgent ||
                                                                                    false
                                                                                }
                                                                            />
                                                                        }
                                                                        label="Atendente"
                                                                    />

                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() =>
                                                                            values.options[index].name
                                                                                ? handleSaveBot(values)
                                                                                : null
                                                                        }
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        <SaveIcon />
                                                                    </IconButton>

                                                                    <IconButton
                                                                        size="small"
                                                                        onClick={() => remove(index)}
                                                                        disabled={isSubmitting}
                                                                    >
                                                                        <DeleteOutline />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </StepLabel>

                                                        {isStepContent && steps.options[index] && (
                                                            <StepContent>
                                                                <>
                                                                    {isGreetingMessageEdit !== index ? (
                                                                        <div className={classes.greetingMessage}>
                                                                            <Typography
                                                                                color="textSecondary"
                                                                                variant="body1"
                                                                            >
                                                                                Message:
                                                                            </Typography>

                                                                            {values.options[index].greetingMessage}

                                                                            {!steps.options[index]?.greetingMessage && (
                                                                                <CustomToolTip
                                                                                    title="A mensagem é necessária para ir para o próximo nível."
                                                                                    content="Se a mensagem não for definida, o bot não seguirá."
                                                                                >
                                                                                    <HelpOutlineOutlinedIcon
                                                                                        color="secondary"
                                                                                        style={{ marginLeft: '4px' }}
                                                                                        fontSize="small"
                                                                                    />
                                                                                </CustomToolTip>
                                                                            )}

                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() =>
                                                                                    setGreetingMessageEdit(index)
                                                                                }
                                                                            >
                                                                                <EditIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    ) : (
                                                                        <div className={classes.greetingMessage}>
                                                                            <Field
                                                                                as={TextField}
                                                                                name={`options[${index}].greetingMessage`}
                                                                                variant="standard"
                                                                                margin="dense"
                                                                                fullWidth
                                                                                multiline
                                                                                error={
                                                                                    touched.greetingMessage &&
                                                                                    Boolean(errors.greetingMessage)
                                                                                }
                                                                                helperText={
                                                                                    touched.greetingMessage &&
                                                                                    errors.greetingMessage
                                                                                }
                                                                                className={classes.textField}
                                                                            />

                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => handleSaveBot(values)}
                                                                                disabled={isSubmitting}
                                                                            >
                                                                                {' '}
                                                                                <SaveIcon />
                                                                            </IconButton>
                                                                        </div>
                                                                    )}

                                                                    {getStepContent(info.id)}
                                                                </>
                                                            </StepContent>
                                                        )}
                                                    </Step>
                                                ))}

                                            <Step>
                                                <StepLabel
                                                    onClick={() =>
                                                        push({
                                                            name: undefined,
                                                            greetingMessage: undefined,
                                                        })
                                                    }
                                                >
                                                    Adicionar opções
                                                </StepLabel>
                                            </Step>
                                        </Stepper>
                                    </>
                                )}
                            </FieldArray>
                        )}
                    />
                </div>
            )}
        </div>
    );
}
