import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItemText,
    MenuItem,
    TextField,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import toastError from '@App/errors/toastError';
import useEmailContact from '@App/hooks/useEmailContact';
import { i18n } from '@App/translate/i18n';
import { FlexBoxColumn } from '@Components/(atoms)/FlexBox';
import { Calendar } from '@Components/(molecules)/Calendar';
import ConnectionIcon from '@Components/ConnectionIcon';
import {
    ScheduleExternalCalendarContainer,
    ScheduleTitleContainer,
    TitleContainer,
} from '@Components/EmailContactModals/AddSchedule/style';

const useStyles = makeStyles((theme) => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const EmailContactAddCommentSchema = Yup.object().shape({
    date: Yup.date().min(moment().startOf('day'), 'Data inválida').required('Campo obrigatório'),
    time: Yup.string().test('is-valid-time', 'Hora inválida', (value, context) => {
        const { date } = context.parent;
        const currentDate = moment().startOf('day');
        const selectedDate = moment(date, 'YYYY-MM-DD');
        const currentTime = moment(value, 'HH:mm');

        if (selectedDate.isSame(currentDate, 'day')) {
            const minimumTime = moment().add(1, 'hour');
            return currentTime.isSameOrAfter(minimumTime);
        }

        return currentTime.isValid();
    }),
    eventTitle: Yup.string().required('Campo obrigatório'),
    eventDescription: Yup.string().required('Campo obrigatório'),
    externalCalendarIds: Yup.array().required('Campo obrigatório'),
});

const AddScheduleModal = ({ open, onClose, suggestedEvent, externalCalendars, emailContact }) => {
    const { addSchedule } = useEmailContact();
    useEffect(() => {
        if (!externalCalendars || externalCalendars.length === 0) {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalCalendars]);

    const hasMoreThanOneExternalCalendar = externalCalendars && externalCalendars.length > 1;
    const initialValues = {
        date: suggestedEvent?.date || moment().format('YYYY-MM-DD'),
        time: suggestedEvent?.time || moment().startOf('hour').add(2, 'hour').format('HH:mm'),
        eventTitle: suggestedEvent?.title || '',
        eventDescription: suggestedEvent?.description || '',
        externalCalendarIds: hasMoreThanOneExternalCalendar ? [] : [externalCalendars[0].id],
    };

    const handleAddSchedule = async (values, actions) => {
        try {
            actions.setSubmitting(true);
            await addSchedule({
                scheduleAt: moment(`${values.date} ${values.time}`).toISOString(),
                eventTitle: getFormattedTitle(values.eventTitle),
                eventDescription: values.eventDescription,
                externalCalendarIds: values.externalCalendarIds,
                emailContactId: emailContact.id,
            });
            toast.success(i18n.t('emailContact.addSchedule.modal.toasts.success'));
            onClose();
        } catch (err) {
            toastError(err);
        }
    };

    const getFormattedTitle = (title) =>
        `[${emailContact.contactFilter.value} - ${emailContact.contactFilter.contact.name}] - ${title}`;

    const AddSchduleForm = ({ values, isSubmitting, isValid, touched, errors }) => {
        const classes = useStyles();

        const ExternalCalendarChip = useCallback(
            (selected) => (
                <div className={classes.chips}>
                    {selected.map((value) => {
                        const externalCalendar = externalCalendars.find((ec) => ec.id === value);
                        return (
                            <Chip
                                key={value}
                                size="small"
                                style={{ paddingLeft: 6 }}
                                icon={<ConnectionIcon connectionType={externalCalendar.type} size="18px" />}
                                label={externalCalendar.username}
                                className={classes.chip}
                            />
                        );
                    })}
                </div>
            ),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
        );

        return (
            <Form>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {hasMoreThanOneExternalCalendar && (
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    variant="outlined"
                                    margin="dense"
                                    required
                                    fullWidth
                                    label={i18n.t('emailContact.addSchedule.modal.form.externalCalendars')}
                                    name="externalCalendarIds"
                                    error={touched.externalCalendarIds && Boolean(errors.externalCalendarIds)}
                                    helperText={touched.externalCalendarIds && errors.externalCalendarIds}
                                    select
                                    SelectProps={{
                                        multiple: true,
                                        renderValue: ExternalCalendarChip,
                                    }}
                                    disabled={isSubmitting}
                                >
                                    {externalCalendars.map((externalCalendar) => (
                                        <MenuItem key={externalCalendar.id} value={externalCalendar.id}>
                                            <Checkbox
                                                checked={values.externalCalendarIds.indexOf(externalCalendar.id) > -1}
                                            />
                                            <ListItemText primary={externalCalendar.username} />
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Field
                                as={TextField}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={i18n.t('emailContact.addSchedule.modal.form.date')}
                                name="date"
                                type="date"
                                error={touched.date && Boolean(errors.date)}
                                helperText={touched.date && errors.date}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={isSubmitting}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                as={TextField}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={i18n.t('emailContact.addSchedule.modal.form.time')}
                                name="time"
                                type="time"
                                error={touched.time && Boolean(errors.time)}
                                helperText={touched.time && errors.time}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={isSubmitting}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                label={i18n.t('emailContact.addSchedule.modal.form.eventTitle')}
                                error={touched.eventTitle && Boolean(errors.eventTitle)}
                                helperText={touched.eventTitle && errors.eventTitle}
                                name="eventTitle"
                                disabled={isSubmitting}
                            />
                            {values.eventTitle && (
                                <Typography variant="subtitle2" color="textSecondary">
                                    <em>{getFormattedTitle(values.eventTitle)}</em>
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                minRows={5}
                                multiline={true}
                                label={i18n.t('emailContact.addSchedule.modal.form.eventDescription')}
                                error={touched.eventDescription && Boolean(errors.eventDescription)}
                                helperText={touched.eventDescription && errors.eventDescription}
                                name="eventDescription"
                                disabled={isSubmitting}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" disabled={isSubmitting} variant="outlined">
                        {i18n.t('emailContact.addSchedule.modal.buttons.cancel')}
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting || !isValid} variant="contained">
                        {i18n.t('emailContact.addSchedule.modal.buttons.okAdd')}
                        {isSubmitting && <CircularProgress size={24} />}
                    </Button>
                </DialogActions>
            </Form>
        );
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll="paper">
            <DialogTitle disableTypography>
                <TitleContainer>
                    <ScheduleTitleContainer>
                        <Typography variant="h6">{i18n.t('emailContact.addSchedule.modal.title')}</Typography>
                        <Typography>
                            <em>
                                {emailContact.contactFilter.value} | {emailContact.contactFilter.contact.name}
                            </em>
                        </Typography>
                    </ScheduleTitleContainer>
                    {!hasMoreThanOneExternalCalendar && (
                        <ScheduleExternalCalendarContainer>
                            <ConnectionIcon connectionType={externalCalendars[0].type} />
                            <FlexBoxColumn>
                                <Typography>{externalCalendars[0].username}</Typography>
                                {externalCalendars[0].calendarId && (
                                    <Calendar
                                        calendar={{
                                            id: externalCalendars[0].calendarId,
                                            summary: externalCalendars[0].calendarSummary,
                                            backgroundColor: externalCalendars[0].calendarBackgroundColor,
                                        }}
                                    />
                                )}
                            </FlexBoxColumn>
                        </ScheduleExternalCalendarContainer>
                    )}
                </TitleContainer>
            </DialogTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={EmailContactAddCommentSchema}
                validateOnMount
                enableReinitialize
                onSubmit={handleAddSchedule}
            >
                {(props) => <AddSchduleForm {...props} />}
            </Formik>
        </Dialog>
    );
};

export default AddScheduleModal;
