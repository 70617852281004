import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import toastError from '@App/errors/toastError';
import { i18n } from '@App/translate/i18n';
import ConfirmationModal from '@Components/ConfirmationModal';
import Schedules from '@Components/EmailContactModals/ListSchedules/(components)/Schedules';
import useEmailContact from '@Hooks/useEmailContact';

const useStyles = makeStyles(() => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
}));

const getScheduleDate = (schedule) => {
    return moment(schedule.scheduledAt).startOf('day');
};

const sortShedules = (s1, s2) => (moment(s1.scheduledAt).isBefore(moment(s2.scheduledAt)) ? -1 : 1);

const ScheduleListModal = ({ open, onClose, emailContact, showName }) => {
    const classes = useStyles();
    const today = moment().startOf('day');
    const { listSchedules, removeSchedule } = useEmailContact();

    const [schedules, setSchedules] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedSchedule, setSelectedSchedule] = useState(null);

    useEffect(() => {
        fetchSchedules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchSchedules = async () => {
        try {
            setIsFetching(true);
            setSchedules(await listSchedules({ emailContactId: emailContact?.id }));
        } catch (err) {
            console.error(err);
            toastError(err, 4000);
        }
        setIsFetching(false);
    };

    const handleSubmitConfirmationModal = async () => {
        try {
            await removeSchedule({ scheduleId: selectedSchedule.id });
            await fetchSchedules();
            handleConfirmationModalClose();
            toast.success(i18n.t('emailContact.listSchedules.toasts.deleted'));
        } catch (err) {
            console.error(err);
            toastError(err, 4000);
        }
    };

    const handleConfirmationModalClose = () => {
        setConfirmModalOpen(false);
        setSelectedSchedule(null);
    };

    const handleOnDelete = (schedule) => {
        setSelectedSchedule(schedule);
        setConfirmModalOpen(true);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth scroll="paper">
                <DialogTitle>
                    {i18n.t('emailContact.listSchedules.modal.title')}
                    {!showName && ` - ${emailContact.contactFilter.contact.name}`}
                </DialogTitle>
                <DialogContent dividers>
                    <div className={classes.mainContainer}>
                        <Schedules
                            schedules={schedules
                                .filter((schedule) => getScheduleDate(schedule).isSame(today))
                                .sort(sortShedules)}
                            onDelete={handleOnDelete}
                            isFetching={isFetching}
                            showName={showName}
                            title={i18n.t('emailContact.listSchedules.modal.todaySchedules')}
                        />
                        <Schedules
                            schedules={schedules
                                .filter((schedule) => getScheduleDate(schedule).isAfter(today))
                                .sort(sortShedules)}
                            onDelete={handleOnDelete}
                            isFetching={isFetching}
                            showName={showName}
                            title={i18n.t('emailContact.listSchedules.modal.futureSchedules')}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {i18n.t('emailContact.listSchedules.modal.buttons.close')}
                    </Button>
                </DialogActions>
            </Dialog>

            <ConfirmationModal
                title={i18n.t('emailContact.listSchedules.modal.deleteConfirmation.title')}
                open={confirmModalOpen}
                onCancel={handleConfirmationModalClose}
                onConfirm={handleSubmitConfirmationModal}
            >
                {i18n.t('emailContact.listSchedules.modal.deleteConfirmation.message')}
            </ConfirmationModal>
        </>
    );
};

export default ScheduleListModal;
