import React, { useContext, useEffect, useRef, useState } from 'react';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import toastError from '../../errors/toastError';
import useQueues from '../../hooks/useQueues';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ButtonWithSpinner from '../ButtonWithSpinner';
import UserStatusIcon from '../UserModal/statusIcon';
import { AuthContext } from '../../context/Auth/AuthContext';

const useStyles = makeStyles((theme) => ({
    maxWidth: {
        width: '100%',
    },
}));

const filterOptions = createFilterOptions({
    trim: true,
});

const TransferTicketModalCustom = ({ modalOpen, onClose, ticketid, title, buttonText }) => {
    const [options, setOptions] = useState([]);
    const [queues, setQueues] = useState([]);
    const [allQueues, setAllQueues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState('');
    const classes = useStyles();
    const { findAll: findAllQueues } = useQueues();
    const { user } = useContext(AuthContext);
    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            const loadQueues = async () => {
                const list = await findAllQueues();
                setAllQueues(list);
                setQueues(list);
            };
            loadQueues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!modalOpen || searchParam.length < 3) {
            setLoading(false);
            return;
        }
        const delayDebounceFn = setTimeout(() => {
            setLoading(true);
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get('/users/', {
                        params: { searchParam },
                    });
                    setOptions(data.users);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };

            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, modalOpen]);

    useEffect(() => {
        if (user && modalOpen) {
            setLoading(true);
            try {
                (async () => {
                    const { data } = await api.get('/users/', {
                        params: { searchParam: user.name },
                    });
                    const filterUser = data.users.filter(usr => usr.id === user.id)
                    setOptions(data.users);
                    setTimeout(() => {
                        handleSelectedUser(filterUser[0])
                    }, 500);
                })();

            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false);
            }
        }
    }, [user, modalOpen]);

    const handleClose = async (success) => {
        await onClose(success);
        setSearchParam('');
        setSelectedUser(null);
    };

    const handleSaveTicket = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            let data = {};

            if (!selectedQueue) return toastError("Selecione uma fila");

            if (selectedUser) {
                data.userId = selectedUser.id;
                data.status = 'open';
            }

            if (selectedQueue && selectedQueue !== null) {
                data.queueId = selectedQueue;

                if (!selectedUser) {
                    data.status = 'pending';
                    data.userId = null;
                }
            }

            await api.put(`/tickets/${ticketid}`, data);
            handleClose(true);
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };

    const handleSelectedUser = (newValue) => {
        setSelectedUser(newValue);
        if (newValue != null && Array.isArray(newValue.queues)) {
            setQueues(newValue.queues);
        } else {
            setQueues(allQueues);
            setSelectedQueue('');
        }
    };

    return (
        <Dialog open={modalOpen} onClose={() => handleClose(false)} maxWidth="lg" scroll="paper">
            <form onSubmit={handleSaveTicket}>
                <DialogTitle id="form-dialog-title">{title || i18n.t('transferTicketModal.title')}</DialogTitle>
                <DialogContent dividers>
                    <Autocomplete
                        style={{ width: 300, marginBottom: 20 }}
                        getOptionLabel={(option) => `${option.name}`}
                        onChange={(e, newValue) => handleSelectedUser(newValue)}
                        options={options}
                        defaultValue={user}
                        filterOptions={filterOptions}
                        freeSolo
                        autoHighlight
                        noOptionsText={i18n.t('transferTicketModal.noOptions')}
                        loading={loading}
                        renderOption={(option) => (
                            <span>
                                {' '}
                                <UserStatusIcon user={option} /> {option.name}
                            </span>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={i18n.t('transferTicketModal.fieldLabel')}
                                variant="outlined"
                                autoFocus
                                onChange={(e) => setSearchParam(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                            />
                        )}
                    />
                    <FormControl variant="outlined" className={classes.maxWidth}>
                        <InputLabel>{i18n.t('transferTicketModal.fieldQueueLabel')}</InputLabel>
                        <Select
                            value={selectedQueue}
                            onChange={(e) => setSelectedQueue(e.target.value)}
                            label={i18n.t('transferTicketModal.fieldQueuePlaceholder')}
                        >
                            {queues.map((queue) => (
                                <MenuItem key={queue.id} value={queue.id}>
                                    {queue.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose(false)} color="secondary" disabled={loading} variant="outlined">
                        {i18n.t('transferTicketModal.buttons.cancel')}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        type="submit"
                        color="primary"
                        loading={loading}
                        disabled={!ticketid || !selectedQueue || selectedQueue === ''}
                    >
                        {buttonText || i18n.t('transferTicketModal.buttons.ok')}
                    </ButtonWithSpinner>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default TransferTicketModalCustom;
