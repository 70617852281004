import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import { i18n } from '../../translate/i18n';
import { UsersFilter } from '../UsersFilter';

const useStyles = makeStyles((theme) => ({
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const ExternalCalendarUserPermissionModal = ({ open, onClose, onSuccess, connection }) => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (connection) {
            setUsers(connection.users);
        }
    }, [connection]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth scroll="paper">
            <DialogTitle>{i18n.t('connections.externalCalendar.userPermissionModal.title')}</DialogTitle>
            <DialogContent>
                <UsersFilter onFiltered={setUsers} initialUsers={users} />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={async () => {
                        setSubmitting(true);
                        if (onSuccess) {
                            await onSuccess(users);
                        } else {
                            onClose();
                        }
                        setSubmitting(false);
                    }}
                    color="primary"
                >
                    {i18n.t('connections.externalCalendar.userPermissionModal.save')}
                    {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
                <Button variant="outlined" onClick={onClose} color="secondary">
                    {i18n.t('connections.externalCalendar.userPermissionModal.cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExternalCalendarUserPermissionModal;
