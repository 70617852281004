import React from 'react';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import googleIcon from '../../assets/google-icon.png';

const ConnectionIcon = ({ connectionType, style, size }) => {
    return (
        <>
            {connectionType === 'whatsapp' && (
                <WhatsAppIcon fontSize="small" style={{ marginBottom: '-5px', color: '#25D366' }} />
            )}
            {connectionType === 'instagram' && (
                <InstagramIcon fontSize="small" style={{ marginBottom: '-5px', color: '#e1306c' }} />
            )}
            {connectionType === 'facebook' && (
                <FacebookIcon fontSize="small" style={{ marginBottom: '-5px', color: '#3b5998' }} />
            )}
            {connectionType === 'google' && (
                <img
                    src={googleIcon}
                    alt="Google"
                    style={{ display: 'inline-block', width: size || '24px', height: size || '24px', ...style }}
                />
            )}
        </>
    );
};

export default ConnectionIcon;
