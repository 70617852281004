import { IconButton, Popover } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useViewIconStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

const IconButtonPopover = ({ onClick, label, children, size = 'medium', loading = false, ...props }) => {
    const classes = useViewIconStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton
                onClick={onClick}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                size={size}
                {...props}
            >
                {loading ? <CircularProgress {...(size === 'small' && { size: 24 })} /> : children}
            </IconButton>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {label}
            </Popover>
        </>
    );
};

export default IconButtonPopover;
