import api from '../../services/api';

const useEmailContact = () => {
    const list = async (params) => {
        const { data } = await api.request({
            url: '/emails/contacts',
            method: 'GET',
            params,
        });
        return data;
    };

    const addComment = async ({ emailContactId, ...body }) => {
        const { data } = await api.request({
            url: `/emails/contacts/${emailContactId}/comments`,
            method: 'PUT',
            data: body,
        });
        return data;
    };

    const addSchedule = async ({ emailContactId, ...body }) => {
        const { data } = await api.request({
            url: `/emails/contacts/${emailContactId}/schedules`,
            method: 'POST',
            data: body,
        });
        return data;
    };

    const listSchedules = async ({ emailContactId }) => {
        const { data } = await api.request({
            url: emailContactId ? `/emails/contacts/${emailContactId}/schedules` : '/emails/contacts/schedules',
            method: 'GET',
        });
        return data;
    };

    const removeSchedule = async ({ scheduleId }) => {
        await api.request({
            url: `/emails/contacts/schedules/${scheduleId}`,
            method: 'DELETE',
        });
    };

    return {
        list,
        addComment,
        addSchedule,
        removeSchedule,
        listSchedules,
    };
};

export default useEmailContact;
