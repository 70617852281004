import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import Title from '../../components/Title';

import { Chip } from '@material-ui/core';
import ConfirmationModal from '../../components/ConfirmationModal';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import TagModal from '../../components/TagModal';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import socket from '../../hooks/useSocket';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';

const reducer = (state, action) => {
    if (action.type === 'LOAD_TAGS') {
        const tags = action.payload;
        const newTags = [];

        tags.forEach((tag) => {
            const tagIndex = state.findIndex((s) => s.id === tag.id);
            if (tagIndex !== -1) {
                state[tagIndex] = tag;
            } else {
                newTags.push(tag);
            }
        });

        return [...state, ...newTags];
    }

    if (action.type === 'UPDATE_TAGS') {
        const tag = action.payload;
        const tagIndex = state.findIndex((s) => s.id === tag.id);

        if (tagIndex !== -1) {
            state[tagIndex] = tag;
            return [...state];
        } else {
            return [tag, ...state];
        }
    }

    if (action.type === 'DELETE_TAG') {
        const tagId = action.payload;

        const tagIndex = state.findIndex((s) => s.id === tagId);
        if (tagIndex !== -1) {
            state.splice(tagIndex, 1);
        }
        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}));

const Tags = () => {
    const classes = useStyles();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [deletingTag, setDeletingTag] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [tags, dispatch] = useReducer(reducer, []);
    const [tagModalOpen, setTagModalOpen] = useState(false);

    const fetchTags = useCallback(async () => {
        try {
            const { data } = await api.get('/tags/', {
                params: { searchParam, pageNumber, kanban: 0 },
            });
            dispatch({ type: 'LOAD_TAGS', payload: data.tags });
            setHasMore(data.hasMore);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    }, [searchParam, pageNumber]);

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchTags();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, fetchTags]);

    useEffect(() => {

        socket.on('user', (data) => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_TAGS', payload: data.tags });
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_TAGS', payload: +data.tagId });
            }
        });

        return () => { };
    }, [user]);

    const handleOpenTagModal = () => {
        setSelectedTag(null);
        setTagModalOpen(true);
    };

    const handleCloseTagModal = () => {
        setSelectedTag(null);
        setTagModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditTag = (tag) => {
        setSelectedTag(tag);
        setTagModalOpen(true);
    };

    const handleDeleteTag = async (tagId) => {
        try {
            await api.delete(`/tags/${tagId}`);
            toast.success(i18n.t('tags.toasts.deleted'));
        } catch (err) {
            toastError(err);
        }
        setDeletingTag(null);
        setSearchParam('');
        setPageNumber(1);

        dispatch({ type: 'RESET' });
        setPageNumber(1);
        await fetchTags();
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={deletingTag && `${i18n.t('tags.confirmationModal.deleteTitle')}`}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteTag(deletingTag.id)}
            >
                {i18n.t('tags.confirmationModal.deleteMessage')}
            </ConfirmationModal>
            <TagModal
                open={tagModalOpen}
                onClose={handleCloseTagModal}
                reload={fetchTags}
                aria-labelledby="form-dialog-title"
                tagId={selectedTag && selectedTag.id}
                kanban={0}
            />
            <MainHeader>
                <Title>
                    {i18n.t('tags.title')} ({tags.length})
                </Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t('contacts.searchPlaceholder')}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon style={{ color: 'gray' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button variant="contained" color="primary" onClick={handleOpenTagModal}>
                        {i18n.t('tags.buttons.add')}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t('tags.table.name')}</TableCell>
                            <TableCell align="center">{i18n.t('tags.table.tickets')}</TableCell>
                            <TableCell align="center">{i18n.t('tags.table.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {tags.map((tag) => (
                                <TableRow key={tag.id}>
                                    <TableCell align="center">
                                        <Chip
                                            variant="outlined"
                                            style={{
                                                backgroundColor: tag.color,
                                                textShadow: '1px 1px 1px #000',
                                                color: 'white',
                                            }}
                                            label={tag.name}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell align="center">{tag.ticketsCount}</TableCell>
                                    <TableCell align="center">
                                        <IconButton size="small" onClick={() => handleEditTag(tag)}>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmModalOpen(true);
                                                setDeletingTag(tag);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Tags;
