import React, { useEffect, useState } from 'react';

import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import AIPromptModal from '../../components/AIPromptModal';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import Title from '../../components/Title';
import useAI from '../../hooks/useAI';
import { i18n } from '../../translate/i18n';

const AIPrompts = () => {
    const { getPrompts } = useAI();

    const [prompts, setPrompts] = useState([]);
    const [selectedPromptId, setSelectedPromptId] = useState();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        (async function fecthPrompts() {
            setPrompts(await getPrompts());
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEditPrompt = (prompt) => {
        setSelectedPromptId(prompt.id);
        setOpenModal(true);
    };

    return (
        <MainContainer>
            <MainHeader>
                <Title>
                    {i18n.t('ai.prompt.title')} ({prompts.length})
                </Title>
            </MainHeader>
            <Paper variant="outlined">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{i18n.t('ai.prompt.table.type')}</TableCell>
                                <TableCell>{i18n.t('ai.prompt.table.target')}</TableCell>
                                <TableCell>{i18n.t('ai.prompt.table.modelName')}</TableCell>
                                <TableCell>{i18n.t('ai.prompt.table.actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prompts.map((prompt) => (
                                <TableRow key={prompt.id}>
                                    <TableCell>{i18n.t(`ai.prompt.enums.${prompt.type}`)}</TableCell>
                                    <TableCell>{i18n.t(`ai.prompt.enums.${prompt.target}`)}</TableCell>
                                    <TableCell>{prompt.modelName}</TableCell>
                                    <TableCell>
                                        <IconButton size="small" onClick={() => handleEditPrompt(prompt)}>
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {openModal && <AIPromptModal open promptId={selectedPromptId} onClose={() => setOpenModal(false)} />}
        </MainContainer>
    );
};

export default AIPrompts;
