import React, { useContext, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { format, isSameDay, parseISO } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
// import { green, grey, red, blue } from "@material-ui/core/colors";
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { blue, green, grey } from '@material-ui/core/colors';
import ErrorIcon from '@material-ui/icons/Error';

import { i18n } from '../../translate/i18n';

import { Dialog, IconButton, Tooltip } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from '../../context/Auth/AuthContext';
import { TicketsContext } from '../../context/Tickets/TicketsContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import ButtonWithSpinner from '../ButtonWithSpinner';
import MarkdownWrapper from '../MarkdownWrapper';

import AndroidIcon from '@material-ui/icons/Android';
import GroupIcon from '@material-ui/icons/Group';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ConnectionIcon from '../ConnectionIcon';
import ContactTag from '../ContactTag';
import TicketMessagesDialog from '../TicketMessagesDialog';
import TransferTicketModalCustom from '../TransferTicketModalCustom';
import TicketBadge from './TicketBadge';
import TicketQueueModal from '../TicketQueueModal';
import isConnectedWhatsapp from '../../utils/isConnectedWhatsapp';
import NotConnectionModal from '../NotConnectionModal';

const useStyles = makeStyles((theme) => ({
    ticket: {
        position: 'relative',
        minHeight: '115px',
    },

    pendingTicket: {
        cursor: 'unset',
    },
    queueTag: {
        background: '#FCFCFC',
        color: '#000',
        marginRight: 1,
        padding: 1,
        fontWeight: 'bold',
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 3,
        fontSize: '0.8em',
        whiteSpace: 'nowrap',
    },
    noTicketsDiv: {
        display: 'flex',
        height: '100px',
        margin: 40,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    newMessagesCount: {
        position: 'absolute',
        alignSelf: 'center',
        marginRight: 8,
        marginLeft: 'auto',
        top: '25px',
        left: '25px',
        borderRadius: 0,
    },
    noTicketsText: {
        textAlign: 'center',
        color: 'rgb(104, 121, 146)',
        fontSize: '14px',
        lineHeight: '1.4',
    },
    noTicketsTitle: {
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: '600',
        margin: '0px',
    },

    contactNameWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        marginLeft: '5px',
    },

    lastMessageTime: {
        background: '#333333',
        color: '#ffffff',
        border: '1px solid #3a3b6c',
        padding: 1,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 5,
        fontSize: '0.9em',
    },

    closedBadge: {
        alignSelf: 'center',
        justifySelf: 'flex-end',
        marginRight: 32,
        marginLeft: 'auto',
    },

    contactLastMessage: {
        paddingRight: '0%',
        marginLeft: '5px',
    },

    badgeStyle: {
        color: 'white',
        backgroundColor: green[500],
    },

    // acceptButton: {
    //     position: "absolute",
    //     right: "108px",
    // },

    acceptButton: {
        position: 'absolute',
        right: '1%',
    },

    ticketQueueColor: {
        flex: 'none',
        width: '8px',
        height: '100%',
        position: 'absolute',
        top: '0%',
        left: '0%',
    },

    ticketInfo: {
        position: 'relative',
        top: -13,
    },
    secondaryContentSecond: {
        display: 'flex',
        // marginTop: 2,
        //marginLeft: "5px",
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'flex-start',
    },
    ticketInfo1: {
        position: 'relative',
        top: -35,
        right: 45,
    },
    Radiusdot: {
        '& .MuiBadge-badge': {
            borderRadius: 2,
            position: 'inherit',
            height: 16,
            margin: 2,
            padding: 3,
        },
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            transform: 'scale(1) translate(0%, -40%)',
        },
    },
    connectionIcon: {
        marginRight: theme.spacing(1),
    },
    lastReadMessageContainer: {
        position: 'absolute',
        gap: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        right: -20,
        bottom: 25,
    },
}));

const TicketListItemCustom = ({ handleChangeTab, ticket }) => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [ticketUser, setTicketUser] = useState(null);
    const [tag, setTag] = useState([]);
    const [contactTicket, setContactTicket] = useState({});
    const [whatsAppName, setWhatsAppName] = useState(null);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(null);
    const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);
    const { ticketId } = useParams();
    const isMounted = useRef(true);
    const { setCurrentTicket } = useContext(TicketsContext);
    const { user } = useContext(AuthContext);
    const { profile, profileState } = user;
    const [userCompletionMessage, setUserCompletionMessage] = useState(null);
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const [modalUserCompletionMessage, setModalUserCompletionMessage] = useState(false);
    const [isConnected, setIsConnected] = useState(true);
    const [openConnectionModal, setOpenConnectionModal] = useState(false);

    useEffect(() => {
        if (ticket) {
            setIsConnected(isConnectedWhatsapp(ticket));
        }
    }, [ticket]);

    useEffect(() => {
        async function fetchData() {
            if (ticket.userId && ticket.user) {
                setTicketUser(ticket.user?.name.toUpperCase());
            }

            return () => {
                isMounted.current = false;
            };
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchTicket = async () => {
                try {
                    const { data } = await api.get('/tickets/' + ticket.id);

                    if (data.whatsappId && data.whatsapp) {
                        setWhatsAppName(data.whatsapp.name.toUpperCase());
                    }

                    setTag(data?.tags);
                } catch (err) { }
            };
            fetchTicket();
        }, 500);
        return () => {
            if (delayDebounceFn !== null) {
                clearTimeout(delayDebounceFn);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketId, user, history]);

    const handleCloseTicket = async (id) => {
        if (!userCompletionMessage || !userCompletionMessage == null || userCompletionMessage.length <= 3) {
            toastError('Informe o motivo do encerramento do atendimento.');
            return;
        }

        setLoading(true);
        try {
            await api.put(`/tickets/${id}`, {
                status: 'closed',
                userId: user?.id || null,
                userCompletionMessage,
            });
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
        if (isMounted.current) {
            setLoading(false);
        }
        history.push(`/tickets/`);
    };

    const handleCloseTransferTicketModal = async (success) => {
        if (isMounted.current) {
            if (!success) {
                setTransferTicketModalOpen(false);
                return;
            }

            await handleReopenTicket(ticket.id);
            setTransferTicketModalOpen(false);
            history.push(`/tickets`);
        }
    };

    const handleReopenTicket = async (id) => {
        setLoading(true);
        try {
            await api.put(`/tickets/${id}`, {
                status: 'open',
                userId: user?.id,
                queueId: ticket?.queue?.id,
                userCompletionMessage,
            });
        } catch (err) {
            setLoading(false);
            toastError(err);
        }

        if (isMounted.current) {
            setLoading(false);
        }
    };


    const handleAcceptTicketFilter = (t) => {
        if (t) {
            ticket = t;
        }
        setTimeout(() => {
            setNewTicketModalOpen(false);
        }, 500);
    };

    const handleAcepptTicket = async (id) => {
        try {
            const ticketWithoutQueue = localStorage.getItem("ticketWithoutQueue") === 'false';
            if ((!ticket?.queueId || ticket.queueId == null) && ticketWithoutQueue) {
                setContactTicket(ticket.contact);
                setNewTicketModalOpen(true);
                return;
            }
            setLoading(true);
            await api.put(`/tickets/${id}`, {
                status: 'open',
                userId: user?.id,
                userCompletionMessage,
            });

            let settingIndex;

            try {
                const { data } = await api.get('/settings/');
                settingIndex = data.filter((s) => s.key === 'sendGreetingAccepted');
            } catch (err) {
                toastError(err);
            }

            if (settingIndex[0]?.value === 'enabled' && !ticket.isGroup) {
                handleSendMessage(ticket.id);
            }
        } catch (err) {
            toastError(err);
        } finally {
            setLoading(false);
        }
        if (isMounted.current) {
            setLoading(false);
        }

        //handleChangeTab(null, "tickets");
        //handleChangeTab(null, "open");
        history.push(`/tickets/${ticket.uuid}`);
    };

    const handleSendMessage = async (id) => {
        const msg = `{{ms}} *{{name}}*, meu nome é *${user?.name}* e darei sequência ao seu atendimento.`;
        const message = {
            read: 1,
            fromMe: true,
            mediaUrl: '',
            body: `*Assistente Virtual:*\n${msg.trim()}`,
        };
        try {
            await api.post(`/messages/${id}`, message);
        } catch (err) {
            toastError(err);
        }
    };

    const handleSelectTicket = (ticket) => {
        const code = uuidv4();
        const { id, uuid } = ticket;
        setCurrentTicket({ id, uuid, code });
    };

    const renderTicketInfo = () => {
        if (ticketUser) {
            return (
                <>
                    {ticket.chatbot && (
                        <Tooltip title="Chatbot">
                            <AndroidIcon fontSize="small" style={{ color: grey[700], marginRight: 5 }} />
                        </Tooltip>
                    )}

                    {/* </span> */}
                </>
            );
        } else {
            return (
                <>
                    {ticket.chatbot && (
                        <Tooltip title="Chatbot">
                            <AndroidIcon fontSize="small" style={{ color: grey[700], marginRight: 5 }} />
                        </Tooltip>
                    )}
                </>
            );
        }
    };

    return (
        <React.Fragment key={ticket.id}>
            <NotConnectionModal
                ticketId={ticket.id}
                open={openConnectionModal}
                onClose={setOpenConnectionModal}
                onConfirm={console.info}
            />
            <TicketQueueModal
                ticketId={ticket.id}
                modalOpen={newTicketModalOpen}
                initialContact={contactTicket}
                onClose={(ticket) => {
                    handleAcceptTicketFilter(ticket);
                }}
            />

            <TicketMessagesDialog
                open={openTicketMessageDialog}
                handleClose={() => setOpenTicketMessageDialog(false)}
                ticketId={ticket.id}
            />

            <ModalUserCompletionMessage
                open={modalUserCompletionMessage}
                handleClose={setModalUserCompletionMessage}
                setMessage={setUserCompletionMessage}
                message={userCompletionMessage}
                onConfirm={() => {
                    setModalUserCompletionMessage(false);
                    handleCloseTicket(ticket.id);
                }}
            />

            <ListItem
                dense
                button
                onClick={(e) => {
                    if (!isConnected) { setOpenConnectionModal(true); return; };
                    if (ticket.status === 'pending') return;
                    handleSelectTicket(ticket);
                    e.stopPropagation();
                }}
                selected={ticketId && +ticketId === ticket.id}
                className={clsx(classes.ticket, {
                    [classes.pendingTicket]: ticket.status === 'pending',
                })}
            >
                <Tooltip arrow placement="right" title={ticket.queue?.name.toUpperCase() || 'Aguardando'}>
                    <span
                        style={{ backgroundColor: ticket.queue?.color || '#7C7C7C' }}
                        className={classes.ticketQueueColor}
                    ></span>
                </Tooltip>
                <ListItemAvatar>
                    {ticket.status !== 'pending' ? (
                        <Avatar
                            style={{
                                marginTop: '-20px',
                                marginLeft: '-3px',
                                width: '55px',
                                height: '55px',
                                borderRadius: '4px',
                            }}
                            src={ticket?.contact?.profilePicUrl}
                        />
                    ) : (
                        <Avatar
                            style={{
                                marginTop: '-28px',
                                marginLeft: '0px',
                                width: '50px',
                                height: '50px',
                                borderRadius: '4px',
                            }}
                            src={ticket?.contact?.profilePicUrl}
                        />
                    )}
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <span className={classes.contactNameWrapper}>
                            <Typography noWrap component="span" variant="body2" color="textPrimary">
                                {ticket.isGroup && (ticket.channel === 'whatsapp' || !ticket.channel) && (
                                    <GroupIcon
                                        fontSize="small"
                                        style={{ color: grey[700], marginBottom: '-5px', marginLeft: '5px' }}
                                    />
                                )}
                                {ticket.channel ? (
                                    <ConnectionIcon
                                        width="20"
                                        height="20"
                                        className={classes.connectionIcon}
                                        connectionType={ticket.channel}
                                    />
                                ) : (
                                    <ConnectionIcon
                                        width="20"
                                        height="20"
                                        className={classes.connectionIcon}
                                        connectionType="whatsapp"
                                    />
                                )}
                                <Typography noWrap component="span" variant="body2" color="textPrimary">
                                    <strong>{ticket?.contact?.name}</strong>
                                </Typography>
                            </Typography>
                            <ListItemSecondaryAction>
                                <Box className={classes.ticketInfo1}>{renderTicketInfo()}</Box>
                            </ListItemSecondaryAction>
                        </span>
                    }
                    secondary={
                        <span className={classes.contactNameWrapper}>
                            <Typography
                                className={classes.contactLastMessage}
                                noWrap
                                component="span"
                                variant="body2"
                                color="textSecondary"
                            // style={console.log('ticket.lastMessage', ticket.lastMessage)}
                            >
                                {/*<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>*/}
                                {/*<MarkdownWrapper>
                                    {ticket.lastMessage.includes("VCARD")
                                      ? "Novo contato recebido..."
                                      : ticket.lastMessage.includes("data:image")
                                      ? "Localização enviada..."
                                      : ticket.lastMessage}
                                </MarkdownWrapper>
                                */}
                                {/*
                                {ticket.lastMessage && (
                                  <>
                                    {ticket.lastMessage.includes("VCARD") ? (
                                      <MarkdownWrapper>Novo Contato recebido</MarkdownWrapper>
                                    ) : ticket.lastMessage.includes("data:image") ? (
                                      <MarkdownWrapper>Localização recebida</MarkdownWrapper>
                                    ) : (
                                      <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                                    )}
                                  </>
                                )}
                                */}
                                {ticket.lastMessage ? (
                                    <>
                                        {ticket.lastMessage.includes('VCARD') ? (
                                            <MarkdownWrapper>Novo Contato recebido</MarkdownWrapper>
                                        ) : ticket.lastMessage.includes('data:image') ? (
                                            <MarkdownWrapper>Localização recebida</MarkdownWrapper>
                                        ) : (
                                            <MarkdownWrapper>{ticket.lastMessage.slice(0, 20) + '...'}</MarkdownWrapper>
                                        )}
                                    </>
                                ) : (
                                    <MarkdownWrapper>Nenhuma mensagem</MarkdownWrapper>
                                )}
                                {/* {ticket.lastMessage ? (
                                    <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                                ) : (
                                    <br />
                                )} */}
                                <TicketBadge ticket={ticket} ticketUser={ticketUser} whatsAppName={whatsAppName} />
                                <span className={classes.secondaryContentSecond}>
                                    {tag?.map((tag) => {
                                        return (
                                            <ContactTag tag={tag} key={`ticket-contact-tag-${ticket.id}-${tag.id}`} />
                                        );
                                    })}
                                </span>
                            </Typography>

                            <Badge
                                className={classes.newMessagesCount}
                                badgeContent={ticket.unreadMessages}
                                classes={{
                                    badge: classes.badgeStyle,
                                }}
                            />
                        </span>
                    }
                />
                <span className={classes.secondaryContentSecond}>

                    {!isConnected && (
                        <Tooltip title="Whatsapp deste ticket desconectado, verifique a pagina de conexões">
                            <IconButton
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    borderRadius: '6px',
                                    rigth: '8px',
                                    fontSize: '0.8rem',
                                    bottom: '45px',
                                }}
                                variant="contained"
                                className={classes.acceptButton}
                                size="small"
                                loading={loading}
                                onClick={(e) => setOpenConnectionModal(true)}
                            >
                                <ErrorIcon />
                            </IconButton>
                        </Tooltip>

                    )}

                    {ticket.status === 'pending' && isConnected && (
                        <ButtonWithSpinner
                            //color="primary"
                            style={{
                                backgroundColor: 'green',
                                color: 'white',
                                borderRadius: '6px',
                                rigth: '8px',
                                fontSize: '0.8rem',
                                bottom: '45px',
                            }}
                            variant="contained"
                            className={classes.acceptButton}
                            size="small"
                            loading={loading}
                            onClick={(e) => handleAcepptTicket(ticket.id, handleChangeTab)}
                        >
                            {i18n.t('ticketsList.buttons.accept')}
                        </ButtonWithSpinner>
                    )}

                    {ticket.status !== 'closed' && isConnected &&
                        (ticket.userId === user.id ||
                            user.profile === 'admin' ||
                            profileState?.includes('isAllowedToCloseTickets')) && (
                            <ButtonWithSpinner
                                style={{
                                    backgroundColor: 'red',
                                    color: 'white',
                                    borderRadius: '6px',
                                    rigth: '8px',
                                    fontSize: '0.8rem',
                                    bottom: '5px',
                                }}
                                variant="contained"
                                className={classes.acceptButton}
                                size="small"
                                loading={loading}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setModalUserCompletionMessage(true);
                                }}
                            >
                                {i18n.t('ticketsList.buttons.closed')}
                            </ButtonWithSpinner>
                        )}
                    {ticket.status === 'closed' && isConnected && (
                        <ButtonWithSpinner
                            //color="primary"
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                borderRadius: '6px',
                                rigth: '8px',
                                fontSize: '0.8rem',
                                bottom: '5px',
                            }}
                            variant="contained"
                            className={classes.acceptButton}
                            size="small"
                            loading={loading}
                            onClick={() => setTransferTicketModalOpen(true)}
                        >
                            {i18n.t('ticketsList.buttons.reopen')}
                        </ButtonWithSpinner>
                    )}
                </span>
                <ListItemSecondaryAction>
                    <Box className={classes.lastReadMessageContainer}>
                        {(profile === 'admin' ||
                            profile === 'supervisor' ||
                            profileState?.includes('isAllowedToSpy')) && (
                                <Tooltip title="Espiar Conversa">
                                    <VisibilityIcon
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setOpenTicketMessageDialog(true);
                                        }}
                                        fontSize="small"
                                        style={{
                                            color: blue[700],
                                            cursor: 'pointer',
                                            marginLeft: 10,
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        {ticket.lastMessage ? (
                            <>
                                <Typography
                                    className={classes.lastMessageTime}
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                                        <>{format(parseISO(ticket.updatedAt), 'HH:mm')}</>
                                    ) : (
                                        <>{format(parseISO(ticket.updatedAt), 'dd/MM/yyyy')}</>
                                    )}
                                </Typography>
                                <br />
                            </>
                        ) : (
                            <>
                                <Typography
                                    className={classes.lastMessageTime}
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    Aguardando
                                </Typography>
                                <br />
                            </>
                        )}
                    </Box>
                </ListItemSecondaryAction>
            </ListItem>

            <TransferTicketModalCustom
                modalOpen={transferTicketModalOpen}
                onClose={handleCloseTransferTicketModal}
                ticketid={ticket.id}
                title={i18n.t('ticketsList.reopenTitle')}
                buttonText={i18n.t('ticketsList.buttons.reopen')}
            />

            <Divider variant="inset" component="li" />
        </React.Fragment>
    );
};

export default TicketListItemCustom;

export const ModalUserCompletionMessage = (props) => {
    const { open, handleClose, setMessage, message, onConfirm } = props;

    return (
        <Dialog open={open} onClose={() => handleClose(false)} fullWidth>
            <DialogTitle id="form-dialog-title">Encerramento de ticket</DialogTitle>
            <DialogContent dividers>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    multiline
                    minRows={4}
                    label="Informe o motivo do encerramento do atendimento"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => handleClose(false)} color="primary">
                    Cancelar
                </Button>
                <Button variant="contained" onClick={() => onConfirm()} color="primary">
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    );
};
