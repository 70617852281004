import { makeStyles } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    on: {
        color: green[600],
        fontSize: '20px',
    },
    off: {
        color: red[600],
        fontSize: '20px',
    },
    away: {
        color: yellow[600],
        fontSize: '20px',
    },
}));

const UserStatusIcon = ({ user }) => {
    const classes = useStyles();
    return user.online === 1 ? <CheckCircleIcon className={classes.on} /> : user.online === 2 ? <Brightness1Icon className={classes.away} /> : <ErrorIcon className={classes.off} />;
};

export default UserStatusIcon;
