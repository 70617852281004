import api from '../../services/api';

const useDocumentTemplate = () => {
    const list = async (params) => {
        const { data } = await api.request({
            url: `/documents/templates`,
            method: 'GET',
            params,
        });
        return data;
    };

    const findById = async (id) => {
        const { data } = await api.request({
            url: `/documents/templates/${id}`,
            method: 'GET',
        });
        return data;
    };

    const save = async (data) => {
        const { data: responseData } = await api.request({
            url: '/documents/templates',
            method: 'POST',
            data,
        });

        return responseData;
    };

    const update = async (data) => {
        const { data: responseData } = await api.request({
            url: `/documents/templates/${data.id}`,
            method: 'PUT',
            data,
        });

        return responseData;
    };

    const remove = async (id) => {
        await api.request({
            url: `/documents/templates/${id}`,
            method: 'DELETE',
        });
    };

    return {
        save,
        update,
        remove,
        list,
        findById,
    };
};

export default useDocumentTemplate;
