import { Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/ConfirmationModal';
import DocumentTemplateModal from '../../components/DocumentTemplateModal';
import IconButtonPopover from '../../components/IconButtonPopover';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import Title from '../../components/Title';
import toastError from '../../errors/toastError';
import useDocumentTemplate from '../../hooks/useDocumentTemplate';
import { i18n } from '../../translate/i18n';

export default function DocumentTemplatePage() {
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [openDocumentTemplateModal, setOpenDocumentTemplateModal] = useState(false);
    const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState();
    const { list, remove } = useDocumentTemplate();

    useEffect(() => {
        (async () => {
            setLoading(true);
            const data = await list();
            setTemplates(data);
            setLoading(false);
        })();
    }, []);

    const handleColseDocumentTemplateModal = async () => {
        setOpenDocumentTemplateModal(false);
        setTemplates(await list());
    };

    const handleOpenDocumentTemplateModal = (documentTemplate) => () => {
        setSelectedDocumentTemplate(documentTemplate);
        setOpenDocumentTemplateModal(true);
    };

    const handleEdit = (template) => () => {
        setSelectedDocumentTemplate(template);
        setOpenDocumentTemplateModal(true);
    };

    const handleDelete = async () => {
        if (!selectedDocumentTemplate) {
            return;
        }

        try {
            await remove(selectedDocumentTemplate.id);
            setTemplates(await list());
            toast.success(i18n.t('document.template.toasts.deleteSuccess'));
        } catch (err) {
            toastError(err, 4000);
        }
    };

    return (
        <MainContainer>
            {openDocumentTemplateModal && (
                <DocumentTemplateModal
                    open={openDocumentTemplateModal}
                    documentTemplateId={selectedDocumentTemplate?.id}
                    onClose={handleColseDocumentTemplateModal}
                />
            )}
            <ConfirmationModal
                title={i18n.t('document.template.confirmationModal.deleteTitle')}
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                onConfirm={handleDelete}
            >
                {i18n.t('document.template.confirmationModal.deleteMessage')}
            </ConfirmationModal>
            <MainHeader>
                <Title>
                    {i18n.t('document.template.list.title')} ({templates.length})
                </Title>
                <MainHeaderButtonsWrapper>
                    <Button variant="contained" color="primary" onClick={handleOpenDocumentTemplateModal()}>
                        {i18n.t('document.template.list.buttons.add')}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper variant="outlined">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t('document.template.list.table.name')}</TableCell>
                            <TableCell>{i18n.t('document.template.list.table.fileNamePrefix')}</TableCell>
                            <TableCell align="center" width="100px">
                                {i18n.t('document.template.list.table.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {templates.map((template) => (
                            <TableRow key={template.id}>
                                <TableCell>{template.name}</TableCell>
                                <TableCell>{template.fileNamePrefix}</TableCell>
                                <TableCell align="center" width="100px">
                                    <IconButtonPopover
                                        label="Editar template"
                                        size="small"
                                        onClick={handleEdit(template)}
                                    >
                                        <EditIcon />
                                    </IconButtonPopover>
                                    <IconButtonPopover
                                        label="Excluir template"
                                        size="small"
                                        onClick={() => {
                                            setConfirmationOpen(true);
                                            setSelectedDocumentTemplate(template);
                                        }}
                                    >
                                        <DeleteOutlineIcon />
                                    </IconButtonPopover>
                                </TableCell>
                            </TableRow>
                        ))}
                        {loading && <TableRowSkeleton columns={3} />}
                        {!loading && (!templates || templates.length === 0) && (
                            <TableRow>
                                <TableCell colSpan={3} align="center">
                                    {i18n.t('document.template.list.table.empty')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
}
