import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { i18n } from '../../translate/i18n';

const MatchedPatternsModal = ({ matchedPatterns, open, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth scroll="paper">
            <DialogTitle>{i18n.t('emailMatchedPatterns.modal.title')}</DialogTitle>
            <DialogContent>
                <Paper variant="outlined">
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell width="10%" align="center">
                                    {i18n.t('emailMatchedPatterns.modal.list.table.id')}
                                </TableCell>
                                <TableCell width="20%">
                                    {i18n.t('emailMatchedPatterns.modal.list.table.name')}
                                </TableCell>
                                <TableCell>{i18n.t('emailMatchedPatterns.modal.list.table.value')}</TableCell>
                                <TableCell width="15%" align="center">
                                    {i18n.t('emailMatchedPatterns.modal.list.table.attached')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {matchedPatterns?.length > 0 ? (
                                matchedPatterns
                                    .sort((a, b) => a.value.localeCompare(b.value))
                                    .map((matchedPattern) => (
                                        <TableRow key={matchedPattern.id}>
                                            <TableCell align="center">{matchedPattern.id}</TableCell>
                                            <TableCell>{matchedPattern.emailFilterPattern.name}</TableCell>
                                            <TableCell>{matchedPattern.value}</TableCell>
                                            <TableCell align="center">
                                                {matchedPattern.attached ? (
                                                    <CheckIcon style={{ color: green[500] }} />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4} align="center">
                                        {i18n.t('emailMatchedPatterns.modal.list.table.empty')}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </Paper>
            </DialogContent>
            <DialogActions style={{ paddingRight: 24, paddingBottom: 16 }}>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    {i18n.t('emailMatchedPatterns.modal.buttons.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default MatchedPatternsModal;
