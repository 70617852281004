import { Badge, makeStyles } from '@material-ui/core';
import HistoryIcon from '@material-ui/icons/History';
import React from 'react';

const useStyles = makeStyles(() => ({
    connectionTag: {
        background: 'green',
        color: '#FFF',
        marginRight: 1,
        padding: 1,
        fontWeight: 'bold',
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 3,
        fontSize: '0.8em',
        whiteSpace: 'nowrap',
    },
    secondaryContentSecond: {
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'flex-start',
    },
}));

const TicketBadge = ({ ticket, ticketUser, whatsAppName }) => {
    const classes = useStyles();
    const status = ticket.status;
    const queue = ticket.status === 'closed' && ticket.latestQueue ? ticket.latestQueue : ticket.queue;
    return (
        <span className={classes.secondaryContentSecond}>
            {whatsAppName ? <Badge className={classes.connectionTag}>{whatsAppName}</Badge> : <br></br>}
            {ticketUser ? (
                <Badge style={{ backgroundColor: '#000000' }} className={classes.connectionTag}>
                    {ticketUser}
                </Badge>
            ) : (
                <br></br>
            )}
            <Badge
                style={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    backgroundColor: queue?.color || '#7c7c7c',
                }}
                className={classes.connectionTag}
            >
                {status === 'closed' && <HistoryIcon fontSize="inherit" />}
                {queue?.name.toUpperCase() || 'SEM FILA'}
            </Badge>
        </span>
    );
};

export default TicketBadge;
