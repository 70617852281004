import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { green } from '@material-ui/core/colors';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import SyncIcon from '@material-ui/icons/Sync';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/ConfirmationModal';
import EmailFilterModal from '../../components/EmailFilterModal';
import EmailListModal from '../../components/EmailListModal';
import IconButtonPopover from '../../components/IconButtonPopover';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import Title from '../../components/Title';
import toastError from '../../errors/toastError';
import { useDate } from '../../hooks/useDate';
import useEmailFilter from '../../hooks/useEmailFilter';
import { i18n } from '../../translate/i18n';
import { CustomTableRow, TagsContainer } from './style';

const Tag = ({ label, value }) => (
    <Chip
        size="small"
        label={
            <>
                <strong>{label}</strong>: {value}
            </>
        }
    />
);

const EmailFilter = () => {
    const { datetimeToClientWithSeconds } = useDate();
    const { list, sync, remove, updateStatus } = useEmailFilter();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(null);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [openEmailModal, setOpenEmailModal] = useState(false);

    useEffect(() => {
        const fetchEmailFilters = async () => {
            try {
                setFilters(await list());
            } catch (err) {
                toastError(err);
            }
        };
        fetchEmailFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenFilterModal = (filter) => () => {
        setSelectedFilter(filter);
        setOpenFilterModal(true);
    };

    const handleCloseFilterModal = async () => {
        setOpenFilterModal(false);
        setSelectedFilter(undefined);
        try {
            setFilters(await list());
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenEmailModal = (filter) => () => {
        setSelectedFilter(filter);
        setOpenEmailModal(true);
    };

    const handleCloseEmailModal = () => {
        setOpenEmailModal(false);
        setSelectedFilter(undefined);
    };

    const handleOnDeleteClick = (filter) => () => {
        setSelectedFilter(filter);
        setConfirmationOpen(true);
    };

    const handleDelete = async () => {
        if (!selectedFilter) {
            return;
        }
        try {
            await remove(selectedFilter.id);
            setFilters(await list());
            toast.success(i18n.t('emailFilter.toasts.deleteSuccess'));
        } catch (err) {
            toastError(err, 4000);
        }
    };

    const handleInactivate = (filter) => () => {
        handleStatusUpdate(filter, 'INACTIVE');
    };

    const handleActivate = (filter) => () => {
        handleStatusUpdate(filter, 'ACTIVE');
    };

    const handleStatusUpdate = async (filter, status) => {
        try {
            await updateStatus(filter.id, status);
            setFilters(await list());
            toast.success(i18n.t('emailFilter.toasts.updateStatusSuccess'));
        } catch (err) {
            toastError(err, 4000);
        }
    };

    const Actions = ({ filter }) => {
        const [isSyncing, setIsSyncing] = useState(false);
        
        const handleSync = (filter) => async () => {
            if (filter.status === 'INACTIVE' || isSyncing) {
                return;
            }
            setIsSyncing(true);
            try {
                await sync(filter.id);
                setFilters(await list());
                toast.success(i18n.t('emailFilter.toasts.syncSuccess'));
            } catch (err) {
                toastError(err, 4000);
            }
            setIsSyncing(false);
        };

        return (
            <>
                {filter.status === 'ACTIVE' && (
                    <IconButtonPopover
                        size="small"
                        label={i18n.t('emailFilter.list.table.inactivate')}
                        onClick={handleInactivate(filter)}
                        disabled={isSyncing}
                    >
                        <NotInterestedIcon />
                    </IconButtonPopover>
                )}
                {filter.status === 'INACTIVE' && (
                    <IconButtonPopover
                        size="small"
                        label={i18n.t('emailFilter.list.table.activate')}
                        onClick={handleActivate(filter)}
                        disabled={isSyncing}
                    >
                        <PowerSettingsNewIcon style={{ color: green[500] }} />
                    </IconButtonPopover>
                )}
                <IconButtonPopover
                    size="small"
                    label={i18n.t('emailFilter.list.table.sync')}
                    onClick={handleSync(filter)}
                    disabled={filter.status === 'INACTIVE'}
                    loading={isSyncing}
                >
                    <SyncIcon />
                </IconButtonPopover>
                <IconButtonPopover
                    size="small"
                    label={i18n.t('emailFilter.list.table.viewEmails')}
                    onClick={handleOpenEmailModal(filter)}
                    disabled={isSyncing}
                >
                    <EmailIcon />
                </IconButtonPopover>
                <IconButtonPopover
                    size="small"
                    label={i18n.t('emailFilter.list.table.edit')}
                    onClick={handleOpenFilterModal(filter)}
                    disabled={isSyncing}
                >
                    <EditIcon />
                </IconButtonPopover>
                <IconButtonPopover
                    size="small"
                    label={i18n.t('emailFilter.list.table.delete')}
                    onClick={handleOnDeleteClick(filter)}
                    color="default"
                    disabled={isSyncing}
                >
                    <DeleteOutlineIcon />
                </IconButtonPopover>
            </>
        );
    };

    return (
        <MainContainer>
            {openFilterModal && (
                <EmailFilterModal open={true} onClose={handleCloseFilterModal} emailFilterId={selectedFilter?.id} />
            )}
            {openEmailModal && (
                <EmailListModal open={true} onClose={handleCloseEmailModal} emailFilterId={selectedFilter?.id} />
            )}
            <ConfirmationModal
                title={i18n.t('emailFilter.confirmationModal.deleteTitle')}
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                onConfirm={handleDelete}
            >
                {i18n.t('emailFilter.confirmationModal.deleteMessage')}
            </ConfirmationModal>
            <MainHeader>
                <Title>
                    {i18n.t('emailFilter.list.title')} ({filters.length})
                </Title>
                <MainHeaderButtonsWrapper>
                    <Button variant="contained" color="primary" onClick={handleOpenFilterModal()}>
                        {i18n.t('emailFilter.list.buttons.add')}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t('emailFilter.list.table.id')}</TableCell>
                            <TableCell>{i18n.t('emailFilter.list.table.name')}</TableCell>
                            <TableCell>{i18n.t('emailFilter.list.table.status')}</TableCell>
                            <TableCell>{i18n.t('emailFilter.list.table.emailSettings.header')}</TableCell>
                            <TableCell>{i18n.t('emailFilter.list.table.rules')}</TableCell>
                            <TableCell>{i18n.t('emailFilter.list.table.lastSyncAt')}</TableCell>
                            <TableCell align="right">{i18n.t('emailFilter.list.table.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filters.map((filter) => (
                            <CustomTableRow key={filter.id} status={filter.status}>
                                <TableCell align="center">{filter.id}</TableCell>
                                <TableCell>{filter.name}</TableCell>
                                <TableCell>{i18n.t(`emailFilter.list.status.${filter.status}`)}</TableCell>
                                <TableCell>{filter.emailSettings?.username}</TableCell>
                                <TableCell>
                                    <TagsContainer>
                                        {filter.rules.map((rule) => (
                                            <Tag
                                                key={rule.id}
                                                label={i18n.t(`emailFilter.list.rules.type.${rule.type}`)}
                                                value={rule.criteria}
                                            />
                                        ))}
                                    </TagsContainer>
                                </TableCell>
                                <TableCell>{datetimeToClientWithSeconds(filter.lastSyncAt)}</TableCell>
                                <TableCell align="right">
                                    <Actions filter={filter} />
                                </TableCell>
                            </CustomTableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default EmailFilter;
