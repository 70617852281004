import { grey } from '@material-ui/core/colors';
import styled from 'styled-components';

export const BodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 25px;
    > div > div > span {
        overflow-wrap: anywhere;
    }
    border-radius: 20px;
    border: 1px dashed ${grey[500]};
    background-color: ${grey[50]};
`;

export const MatchedPatternsTdContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
`;

export const MatchedPatternsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    div {
        width: fit-content;
    }
`;

export const LegendsContainer = styled.div`
    display: flex;
    gap: 20px;
    padding-left: 10px;
`;

export const LegendContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
`;
