import { TableRow } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import styled from 'styled-components';

export const TagsContainer = styled.div`
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
`;

export const CustomTableRow = styled(TableRow).attrs((props) => ({
    status: props.status,
}))`
    td {
        text-decoration-line: ${({ status }) => (status === 'ACTIVE' ? 'inherit' : 'line-through')};
        color: ${({ status }) => (status === 'ACTIVE' ? 'inherit' : grey[500])};
    }
`;
