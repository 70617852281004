import React from 'react';
import ButtonWithSpinner from '../../ButtonWithSpinner';

export const DownloadButton = ({ children, fileName, onClick, onComplete, loading, ...buttonProps }) => {
    return (
        <ButtonWithSpinner
            {...buttonProps}
            onClick={async () => {
                try {
                    const blob = await onClick();
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                } catch (ex) {
                    console.error(ex);
                }
                onComplete();
            }}
            loading={loading}
            disabled={loading || buttonProps.disabled}
        >
            {children}
        </ButtonWithSpinner>
    );
};
