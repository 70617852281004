import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { DialogActions } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FlexBoxColumn, FlexBoxRow } from '../(atoms)/FlexBox';
import RichTextEditor from '../(molecules)/RichTextEditor';
import toastError from '../../errors/toastError';
import useDocumentTemplate from '../../hooks/useDocumentTemplate';
import { i18n } from '../../translate/i18n';

const DocumentTemplateSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    fileNamePrefix: Yup.string().required('Campo obrigatório'),
    contentHtml: Yup.string().required('Campo obrigatório'),
});

const initialState = {
    name: '',
    fileNamePrefix: '',
    contentHtml: '',
};

const DocumentTemplateModal = ({ open, documentTemplateId, onClose }) => {
    const [documentTemplate, setDocumentTemplate] = useState(initialState);
    const { save, update, findById } = useDocumentTemplate();

    useEffect(() => {
        if (documentTemplateId) {
            (async () => {
                const { contentHtmlBase64, ...data } = await findById(documentTemplateId);
                setDocumentTemplate({
                    ...data,
                    contentHtml: Buffer.from(contentHtmlBase64, 'base64').toString('utf-8'),
                });
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentTemplateId]);

    const handleClose = () => {
        onClose();
        setDocumentTemplate(initialState);
    };

    const handleSave = async (values) => {
        try {
            if (values.id) {
                await update(values);
                toast.success(i18n.t('document.template.toasts.editSuccess'));
            } else {
                await save(values);
                toast.success(i18n.t('document.template.toasts.addSuccess'));
            }
            onClose();
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };

    const DocumentTemplateForm = ({ errors, touched, setFieldValue, isSubmitting, isValid }) => {
        return (
            <Form>
                <DialogContent>
                    <FlexBoxColumn gap="10px">
                        <FlexBoxRow gap="10px">
                            <Field
                                as={TextField}
                                label={i18n.t('document.template.modal.form.name')}
                                name={`name`}
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            />
                            <Field
                                as={TextField}
                                label={i18n.t('document.template.modal.form.fileNamePrefix')}
                                name={`fileNamePrefix`}
                                error={touched.fileNamePrefix && Boolean(errors.fileNamePrefix)}
                                helperText={touched.fileNamePrefix && errors.fileNamePrefix}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            />
                        </FlexBoxRow>
                        <RichTextEditor
                            onChange={(value) => setFieldValue('contentHtml', value)}
                            value={documentTemplate.contentHtml}
                        />
                    </FlexBoxColumn>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                        {i18n.t('emailFilter.modal.buttons.cancel')}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" disabled={!isValid || isSubmitting}>
                        {documentTemplateId
                            ? i18n.t('document.template.modal.buttons.okEdit')
                            : i18n.t('document.template.modal.buttons.okAdd')}
                    </Button>
                </DialogActions>
            </Form>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            PaperProps={{
                style: {
                    maxWidth: '1000px',
                    width: '100%'
                },
            }}
        >
            <DialogTitle>
                {documentTemplateId
                    ? i18n.t('document.template.modal.title.edit')
                    : i18n.t('document.template.modal.title.add')}
            </DialogTitle>
            <Formik
                initialValues={documentTemplate}
                enableReinitialize={true}
                validationSchema={DocumentTemplateSchema}
                validateOnMount
                validateOnBlur
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSave(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {(props) => <DocumentTemplateForm {...props} />}
            </Formik>
        </Dialog>
    );
};

export default React.memo(DocumentTemplateModal);
