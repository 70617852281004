import { grey } from '@material-ui/core/colors';
import styled from 'styled-components';

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
`;

export const FiltersContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    flex: 1;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    button {
        padding: 0;
    }
    > div > div > span {
        overflow-wrap: anywhere;
    }
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    border: 1px dashed ${grey[500]};
    padding: 25px;
`;

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
`;

export const SwitchContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;
