import { Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        // padding: theme.spacing(1),
        padding: theme.spacing(2),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
        '& th:first-child, & td:first-child': {
            width: '100px',
            textAlign: 'center',
        },
    },
    mainHeader: {
        paddingBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
    actions: {
        display: 'flex',
        gap: theme.spacing(1),
    },
}));

const ConnectionListContainer = ({ children, title, actionName, onAddNew, otherActions }) => {
    const classes = useStyles();
    return (
        <Paper className={classes.mainPaper} variant="outlined">
            <div className={classes.mainHeader}>
                <Typography variant="h6">{title}</Typography>
                <div className={classes.actions}>
                    {otherActions}
                    {onAddNew && (
                        <Button onClick={onAddNew} variant="contained" color="primary">
                            {actionName}
                        </Button>
                    )}
                </div>
            </div>
            {children}
        </Paper>
    );
};

export default ConnectionListContainer;
