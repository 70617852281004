/* eslint-disable no-unused-vars */

import React, { useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DescriptionIcon from '@material-ui/icons/Description';
import EditIcon from '@material-ui/icons/Edit';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';

import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import Title from '../../components/Title';

import { Grid } from '@material-ui/core';
import { isArray } from 'lodash';
import CampaignModal from '../../components/CampaignModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import toastError from '../../errors/toastError';
import { useDate } from '../../hooks/useDate';
import usePlans from '../../hooks/usePlans';
import socket from '../../hooks/useSocket';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';

const reducer = (state, action) => {
    if (action.type === 'LOAD_CAMPAIGNS') {
        const campaigns = action.payload;
        const newCampaigns = [];

        if (isArray(campaigns)) {
            campaigns.forEach((campaign) => {
                const campaignIndex = state.findIndex((u) => u.id === campaign.id);
                if (campaignIndex !== -1) {
                    state[campaignIndex] = campaign;
                } else {
                    newCampaigns.push(campaign);
                }
            });
        }

        return [...state, ...newCampaigns];
    }

    if (action.type === 'UPDATE_CAMPAIGNS') {
        const campaign = action.payload;
        const campaignIndex = state.findIndex((u) => u.id === campaign.id);

        if (campaignIndex !== -1) {
            state[campaignIndex] = campaign;
            return [...state];
        } else {
            return [campaign, ...state];
        }
    }

    if (action.type === 'DELETE_CAMPAIGN') {
        const campaignId = action.payload;

        const campaignIndex = state.findIndex((u) => u.id === campaignId);
        if (campaignIndex !== -1) {
            state.splice(campaignIndex, 1);
        }
        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        // padding: theme.spacing(1),
        padding: theme.padding,
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}));

const Campaigns = () => {
    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [deletingCampaign, setDeletingCampaign] = useState(null);
    const [campaignModalOpen, setCampaignModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [showCampaigns, setShowCampaigns] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [campaigns, dispatch] = useReducer(reducer, []);

    const { datetimeToClient } = useDate();
    const { getPlanCompany } = usePlans();

    useEffect(() => {
        async function fetchData() {
            const companyId = localStorage.getItem('companyId');
            const planConfigs = await getPlanCompany(undefined, companyId);
            if (!planConfigs.plan.useCampaigns) {
                toast.error('Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando.');
                setTimeout(() => {
                    history.push(`/`);
                }, 1000);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchCampaigns();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');

        socket.on(`company-${companyId}-campaign`, (data) => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_CAMPAIGNS', payload: data.record });
            }
            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_CAMPAIGN', payload: +data.id });
            }
        });
        return () => { };
    }, []);

    const fetchCampaigns = async () => {
        try {
            const { data } = await api.get('/campaigns/', {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: 'LOAD_CAMPAIGNS', payload: data.records });
            setHasMore(data.hasMore);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenCampaignModal = () => {
        setSelectedCampaign(null);
        setCampaignModalOpen(true);
    };

    const handleCloseCampaignModal = () => {
        setSelectedCampaign(null);
        setCampaignModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditCampaign = (campaign) => {
        setSelectedCampaign(campaign);
        setCampaignModalOpen(true);
    };

    const handleDeleteCampaign = async (campaignId) => {
        try {
            await api.delete(`/campaigns/${campaignId}`);
            toast.success(i18n.t('campaigns.toasts.deleted'));
        } catch (err) {
            toastError(err);
        }
        setDeletingCampaign(null);
        setSearchParam('');
        setPageNumber(1);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const formatStatus = (val) => {
        switch (val) {
            case 'INATIVA':
                return 'Inativa';
            case 'PROGRAMADA':
                return 'Programada';
            case 'EM_ANDAMENTO':
                return 'Em Andamento';
            case 'CANCELADA':
                return 'Cancelada';
            case 'FINALIZADA':
                return 'Finalizada';
            default:
                return val;
        }
    };

    const cancelCampaign = async (campaign) => {
        try {
            await api.post(`/campaigns/${campaign.id}/cancel`);
            toast.success(i18n.t('campaigns.toasts.cancel'));
            setPageNumber(1);
            fetchCampaigns();
        } catch (err) {
            toast.error(err.message);
        }
    };

    const restartCampaign = async (campaign) => {
        try {
            await api.post(`/campaigns/${campaign.id}/restart`);
            toast.success(i18n.t('campaigns.toasts.restart'));
            setPageNumber(1);
            fetchCampaigns();
        } catch (err) {
            toast.error(err.message);
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingCampaign && `${i18n.t('campaigns.confirmationModal.deleteTitle')} ${deletingCampaign.name}?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteCampaign(deletingCampaign.id)}
            >
                {i18n.t('campaigns.confirmationModal.deleteMessage')}
            </ConfirmationModal>
            <CampaignModal
                resetPagination={() => {
                    setPageNumber(1);
                    fetchCampaigns();
                }}
                open={campaignModalOpen}
                onClose={handleCloseCampaignModal}
                aria-labelledby="form-dialog-title"
                campaignId={selectedCampaign && selectedCampaign.id}
            />
            <MainHeader>
                <Grid style={{ width: '99.6%' }} container>
                    <Grid xs={12} sm={8} item>
                        <Title>{i18n.t('campaigns.title')}</Title>
                    </Grid>
                    <Grid xs={12} sm={4} item>
                        <Grid spacing={2} container>
                            <Grid xs={6} sm={6} item>
                                <TextField
                                    fullWidth
                                    placeholder={i18n.t('campaigns.searchPlaceholder')}
                                    type="search"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: 'gray' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid xs={6} sm={6} item>
                                <Button fullWidth variant="contained" onClick={handleOpenCampaignModal} color="primary">
                                    {i18n.t('campaigns.buttons.add')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t('campaigns.table.name')}</TableCell>
                            <TableCell align="center">{i18n.t('campaigns.table.status')}</TableCell>
                            <TableCell align="center">{i18n.t('campaigns.table.contactList')}</TableCell>
                            <TableCell align="center">{i18n.t('campaigns.table.whatsapp')}</TableCell>
                            <TableCell align="center">{i18n.t('campaigns.table.scheduledAt')}</TableCell>
                            <TableCell align="center">{i18n.t('campaigns.table.completedAt')}</TableCell>
                            <TableCell align="center">{i18n.t('campaigns.table.confirmation')}</TableCell>
                            <TableCell align="center">{i18n.t('campaigns.table.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {campaigns.map((campaign) => (
                                <TableRow key={campaign.id}>
                                    <TableCell align="center">{campaign.name}</TableCell>
                                    <TableCell align="center">{formatStatus(campaign.status)}</TableCell>
                                    <TableCell align="center">
                                        {campaign.contactListId ? campaign.contactList.name : 'Não definida'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.whatsappId ? campaign.whatsapp.name : 'Não definido'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.scheduledAt
                                            ? datetimeToClient(campaign.scheduledAt)
                                            : 'Sem agendamento'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.completedAt
                                            ? datetimeToClient(campaign.completedAt)
                                            : 'Não concluída'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.confirmation ? 'Habilitada' : 'Desabilitada'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {campaign.status === 'EM_ANDAMENTO' && (
                                            <IconButton
                                                onClick={() => cancelCampaign(campaign)}
                                                title="Parar Campanha"
                                                size="small"
                                            >
                                                <PauseCircleOutlineIcon />
                                            </IconButton>
                                        )}
                                        {campaign.status === 'CANCELADA' && (
                                            <IconButton
                                                onClick={() => restartCampaign(campaign)}
                                                title="Parar Campanha"
                                                size="small"
                                            >
                                                <PlayCircleOutlineIcon />
                                            </IconButton>
                                        )}
                                        <IconButton
                                            onClick={() => history.push(`/campaign/${campaign.id}/report`)}
                                            size="small"
                                        >
                                            <DescriptionIcon />
                                        </IconButton>
                                        <IconButton size="small" onClick={() => handleEditCampaign(campaign)}>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmModalOpen(true);
                                                setDeletingCampaign(campaign);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={8} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Campaigns;
