import Paper from '@material-ui/core/Paper';
import { styled } from '@material-ui/core/styles';

const TicketAdvancedLayout = styled(Paper)({
    height: `calc(100% - 48px)`,
    display: 'grid',
    gridTemplateRows: '56px 1fr',
});

export default TicketAdvancedLayout;
