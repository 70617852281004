import React, { useEffect, useState } from 'react';
import { Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { TicketsContextProvider } from '../context/Tickets/TicketsContext';
import { WhatsAppsProvider } from '../context/WhatsApp/WhatsAppsContext';
import LoggedInLayout from '../layout';
import Annoucements from '../pages/Annoucements';
import CampaignReport from '../pages/CampaignReport';
import Campaigns from '../pages/Campaigns';
import CampaignsConfig from '../pages/CampaignsConfig';
import Chat from '../pages/Chat';
import Companies from '../pages/Companies/';
import Connections from '../pages/Connections/';
import ContactListItems from '../pages/ContactListItems/';
import ContactLists from '../pages/ContactLists/';
import Contacts from '../pages/Contacts/';
import Dashboard from '../pages/Dashboard/';
import Financeiro from '../pages/Financeiro/';
import Helps from '../pages/Helps/';
import Login from '../pages/Login/';
import MessagesAPI from '../pages/MessagesAPI/';
import Queues from '../pages/Queues/';
import QuickMessages from '../pages/QuickMessages/';
import Schedules from '../pages/Schedules';
import SettingsCustom from '../pages/SettingsCustom/';
import Tags from '../pages/Tags/';
import TicketResponsiveContainer from '../pages/TicketResponsiveContainer';
import Users from '../pages/Users';
import Route from './Route';
// import Integrations from '../pages/Integrations';
import AIPrompts from '../pages/AIPrompts';
import DocumentTemplatePage from '../pages/DocumentTemplate';
import EmailContactFilter from '../pages/EmailContactFilter';
import EmailFilter from '../pages/EmailFilter';
import GoogleAuthSuccessPage from '../pages/GoogleAuth/success';
import Kanban from '../pages/Kanban';
import PasswordReset from '../pages/passwordReset/';
import Ratings from '../pages/Ratings/';
import TagsKanban from '../pages/TagsKanban/';

const Routes = () => {
    const [showCampaigns, setShowCampaigns] = useState(false);

    useEffect(() => {
        const cshow = localStorage.getItem('cshow');
        if (cshow !== undefined) {
            setShowCampaigns(true);
        }
    }, []);

    return (
        <TicketsContextProvider>
            <Switch>
                <Route exact path="/login" component={Login} />
                {/* <Route exact path="/signup" component={Signup} /> */}
                <Route exact path="/resetPass" component={PasswordReset} />
                <Route exact path="/financeiro-aberto" component={Financeiro} isPrivate />
                <WhatsAppsProvider>
                    <LoggedInLayout>
                        <Route exact path="/companies" component={Companies} isPrivate />
                        <Route exact path="/" component={Dashboard} isPrivate />
                        <Route exact path="/tickets/:ticketId?" component={TicketResponsiveContainer} isPrivate />
                        <Route exact path="/connections" component={Connections} isPrivate />
                        <Route exact path="/quick-messages" component={QuickMessages} isPrivate />
                        <Route exact path="/schedules" component={Schedules} isPrivate />
                        <Route exact path="/financeiro" component={Financeiro} isPrivate />
                        <Route exact path="/tags" component={Tags} isPrivate />
                        <Route exact path="/contacts" component={Contacts} isPrivate />
                        <Route exact path="/helps" component={Helps} isPrivate />
                        <Route exact path="/users" component={Users} isPrivate />
                        <Route exact path="/messages-api" component={MessagesAPI} isPrivate />
                        <Route exact path="/settings" component={SettingsCustom} isPrivate />
                        <Route exact path="/emails/contacts/filters" component={EmailContactFilter} isPrivate />
                        <Route exact path="/emails/filters" component={EmailFilter} isPrivate />
                        <Route exact path="/queues" component={Queues} isPrivate />
                        <Route exact path="/announcements" component={Annoucements} isPrivate />
                        <Route exact path="/chats/:id?" component={Chat} isPrivate />
                        <Route exact path="/ratings" component={Ratings} isPrivate />
                        <Route exact path="/ai/prompts" component={AIPrompts} isPrivate />
                        <Route exact path="/documents/templates" component={DocumentTemplatePage} isPrivate />

                        {/* <Route exact path="/integrations" component={Integrations} isPrivate /> */}
                        {/* <Route exact path="/integrations/hinova" component={MikWebComponent} isPrivate /> */}
                        {/* <Route exact path="/integrations/siprov" component={MikWebComponent} isPrivate /> */}
                        {/* <Route exact path="/integrations/mikweb" component={MikWebComponent} isPrivate /> */}
                        {/* <Route exact path="/integrations/asaas" component={MikWebComponent} isPrivate /> */}
                        {/* <Route exact path="/integrations/bling" component={MikWebComponent} isPrivate /> */}

                        <Route exact path="/google/auth/success" component={GoogleAuthSuccessPage} isPrivate />

                        {showCampaigns && (
                            <>
                                <Route exact path="/contact-lists" component={ContactLists} isPrivate />
                                <Route exact path="/kanban" component={Kanban} isPrivate />
                                <Route exact path="/TagsKanban" component={TagsKanban} isPrivate />
                                <Route
                                    exact
                                    path="/contact-lists/:contactListId/contacts"
                                    component={ContactListItems}
                                    isPrivate
                                />
                                <Route exact path="/campaigns" component={Campaigns} isPrivate />
                                <Route exact path="/campaign/:campaignId/report" component={CampaignReport} isPrivate />
                                <Route exact path="/campaigns-config" component={CampaignsConfig} isPrivate />
                            </>
                        )}
                    </LoggedInLayout>
                </WhatsAppsProvider>
            </Switch>
            <ToastContainer autoClose={3000} />
        </TicketsContextProvider>
    );
};

export default Routes;
