import React, { useEffect, useState } from 'react';
import toastError from '../../errors/toastError';
import api from '../../services/api';

import { Avatar, Box, Button, Divider, Typography } from '@material-ui/core';

import CreateVcardTicketModal from '../CreateVcardTicketModal';

const VcardPreview = ({ contact, number }) => {
    const [openModal, setOpenModal] = useState(false);
    const [selectedContact, setContact] = useState({
        id: 0,
        name: '',
        number: 0,
        profilePicUrl: '',
    });
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    let contactObj = {
                        name: contact,
                        number,
                        email: '',
                    };
                    const { data } = await api.post('/contacts/findOrCreate', contactObj);
                    setContact(data);
                } catch (err) {
                    console.log(err);
                    toastError(err);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [contact, number]);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <>
            <CreateVcardTicketModal
                contactId={selectedContact.id}
                openModal={openModal}
                onClose={handleCloseModal}
            />
            <div
                style={{
                    minWidth: '250px',
                    maxWidth: '350px',
                    marginTop: '8px',
                    marginBottom: '16px',
                    border: '1px solid gray',
                    borderRadius: '4px',
                }}
            >
                <Box>
                    <Box display="flex" padding={1} justifyContent="space-around" alignItems="center">
                        <Avatar src={selectedContact?.profilePicUrl} />
                        <Typography style={{ marginTop: '12px', marginLeft: '10px', fontWeight: 'bolder' }}>
                            {selectedContact?.name}
                        </Typography>
                    </Box>
                    <Box display="flex" padding={1} justifyContent="space-between" alignItems="center">
                        <Divider />
                        <Button
                            fullWidth
                            color="primary"
                            variant="outlined"
                            onClick={handleOpenModal}
                            disabled={!selectedContact?.number}
                        >
                            Conversar
                        </Button>
                    </Box>
                </Box>
            </div>
        </>
    );
};

export default VcardPreview;
