import React from "react";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center"
    },
    border: {
        borderBottom: "2px solid lightgray",
        width: "100%"
    },
    content: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    }
}));

const DividerChip = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div className={classes.border} />
            <span className={classes.content}>
                <Chip
                    style={{
                        borderRadius: '5px',
                        height: '25px'
                    }}
                    label={children} />
            </span>
            <div className={classes.border} />
        </div>
    );
};
export default DividerChip;