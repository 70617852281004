import React, { useContext, useState, useCallback } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ButtonWithSpinner from '../ButtonWithSpinner';

const QueueSelect = React.memo(({ queues, selectedQueue, onChange }) => (
    <FormControl variant="outlined" fullWidth>
        <InputLabel>Filas</InputLabel>
        <Select
            value={selectedQueue}
            onChange={onChange}
            label='Filas'
        >
            {queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>
                    {queue.name}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
));

const CreateVcardTicketModal = ({ openModal, onClose, contactId }) => {
    const history = useHistory();
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState('');

    const closeDialog = (success = false) => {
        setLoading(false);
        onClose(success);
    };

    const handleQueueChange = (e) => {
        setSelectedQueue(e.target.value);
    };

    const createTicket = async () => {
        setLoading(true);
        try {
            const { data: ticket } = await api.post('/tickets', {
                contactId,
                userId: user.id,
                status: 'open',
                companyId: user.companyId,
            });
            history.push(`/tickets/${ticket.uuid}`);
            closeDialog(true);
        } catch (err) {
            toastError(err);
            closeDialog(false);
        }
    };

    return (
        <Dialog
            open={openModal}
            onClose={() => closeDialog(false)}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: {
                    maxWidth: '300px',
                    borderRadius: '8px',
                    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
                },
            }}
        >
            <form onSubmit={(e) => e.preventDefault()}>
                <DialogTitle>Selecione uma fila</DialogTitle>
                <DialogContent dividers>
                    <QueueSelect
                        queues={user.queues}
                        selectedQueue={selectedQueue}
                        onChange={handleQueueChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => closeDialog(false)}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t('transferTicketModal.buttons.cancel')}
                    </Button>
                    <ButtonWithSpinner
                        variant="contained"
                        color="primary"
                        onClick={createTicket}
                        loading={loading}
                        disabled={!selectedQueue || loading}
                    >
                        Iniciar chamado
                    </ButtonWithSpinner>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.memo(CreateVcardTicketModal);
