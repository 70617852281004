import React from 'react';

import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';

import { i18n } from '@App/translate/i18n';
import TableRowSkeleton from '@Components/TableRowSkeleton';
import { useDate } from '@Hooks/useDate';

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
        maxHeight: '40dvh',
    },
    tableRoot: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        width: '100%',
        padding: '20px',
    },
    tableContainer: {
        maxHeight: '35dvh',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
}));

const Schedules = ({ schedules, title, onDelete, isFetching, showName = false }) => {
    const classes = useStyles();
    const { datetimeToClient } = useDate();

    const handleDeleteClick = (schedule) => {
        onDelete(schedule);
    };

    return (
        <Paper className={classes.tableRoot} elevation={3}>
            <Typography variant="h6">
                {title} ({schedules.length})
            </Typography>
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table} stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                {i18n.t('emailContact.listSchedules.modal.table.datetime')}
                            </TableCell>
                            {showName && (
                                <TableCell>{i18n.t('emailContact.listSchedules.modal.table.contactName')}</TableCell>
                            )}
                            <TableCell>{i18n.t('emailContact.listSchedules.modal.table.title')}</TableCell>
                            <TableCell>{i18n.t('emailContact.listSchedules.modal.table.description')}</TableCell>
                            <TableCell>{i18n.t('emailContact.listSchedules.modal.table.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isFetching && <TableRowSkeleton colSpan={4} />}
                        {!isFetching &&
                            schedules.map((schedule) => (
                                <TableRow key={schedule.id}>
                                    <TableCell align="center" width={100}>
                                        {datetimeToClient(schedule.scheduledAt)}
                                    </TableCell>
                                    {showName && (
                                        <TableCell width={200}>{schedule.emailContact.contactFilter.contact.name || '-'}</TableCell>
                                    )}
                                    <TableCell width={400}>{schedule.eventTitle}</TableCell>
                                    <TableCell>{schedule.eventDescription}</TableCell>
                                    <TableCell width={100}>
                                        {/* <IconButton size="small" onClick={() => handleOpenModal(schedule)}>
                                            <Edit />
                                        </IconButton> */}
                                        <IconButton size="small" onClick={() => handleDeleteClick(schedule)}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {!isFetching && schedules.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={showName ? 5 : 4} align="center">
                                    {i18n.t('emailContact.listSchedules.modal.table.noSchedules')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default Schedules;
