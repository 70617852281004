import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    InputAdornment,
    Switch,
    TextField,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import toastError from '../../errors/toastError';
import useEmailSettings from '../../hooks/useEmailSettings';
import { i18n } from '../../translate/i18n';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const SessionSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
    host: Yup.string().required('Required'),
    port: Yup.number().required('Required'),
    tls: Yup.boolean().required('Required'),
});

const initialState = {
    username: '',
    password: '',
    host: '',
    port: 993,
    tls: false,
};

const EmailSettingsModal = ({ open, onClose, emailSettingsId }) => {
    const classes = useStyles();
    const { save, update, connectionValidation, findById } = useEmailSettings();
    const [emailSettings, setEmailSettings] = useState(initialState);

    useEffect(() => {
        if (emailSettingsId) {
            const fetchEmailSettings = async () => {
                const data = await findById(emailSettingsId);
                setEmailSettings(data);
            };
            fetchEmailSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailSettingsId]);

    const handleSaveEmailSettings = async (emailSettings) => {
        try {
            if (emailSettingsId) {
                await update({ ...emailSettings, id: emailSettingsId });
            } else {
                await save(emailSettings);
            }

            toast.success(i18n.t('emailSettingsModal.success'));
            handleClose();
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };

    const handleClose = () => {
        onClose();
        setEmailSettings(initialState);
    };

    const EmailSettingsForm = ({ values, isValid, setFieldValue, touched, errors, isSubmitting }) => {
        const [tested, setTested] = useState(false);
        const [connecationValid, setConnectionValid] = useState(false);
        const [testing, setTesting] = useState(false);

        const handleConnectionValidation = async () => {
            setTested(true);
            setTesting(true);
            try {
                await connectionValidation(values);
                toast.success(i18n.t('emailSettingsModal.connectionSuccess'));
                setConnectionValid(true);
            } catch (err) {
                setConnectionValid(false);
                toastError(err);
            }
            setTesting(false);
        };

        useEffect(() => {
            setConnectionValid(false);
            setTested(false);
        }, [values]);

        const ValidationIcon = useCallback(() => {
            if (!tested) {
                return <></>;
            }

            if (testing) {
                return (
                    <InputAdornment position="end">
                        <CircularProgress size={24} />
                    </InputAdornment>
                );
            }

            if (connecationValid) {
                return (
                    <InputAdornment position="end">
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    </InputAdornment>
                );
            }

            return (
                <InputAdornment position="end">
                    <HighlightOffIcon color="error" />
                </InputAdornment>
            );
        }, [connecationValid, tested, testing]);

        return (
            <Form>
                <DialogContent dividers>
                    <div className={classes.multFieldLine}>
                        <Grid spacing={2} container>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    label={i18n.t('emailSettingsModal.form.username')}
                                    autoFocus
                                    name="username"
                                    error={touched.username && Boolean(errors.username)}
                                    helperText={touched.username && errors.username}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <ValidationIcon />,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    label={i18n.t('emailSettingsModal.form.password')}
                                    name="password"
                                    error={touched.password && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <ValidationIcon />,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    label={i18n.t('emailSettingsModal.form.host')}
                                    name="host"
                                    error={touched.host && Boolean(errors.host)}
                                    helperText={touched.host && errors.host}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <ValidationIcon />,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    as={TextField}
                                    label={i18n.t('emailSettingsModal.form.port')}
                                    name="port"
                                    error={touched.port && Boolean(errors.port)}
                                    helperText={touched.port && errors.port}
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.textField}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <ValidationIcon />,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    style={{ marginRight: 7, color: 'gray' }}
                                    label={i18n.t('emailSettingsModal.form.tls')}
                                    labelPlacement="end"
                                    control={
                                        <Switch
                                            size="small"
                                            checked={values.tls}
                                            onChange={(e) => setFieldValue('tls', e.target.checked)}
                                            name="tls"
                                            color="primary"
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="default"
                        onClick={handleConnectionValidation}
                        disabled={testing || !isValid || isSubmitting}
                        variant="outlined"
                    >
                        {i18n.t(
                            testing
                                ? 'emailSettingsModal.buttons.connectionValidating'
                                : 'emailSettingsModal.buttons.connectionValidation'
                        )}
                    </Button>
                    <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                        {i18n.t('emailSettingsModal.buttons.cancel')}
                    </Button>
                    <Button
                        type="submit"
                        color="primary"
                        disabled={!isValid || !connecationValid || isSubmitting}
                        variant="contained"
                        className={classes.btnWrapper}
                    >
                        {emailSettingsId
                            ? i18n.t('emailSettingsModal.buttons.okEdit')
                            : i18n.t('emailSettingsModal.buttons.okAdd')}
                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                </DialogActions>
            </Form>
        );
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
                <DialogTitle>
                    {emailSettingsId ? i18n.t('emailSettingsModal.title.edit') : i18n.t('emailSettingsModal.title.add')}
                </DialogTitle>
                <Formik
                    initialValues={emailSettings}
                    enableReinitialize={true}
                    validationSchema={SessionSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveEmailSettings(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {(props) => <EmailSettingsForm {...props} />}
                </Formik>
            </Dialog>
        </div>
    );
};

export default React.memo(EmailSettingsModal);
