import React, { useEffect, useState } from 'react';

import {
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import RemoveIcon from '@material-ui/icons/Remove';

import toastError from '@App/errors/toastError';
import { useDate } from '@App/hooks/useDate';
import useEmailContact from '@App/hooks/useEmailContact';
import { i18n } from '@App/translate/i18n';
import EmailContactItemContainer from '@Components/EmailContactModals/(components)/EmailContactContainer';
import EmailContactValue from '@Components/EmailContactModals/(components)/EmailContactValue';
import EmailContactAddCommentModal from '@Components/EmailContactModals/AddComment';
import { Container, FiltersContainer, TitleContainer } from '@Components/EmailContactModals/EmailContact/style';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: '30%',
    },
}));

const CollapseButton = ({ open, onToggle }) => (
    <IconButton size="small" onClick={onToggle} aria-label="expand row">
        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </IconButton>
);

const CollapsibleTableRow = ({ open, onToggle, children }) => {
    const theme = useTheme();
    const openColor = theme.mode === 'light' ? grey[100] : grey[700];

    return (
        <TableRow
            style={{
                '& > td, th': { borderBottom: 'unset' },
                backgroundColor: open ? openColor : 'initial',
            }}
        >
            <TableCell>
                <CollapseButton onToggle={onToggle} open={open} />
            </TableCell>
            {children}
        </TableRow>
    );
};

const Row = ({ emailContact, open, toggleOpen, onAddCommentClick }) => {
    const { datetimeToClient } = useDate();
    const onToggle = () => {
        toggleOpen(emailContact.id);
    };

    return (
        <>
            <CollapsibleTableRow onToggle={onToggle} open={open}>
                <TableCell align="center">{emailContact.id}</TableCell>
                <TableCell>{emailContact.email.sender}</TableCell>
                <TableCell>{datetimeToClient(emailContact.email.messageDate)}</TableCell>
                <TableCell>{emailContact.contactFilter.value}</TableCell>
                <TableCell align="center">
                    {!!emailContact.comment ? (
                        <CheckIcon style={{ color: green[500] }} />
                    ) : (
                        <RemoveIcon color="disabled" />
                    )}
                </TableCell>
                <TableCell align="center">
                    {!!emailContact.comment ? (
                        <CheckIcon style={{ color: green[500] }} />
                    ) : !emailContact.comment ? (
                        <RemoveIcon color="disabled" />
                    ) : (
                        <CloseIcon color="error" />
                    )}
                </TableCell>
                <TableCell align="center">
                    {emailContact.sentToContactAt ? datetimeToClient(emailContact.sentToContactAt) : '-'}
                </TableCell>
                <TableCell align="center">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!!emailContact.comment}
                        size="small"
                        onClick={() => onAddCommentClick(emailContact)}
                    >
                        {i18n.t('emailContact.list.table.email.actions.addComment')}
                    </Button>
                </TableCell>
            </CollapsibleTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open && !!emailContact} timeout="auto">
                        <Container>
                            {emailContact.comment && (
                                <EmailContactItemContainer
                                    variant="primary"
                                    title="Comentário"
                                    item={emailContact.comment}
                                />
                            )}
                            {[...(emailContact.items || [])]
                                .sort((a, b) => a.id - b.id)
                                .map((item, index) => (
                                    <EmailContactItemContainer
                                        key={item.id}
                                        title={`Citação ${index + 1}`}
                                        item={<EmailContactValue value={item.value} emailContact={emailContact} />}
                                    />
                                ))}
                        </Container>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const EmailContactModal = ({ open, onClose, contact }) => {
    const classes = useStyles();
    const { list } = useEmailContact();
    const [seletctedFilterId, setSelectedFilterId] = useState(0);
    const [onlyUncommented, setOnlyUncommented] = useState(false);
    const [openContactId, setOpenContactId] = useState(0);
    const [emailContacts, setEmailContacts] = useState([]);
    const [openAddCommentModal, setOpenAddCommentModel] = useState(false);
    const [selectedEmailContact, setSelectedEmailContact] = useState(null);
    const [filteredEmailContacts, setFilteredEmailContacts] = useState([]);

    const fetchEmailContacts = async () => {
        try {
            setEmailContacts(await list({ contactId: contact.id }));
        } catch (err) {
            toastError(err);
        }
    };

    useEffect(() => {
        if (!contact) return;
        fetchEmailContacts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contact]);

    useEffect(() => {
        setFilteredEmailContacts(
            emailContacts
                .filter((ec) => seletctedFilterId === 0 || ec.contactFilter.id === seletctedFilterId)
                .filter((ec) => !onlyUncommented || !ec.comment)
        );
    }, [emailContacts, seletctedFilterId, onlyUncommented]);

    const handleFilterChange = (event) => {
        setSelectedFilterId(event.target.value);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth scroll="paper">
                <DialogTitle>
                    <TitleContainer>
                        <Typography variant="h5">
                            {contact?.name} ({emailContacts.length})
                        </Typography>
                        <FiltersContainer>
                            <FormControlLabel
                                control={<Switch color="primary" name="publicToContact" checked={onlyUncommented} />}
                                onChange={(e) => setOnlyUncommented(e.target.checked)}
                                labelPlacement="start"
                                label={i18n.t('emailContact.list.modal.onlyUncommented')}
                            />
                            {contact?.emailContactFilters && contact.emailContactFilters.length > 1 && (
                                <FormControl className={classes.formControl} margin="dense" variant="outlined">
                                    <InputLabel id="filter-select-label">
                                        {i18n.t('emailContact.list.modal.filters')}
                                    </InputLabel>
                                    <Select
                                        labelId="filter-select-label"
                                        value={seletctedFilterId}
                                        label={i18n.t('emailContact.list.modal.filters')}
                                        margin="dense"
                                        variant="outlined"
                                        onChange={handleFilterChange}
                                    >
                                        <MenuItem value={0}>
                                            <em>Todos</em>
                                        </MenuItem>
                                        {contact.emailContactFilters.map((filter) => (
                                            <MenuItem key={filter.id} value={filter.id}>
                                                {filter.value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </FiltersContainer>
                    </TitleContainer>
                </DialogTitle>
                <DialogContent>
                    <Paper variant="outlined" style={{ height: '70dvh', overflowY: 'auto' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell align="center">{i18n.t('emailContact.list.table.id')}</TableCell>
                                    <TableCell>{i18n.t('emailContact.list.table.email.sender')}</TableCell>
                                    <TableCell>{i18n.t('emailContact.list.table.email.messageDate')}</TableCell>
                                    <TableCell>{i18n.t('emailContact.list.table.email.filter')}</TableCell>
                                    <TableCell align="center">
                                        {i18n.t('emailContact.list.table.email.commented')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t('emailContact.list.table.email.sentToContact')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t('emailContact.list.table.email.sentToContactAt')}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t('emailContact.list.table.email.actions.header')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredEmailContacts?.length > 0 ? (
                                    filteredEmailContacts
                                        .sort((a, b) =>
                                            moment(b.email.messageDate).isBefore(moment(a.email.messageDate)) ? -1 : 1
                                        )
                                        .map((emailContact) => (
                                            <Row
                                                key={emailContact.id}
                                                emailContact={emailContact}
                                                open={emailContact.id === openContactId}
                                                onAddCommentClick={(emailContact) => {
                                                    setSelectedEmailContact(emailContact);
                                                    setOpenAddCommentModel(true);
                                                }}
                                                toggleOpen={(contactId) =>
                                                    setOpenContactId(contactId === openContactId ? 0 : contactId)
                                                }
                                            />
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={9} align="center">
                                            {i18n.t('emailContact.list.table.empty')}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>
                </DialogContent>
                <DialogActions style={{ paddingRight: 24, paddingBottom: 16 }}>
                    <Button onClick={onClose} color="secondary" variant="outlined">
                        {i18n.t('emailContact.list.modal.buttons.close')}
                    </Button>
                </DialogActions>
            </Dialog>

            {openAddCommentModal && (
                <EmailContactAddCommentModal
                    open={openAddCommentModal}
                    onClose={() => {
                        setOpenAddCommentModel(false);
                        fetchEmailContacts();
                    }}
                    emailContact={selectedEmailContact}
                />
            )}
        </>
    );
};

export default EmailContactModal;
