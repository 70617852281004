import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        // padding: theme.spacing(1),
        padding: theme.padding,
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
        '& h6': {
            padding: theme.spacing(2),
        },
    },
    customTableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: 'center',
    },
    buttonProgress: {
        color: green[500],
    },
}));
