import { isString } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';

import {
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core';
import AssistantIcon from '@material-ui/icons/Assistant';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { AuthContext } from '@App/context/Auth/AuthContext';
import toastError from '@App/errors/toastError';
import { i18n } from '@App/translate/i18n';
import { FlexBoxRow } from '@Components/(atoms)/FlexBox';
import EmailContactItemContainer from '@Components/EmailContactModals/(components)/EmailContactContainer';
import EmailContactValue from '@Components/EmailContactModals/(components)/EmailContactValue';
import {
    CommentAreaFooter,
    Container,
    FormContainer,
    SuggestionDatesContainer,
    SwitchContainer,
    TitleContainer,
} from '@Components/EmailContactModals/AddComment/style';
import AddScheduleModal from '@Components/EmailContactModals/AddSchedule';
import ScheduleListModal from '@Components/EmailContactModals/ListSchedules';
import IconButtonPopover from '@Components/IconButtonPopover';
import useAI from '@Hooks/useAI';
import useEmailContact from '@Hooks/useEmailContact';
import useExternalCalendar from '@Hooks/useExternalCalendar';
import useQuickMessages from '@Hooks/useQuickMessages';

const EmailContactAddCommentSchema = Yup.object().shape({
    comment: Yup.string().required('Campo obrigatório'),
    sendToContact: Yup.boolean(),
    publicToContact: Yup.boolean(),
});

const initialValues = {
    quickMessage: '',
    comment: '',
    sendToContact: true,
    publicToContact: true,
};

const EmailContactAddCommentModal = ({ open, onClose, emailContact }) => {
    const { user } = useContext(AuthContext);
    const { addComment } = useEmailContact();
    const { getSuggestion } = useAI();
    const { list: listQuickMessages } = useQuickMessages();
    const { list: listExternalCalendars } = useExternalCalendar();

    const [quickMessages, setQuickMessages] = useState([]);
    const [externalCalendars, setExternalCalendars] = useState([]);
    const [suggestedEvents, setSuggestedEvents] = useState([]);
    const [selectedSuggestedEvent, setSelectedSuggestedEvent] = useState(null);

    const [suggestionIsLoading, setSuggestionIsLoading] = useState(false);
    const [openScheduleModal, setOpenScheduleModal] = useState(false);
    const [openScheduleListModal, setOpenScheduleListModal] = useState(false);

    const textareaRef = useRef(null);

    useEffect(() => {
        async function loadData() {
            const companyId = localStorage.getItem('companyId');
            const messages = await listQuickMessages({ companyId, userId: user.id, type: 'EMAIL_FILTER_COMMENT' });
            const options = messages.map((m) => {
                let truncatedMessage = m.message;
                if (isString(truncatedMessage) && truncatedMessage.length > 35) {
                    truncatedMessage = m.message.substring(0, 35) + '...';
                }
                return {
                    value: m.message,
                    label: `/${m.shortcode} - ${truncatedMessage}`,
                };
            });
            setQuickMessages(options);
            setExternalCalendars(await listExternalCalendars());
        }
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAddComment = async (values) => {
        try {
            await addComment({ emailContactId: emailContact.id, ...values });
            onClose();
        } catch (err) {
            toastError(err);
        }
    };

    const handleCloseScheduleModal = () => {
        setOpenScheduleModal(false);
        setSelectedSuggestedEvent(null);
    };

    const handleAddSuggestedEvent = (event) => {
        setSelectedSuggestedEvent(event);
        setOpenScheduleModal(true);
    };

    const AddCommentForm = ({ values, isValid, touched, errors, isSubmitting, setFieldValue }) => {
        useEffect(() => {
            if (values.quickMessage) {
                const comment = !!values.comment ? `${values.comment}\n${values.quickMessage}` : values.quickMessage;
                setFieldValue('comment', comment);
                setFieldValue('quickMessage', null);
                if (textareaRef.current) {
                    textareaRef.current.focus();
                }
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [values.quickMessage]);

        const handleAISuggestion = async () => {
            setSuggestionIsLoading(true);
            try {
                const { events, summary } = await getSuggestion({
                    type: 'EMAIL_FILTER_COMMENT',
                    target: 'JUD_PROCESS',
                    resourceId: emailContact.id,
                });

                setSuggestedEvents(
                    events
                        ?.filter((event) =>
                            moment(`${event.date} ${event.time}`, 'YYYY-MM-DD HH:mm:ss', true).isValid()
                        )
                        .map((event) => ({
                            ...event,
                            dateTime: moment(`${event.date} ${event.time}`, 'YYYY-MM-DD HH:mm:ss', true),
                        })) || []
                );
                setFieldValue('comment', `{{ms}} {{firstName}},\n\n${summary}`);
            } catch (err) {
                toastError(err);
            }
            setSuggestionIsLoading(false);
        };

        return (
            <Form
                style={{
                    width: '100%',
                }}
            >
                <DialogContent dividers>
                    <Grid spacing={4} container style={{ maxHeight: '75dvh' }}>
                        <Grid item xs={5} style={{ height: 'calc(75dvh - 32px)', overflowY: 'auto' }}>
                            <Container>
                                {[...(emailContact.items || [])]
                                    .sort((a, b) => a.id - b.id)
                                    .map((item, index) => (
                                        <EmailContactItemContainer
                                            key={item.id}
                                            title={`Citação ${index + 1}`}
                                            item={
                                                <EmailContactValue
                                                    value={item.value}
                                                    emailContact={emailContact}
                                                    expanded
                                                />
                                            }
                                        />
                                    ))}
                            </Container>
                        </Grid>
                        <Grid spacing={2} container xs={7} item style={{ height: 'calc(75dvh - 32px)' }}>
                            <FormContainer>
                                {quickMessages.length > 0 && (
                                    <FormControl variant="outlined" margin="dense" fullWidth>
                                        <InputLabel id="quickmessage-selection-label">
                                            {i18n.t('emailContact.addComment.modal.form.quickMessage')}
                                        </InputLabel>
                                        <Field
                                            as={Select}
                                            label={i18n.t('emailContact.addComment.modal.form.quickMessage')}
                                            placeholder={i18n.t('emailContact.addComment.modal.form.quickMessage')}
                                            labelId="quickmessage-selection-label"
                                            id="quickMessage"
                                            name="quickMessage"
                                        >
                                            <MenuItem value="">Selecione</MenuItem>
                                            {quickMessages.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                )}
                                <Field
                                    as={TextField}
                                    name="comment"
                                    inputRef={textareaRef}
                                    label={i18n.t('emailContact.addComment.modal.form.comment')}
                                    placeholder={i18n.t('emailContact.addComment.modal.form.comment')}
                                    multiline={true}
                                    error={touched.comment && Boolean(errors.comment)}
                                    helperText={touched.comment && errors.comment}
                                    variant="outlined"
                                    fullWidth
                                    style={{ flex: '1' }}
                                    InputProps={{
                                        style: {
                                            position: 'relative',
                                            alignItems: 'flex-start',
                                            height: '100%',
                                        },
                                        endAdornment: (
                                            <CommentAreaFooter>
                                                <SuggestionDatesContainer>
                                                    {suggestedEvents.length > 0 &&
                                                        suggestedEvents
                                                            // .filter((event) => moment(event.date).isAfter(moment()))
                                                            .map((event, index) => (
                                                                <Chip
                                                                    key={index}
                                                                    clickable
                                                                    size="small"
                                                                    color="secondary"
                                                                    label={`${event.dateTime.format('DD/MM/YYYY [às] HH:mm')} - ${event.title}`}
                                                                    onClick={() => handleAddSuggestedEvent(event)}
                                                                />
                                                            ))}
                                                </SuggestionDatesContainer>
                                                <IconButtonPopover
                                                    style={{
                                                        position: 'absolute',
                                                        bottom: 10,
                                                        right: 10,
                                                    }}
                                                    color="primary"
                                                    loading={suggestionIsLoading}
                                                    size="small"
                                                    onClick={handleAISuggestion}
                                                    label={i18n.t('emailContact.addComment.modal.form.aiSuggestion')}
                                                >
                                                    <AssistantIcon />
                                                </IconButtonPopover>
                                            </CommentAreaFooter>
                                        ),
                                    }}
                                />
                                <SwitchContainer>
                                    <FormControlLabel
                                        control={
                                            <Field
                                                as={Switch}
                                                color="primary"
                                                name="sendToContact"
                                                checked={values.sendToContact}
                                            />
                                        }
                                        label={i18n.t('emailContact.addComment.modal.form.sendToContact')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Field
                                                as={Switch}
                                                color="primary"
                                                name="publicToContact"
                                                checked={values.publicToContact}
                                            />
                                        }
                                        label={
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: i18n.t(
                                                        'emailContact.addComment.modal.form.publicToContact'
                                                    ),
                                                }}
                                            />
                                        }
                                    />
                                </SwitchContainer>
                            </FormContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" disabled={isSubmitting} variant="outlined">
                        {i18n.t('emailContact.addComment.modal.buttons.cancel')}
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting || !isValid} variant="contained">
                        {i18n.t('emailContact.addComment.modal.buttons.okAdd')}
                        {isSubmitting && <CircularProgress size={24} />}
                    </Button>
                </DialogActions>
            </Form>
        );
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth scroll="paper">
                <DialogTitle disableTypography>
                    <TitleContainer>
                        <Typography variant="h5">{i18n.t('emailContact.addComment.modal.title')}</Typography>

                        {externalCalendars && externalCalendars.length > 0 && (
                            <FlexBoxRow gap="10px">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setOpenScheduleListModal(true)}
                                >
                                    {i18n.t('emailContact.addComment.modal.buttons.listSchedules')}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setOpenScheduleModal(true)}
                                >
                                    {i18n.t('emailContact.addComment.modal.buttons.addSchedule')}
                                </Button>
                            </FlexBoxRow>
                        )}
                    </TitleContainer>
                </DialogTitle>
                <Formik
                    initialValues={initialValues}
                    validationSchema={EmailContactAddCommentSchema}
                    validateOnMount
                    validateOnBlur
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleAddComment(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {(props) => <AddCommentForm {...props} />}
                </Formik>
            </Dialog>
            {openScheduleModal && (
                <AddScheduleModal
                    open={true}
                    onClose={handleCloseScheduleModal}
                    emailContact={emailContact}
                    externalCalendars={externalCalendars}
                    suggestedEvent={selectedSuggestedEvent}
                />
            )}
            {openScheduleListModal && (
                <ScheduleListModal
                    open={true}
                    onClose={() => setOpenScheduleListModal(false)}
                    emailContact={emailContact}
                />
            )}
        </>
    );
};

export default EmailContactAddCommentModal;
