import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useEffect, useState } from 'react';
import toastError from '../../errors/toastError';
import { useDate } from '../../hooks/useDate';
import useEmailFilter from '../../hooks/useEmailFilter';
import { i18n } from '../../translate/i18n';
import IconButtonPopover from '../IconButtonPopover';
import TableRowSkeleton from '../TableRowSkeleton';
import EmailModal from './EmailModal';
import MatchedPatternsModal from './MatchedPatternModal';
import { MatchedPatternsTdContainer } from './style';

const useListStyles = makeStyles({
    container: {
        maxHeight: '70dvh',
    },
});

const EmailListModal = ({ open, onClose, emailFilterId }) => {
    const classes = useListStyles();
    const { datetimeToClient } = useDate();
    const { listEmails } = useEmailFilter();
    const [openMatchedPatternsModal, setOpenMatchedPatternsModal] = useState(false);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchEmails = async () => {
                if (!emailFilterId) return;
                try {
                    const data = await listEmails({ id: emailFilterId, pageNumber });
                    setEmails((prevState) => [...prevState, ...data.records]);
                    setHasMore(data.hasMore);
                } catch (err) {
                    toastError(err);
                }
                setLoading(false);
            };
            fetchEmails();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailFilterId, pageNumber]);

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleOpenEmailModal = (email) => () => {
        setSelectedEmail(email);
        setOpenEmailModal(true);
    };

    const handleCloseEmailModal = () => {
        setSelectedEmail(null);
        setOpenEmailModal(false);
    };

    const handleOpenMatchedPatternsModal = (email) => () => {
        console.log(email.id);
        setSelectedEmail(email);
        setOpenMatchedPatternsModal(true);
    };

    const handleCloseMatchedPatternsModal = () => {
        setSelectedEmail(null);
        setOpenMatchedPatternsModal(false);
    };

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth scroll="paper">
                <DialogTitle>{i18n.t('emailList.modal.title')}</DialogTitle>
                <DialogContent className={classes.mainPaper} onScroll={handleScroll}>
                    <Paper variant="outlined">
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">{i18n.t('emailList.modal.list.table.id')}</TableCell>
                                        <TableCell>{i18n.t('emailList.modal.list.table.sender')}</TableCell>
                                        <TableCell>{i18n.t('emailList.modal.list.table.subject')}</TableCell>
                                        <TableCell align="center">
                                            {i18n.t('emailList.modal.list.table.matchedPatternsCount')}
                                        </TableCell>
                                        <TableCell>{i18n.t('emailList.modal.list.table.messageDate')}</TableCell>
                                        <TableCell align="center">
                                            {i18n.t('emailList.modal.list.table.actions')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {emails?.length > 0
                                        ? emails
                                              .sort((a, b) => (a.messageDate > b.messageDate ? -1 : 1))
                                              .map((email) => (
                                                  <TableRow key={email.id}>
                                                      <TableCell align="center" component="th" scope="row">
                                                          {email.id}
                                                      </TableCell>
                                                      <TableCell>{email.sender}</TableCell>
                                                      <TableCell>{email.subject}</TableCell>
                                                      <TableCell align="center">
                                                          <MatchedPatternsTdContainer>
                                                              {email.matchedPatterns.length}/
                                                              {email.matchedPatterns.filter((mp) => mp.attached).length}
                                                          </MatchedPatternsTdContainer>
                                                      </TableCell>
                                                      <TableCell>{datetimeToClient(email.messageDate)}</TableCell>
                                                      <TableCell align="center">
                                                          <IconButtonPopover
                                                              label={i18n.t('emailList.modal.list.table.viewEmail')}
                                                              onClick={handleOpenEmailModal(email)}
                                                          >
                                                              <VisibilityIcon />
                                                          </IconButtonPopover>
                                                          <IconButtonPopover
                                                              label={i18n.t(
                                                                  'emailList.modal.list.table.viewMatchedPatterns'
                                                              )}
                                                              onClick={handleOpenMatchedPatternsModal(email)}
                                                          >
                                                              <PlaylistAddCheckIcon />
                                                          </IconButtonPopover>
                                                      </TableCell>
                                                  </TableRow>
                                              ))
                                        : !loading && (
                                              <TableRow>
                                                  <TableCell colSpan={6} align="center">
                                                      {i18n.t('emailList.modal.list.table.empty')}
                                                  </TableCell>
                                              </TableRow>
                                          )}
                                    {loading && <TableRowSkeleton columns={5} />}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </DialogContent>
                <DialogActions style={{ paddingRight: 24, paddingBottom: 16 }}>
                    <Button onClick={onClose} color="secondary" variant="outlined">
                        {i18n.t('emailList.modal.buttons.close')}
                    </Button>
                </DialogActions>
            </Dialog>
            {openEmailModal && <EmailModal open={true} onClose={handleCloseEmailModal} email={selectedEmail} />}
            {openMatchedPatternsModal && (
                <MatchedPatternsModal
                    open={true}
                    onClose={handleCloseMatchedPatternsModal}
                    matchedPatterns={selectedEmail.matchedPatterns}
                />
            )}
        </>
    );
};

export default EmailListModal;
