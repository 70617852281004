import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Visibility, VisibilityOff } from '@material-ui/icons';

import '../../assets/style.css';

import { i18n } from '../../translate/i18n';

import { AuthContext } from '../../context/Auth/AuthContext';

// import wave from '../../assets/wave.png'
import bg from '../../assets/bg.svg';
// import avatar from '../../assets/avatar.svg'
import logo from '../../assets/logo.png';
import SupportTokenModal from '../../components/SupportTokenModal';

const supportAccount = 'suporte@softlogsoftware.com.br';

const Login = () => {
    const [user, setUser] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [isSupport, setIsSupport] = useState(false);
    const [openTokenModal, setOpenTokenModal] = useState(false);

    const { handleLogin } = useContext(AuthContext);

    const handleChangeInput = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handlSubmit = (e) => {
        e.preventDefault();
        handleLogin(user);
    };

    useEffect(() => {
        if (user?.email && user.email === supportAccount) {
            setOpenTokenModal(true);
        };
    }, [user?.email])


    const handleLoginSupport = async (userData) => {
        setUser({ email: userData.email, password: userData.token });
        setTimeout(() => {
            handleLogin({ email: userData.email, password: userData.token });
        }, 500);
    }

    return (
        <>
            {/* <img className="wave" src={wave} /> */}
            <SupportTokenModal
                open={openTokenModal}
                onClose={() => {
                    setUser({ email: '', password: '' });
                    setOpenTokenModal(false);
                }}
                onConfirm={handleLoginSupport}
            />
            <div className="container">
                <div className="img">
                    <img src={bg} alt="" />
                </div>
                <div className="login-content" style={{ alignItems: 'center' }}>
                    <form noValidate onSubmit={handlSubmit} style={{ display: 'grid' }}>
                        {/* <img src={avatar} /> */}
                        <img alt="logo" style={{ maxWidth: 300, marginBottom: 20 }} src={logo}></img>
                        <TextField
                            variant="standard"
                            margin="normal"
                            color="warning"
                            required
                            fullWidth
                            id="email"
                            label={i18n.t('login.form.email')}
                            name="email"
                            value={user.email}
                            onChange={handleChangeInput}
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            color="success"
                            required
                            fullWidth
                            name="password"
                            label={i18n.t('login.form.password')}
                            id="password"
                            value={user.password}
                            onChange={handleChangeInput}
                            autoComplete="current-password"
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword((e) => !e)}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="#" variant="body2" component={RouterLink} to="/resetPass">
                                    {i18n.t('Recuperar Senha?')}
                                </Link>
                            </Grid>
                        </Grid>

                        <input type="submit" className="btn" value="Acessar" />
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
