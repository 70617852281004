import axios from 'axios';

const downloadFileToBlob = async (fileUrl) => {
    try {
        const response = await axios.get(fileUrl, { responseType: 'blob' });
        const blob = new Blob([response.data], { type: response.data.type });
        const fileName = fileUrl.split('/').pop();
        const file = new File([blob], fileName, { type: blob.type });
        return file;
    } catch (error) {
        console.error('There has been a problem with your download operation:', error);
        return null;
    }
};

export default downloadFileToBlob;
