import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Replay } from '@material-ui/icons';
import { Can } from '../Can';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { AuthContext } from '../../context/Auth/AuthContext';
import { TicketsContext } from '../../context/Tickets/TicketsContext';
import toastError from '../../errors/toastError';
import usePlans from '../../hooks/usePlans';
import useSettings from '../../hooks/useSettings';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import ButtonWithSpinner from '../ButtonWithSpinner';
import ConfirmationModal from '../ConfirmationModal';
import TransferTicketModalCustom from '../TransferTicketModalCustom';

//icones
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EventIcon from '@material-ui/icons/Event';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import UndoIcon from '@material-ui/icons/Undo';

import { Switch } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ScheduleModal from '../ScheduleModal';

const useStyles = makeStyles((theme) => ({
    actionButtons: {
        marginRight: 6,
        flex: 'none',
        alignSelf: 'center',
        marginLeft: 'auto',
        // flexBasis: "50%",
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    bottomButtonVisibilityIcon: {
        padding: 1,
    },
    botoes: {
        display: 'flex',
        padding: '15px',
        justifyContent: 'flex-end',
        // alignItems: "center"
    },
}));

const SessionSchema = Yup.object().shape({
    ratingId: Yup.string().required('Avaliação obrigatória'),
});

const TicketActionButtonsCustom = ({ ticket }) => {
    const classes = useStyles();
    const history = useHistory();
    const isMounted = useRef(true);
    const [loading, setLoading] = useState(false);
    const { user } = useContext(AuthContext);
    const { setCurrentTicket } = useContext(TicketsContext);
    const [initialState, setInitialState] = useState({ ratingId: '' });
    const [dataRating, setDataRating] = useState([]);
    const [open, setOpen] = React.useState(false);
    const formRef = React.useRef(null);
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
    const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
    const [contactId, setContactId] = useState(null);
    const [acceptAudioMessage, setAcceptAudio] = useState(ticket?.contact?.acceptAudioMessage || true);
    const [showSchedules, setShowSchedules] = useState(false);
    const [ratings, setRatings] = useState(false);

    const { getAll: getAllSettings } = useSettings();
    const { getPlanCompany } = usePlans();

    useEffect(() => {
        async function fetchData() {
            const settingList = await getAllSettings();
            const setting = settingList.find((setting) => setting.key === 'userRating');

            if (setting?.value === 'enabled') {
                setRatings(true);
            } else {
                setRatings(false);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function fetchData() {
            const companyId = localStorage.getItem('companyId');
            const planConfigs = await getPlanCompany(undefined, companyId);
            setShowSchedules(planConfigs.plan.useSchedules);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadRatings = async () => {
        try {
            const { data } = await api.get(`/ratings/list`);
            setDataRating(data);
        } catch (err) {
            toastError(err);
        }
    };

    const handleClickOpen = async () => {
        const settingList = await getAllSettings();
        const setting = settingList.find((setting) => setting.key === 'userRating');
        if (setting?.value === 'enabled') {
            setInitialState({
                ratingId: '',
            });
            await loadRatings();
            setOpen(true);
        } else {
            setOpen(true);
            // handleUpdateTicketStatus(e, "closed", user?.id);
        }
    };

    const handleClose = () => {
        formRef.current.resetForm();
        setOpen(false);
    };

    const handleOpenScheduleModal = () => {
        if (typeof handleClose == 'function') handleClose();
        setContactId(ticket.contact.id);
        setScheduleModalOpen(true);
    };

    const handleCloseScheduleModal = () => {
        setScheduleModalOpen(false);
        setContactId(null);
    };

    const handleOpenTransferModal = () => {
        setTransferTicketModalOpen(true);
        if (typeof handleClose == 'function') handleClose();
    };

    const handleOpenConfirmationModal = () => {
        setConfirmationOpen(true);
        if (typeof handleClose == 'function') handleClose();
    };

    const handleCloseTicketWithoutFarewellMsg = async () => {
        setLoading(true);
        try {
            await api.put(`/tickets/${ticket.id}`, {
                status: 'closed',
                userId: user?.id || null,
                sendFarewellMessage: false,
                amountUsedBotQueues: 0,
            });

            setLoading(false);
            history.push('/tickets');
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };

    const handleContactToggleAcceptAudio = async () => {
        try {
            const contact = await api.put(`/contacts/toggleAcceptAudio/${ticket.contact.id}`);
            setAcceptAudio(contact.data.acceptAudioMessage);
        } catch (err) {
            toastError(err);
        }
    };

    const handleCloseTransferTicketModal = () => {
        if (isMounted.current) {
            setTransferTicketModalOpen(false);
        }
        history.push('/tickets');
    };

    const handleDeleteTicket = async () => {
        try {
            await api.delete(`/tickets/${ticket.id}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleUpdateTicketStatus = async (e, status, userId) => {
        setLoading(true);
        try {
            await api.put(`/tickets/${ticket.id}`, {
                status: status,
                userId: userId || null,
                userCompletionMessage: '',
            });

            setLoading(false);
            if (status === 'open') {
                setCurrentTicket({ ...ticket, code: '#open' });
            } else {
                setCurrentTicket({ id: null, code: null });
                history.push('/tickets');
            }
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };

    const handleSendRating = async (userId, ratingId) => {
        handleClose();
        setLoading(true);
        try {
            await api.post(`/ratings/messages/${ticket.id}`, {
                userId: userId || null,
                ratingId,
            });

            setLoading(false);
            history.push('/tickets');
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };


    const handleClickedButton = () => {
        setTransferTicketModalOpen(true);
    };

    return (
        <>
            <TransferTicketModalCustom
                modalOpen={transferTicketModalOpen}
                onClose={handleCloseTransferTicketModal}
                ticketid={ticket.id}
                title={i18n.t('ticketsList.reopenTitle')}
                buttonText={i18n.t('ticketsList.buttons.reopen')}
            />

            <div className={classes.actionButtons}>
                {ticket.status === 'closed' && (
                    <ButtonWithSpinner
                        loading={loading}
                        startIcon={<Replay />}
                        size="small"
                        onClick={() => handleClickedButton()}
                    >
                        {i18n.t('messagesList.header.buttons.reopen')}
                    </ButtonWithSpinner>
                )}
                {ticket.status === 'open' && (
                    <>
                        <IconButton className={classes.bottomButtonVisibilityIcon}>
                            <Tooltip title={i18n.t('messagesList.header.buttons.resolve')}>
                                <HighlightOffIcon color="primary" onClick={handleClickOpen} />
                            </Tooltip>
                        </IconButton>

                        {ticket.userId === user?.id && (
                            <>
                                <IconButton className={classes.bottomButtonVisibilityIcon}>
                                    <Tooltip title="Devolver a Fila">
                                        <UndoIcon
                                            color="primary"
                                            onClick={(e) => handleUpdateTicketStatus(e, 'pending', null)}
                                        />
                                    </Tooltip>
                                </IconButton>

                                <IconButton className={classes.bottomButtonVisibilityIcon}>
                                    <Tooltip title="Transferir Ticket">
                                        <CachedOutlinedIcon color="primary" onClick={handleOpenTransferModal} />
                                    </Tooltip>
                                </IconButton>
                            </>
                        )}

                        {showSchedules && (
                            <>
                                <IconButton className={classes.bottomButtonVisibilityIcon}>
                                    <Tooltip title="Agendamento">
                                        <EventIcon color="primary" onClick={handleOpenScheduleModal} />
                                    </Tooltip>
                                </IconButton>
                            </>
                        )}

                        <MenuItem className={classes.bottomButtonVisibilityIcon}>
                            <Tooltip title={i18n.t('ticketOptionsMenu.acceptAudioMessage')}>
                                <Switch
                                    size="small"
                                    color="primary"
                                    checked={acceptAudioMessage}
                                    onChange={() => handleContactToggleAcceptAudio()}
                                />
                            </Tooltip>
                        </MenuItem>

                        <Can
                            role={user.profile}
                            perform="ticket-options:deleteTicket"
                            yes={() => (
                                <IconButton className={classes.bottomButtonVisibilityIcon}>
                                    <Tooltip title="Deletar Ticket">
                                        <DeleteOutlineIcon color="primary" onClick={handleOpenConfirmationModal} />
                                    </Tooltip>
                                </IconButton>
                            )}
                        />

                        <ConfirmationModal
                            title={`${i18n.t('ticketOptionsMenu.confirmationModal.title')} #${ticket.id}?`}
                            open={confirmationOpen}
                            onClose={setConfirmationOpen}
                            onConfirm={handleDeleteTicket}
                        >
                            {i18n.t('ticketOptionsMenu.confirmationModal.message')}
                        </ConfirmationModal>-

                        <ScheduleModal
                            open={scheduleModalOpen}
                            onClose={handleCloseScheduleModal}
                            aria-labelledby="form-dialog-title"
                            contactId={contactId}
                        />
                    </>
                )}
                {ticket.status === 'pending' && (
                    <ButtonWithSpinner
                        loading={loading}
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleUpdateTicketStatus(e, 'open', user?.id)}
                    >
                        {i18n.t('messagesList.header.buttons.accept')}
                    </ButtonWithSpinner>
                )}
            </div>
            <>
                <Formik
                    initialValues={initialState}
                    enableReinitialize={true}
                    validationSchema={SessionSchema}
                    innerRef={formRef}
                    onSubmit={(values, actions) => {
                        handleSendRating(user?.id, values.ratingId);
                        setTimeout(() => {
                            actions.setSubmitting(false);
                            actions.resetForm();
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, setFieldValue }) => (
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Form>
                                <DialogTitle id="alert-dialog-title">
                                    {ratings
                                        ? i18n.t('messagesList.header.dialogRatingTitle')
                                        : i18n.t('messagesList.header.dialogClosingTitle')}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        {ratings && (
                                            <div style={{ marginTop: 8 }}>
                                                <Autocomplete
                                                    size="small"
                                                    options={dataRating}
                                                    name="ratingId"
                                                    getOptionLabel={(option) => option.name}
                                                    onChange={(e, value) => setFieldValue('ratingId', value?.id || '')}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            error={touched.ratingId && Boolean(errors.ratingId)}
                                                            helperText={touched.ratingId && errors.ratingId}
                                                            variant="outlined"
                                                            placeholder={i18n.t('messagesList.header.ratingTitle')}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </DialogContentText>
                                </DialogContent>

                                <DialogActions className={classes.botoes}>
                                    <Button
                                        onClick={() => handleCloseTicketWithoutFarewellMsg()}
                                        style={{ background: '#9200bf', color: 'white' }}
                                    >
                                        {i18n.t('messagesList.header.dialogRatingWithoutFarewellMsg')}
                                    </Button>

                                    <Button
                                        onClick={(e) =>
                                            handleUpdateTicketStatus(e, 'closed', user?.id, ticket?.queue?.id)
                                        }
                                        style={{ background: '#9200bf', color: 'white' }}
                                    >
                                        {i18n.t('messagesList.header.dialogRatingCancel')}
                                    </Button>

                                    {ratings && (
                                        <Button
                                            disabled={isSubmitting}
                                            variant="contained"
                                            type="submit"
                                            style={{ background: '#9200bf', color: 'white' }}
                                        >
                                            {i18n.t('messagesList.header.dialogRatingSuccess')}
                                        </Button>
                                    )}
                                </DialogActions>
                            </Form>
                        </Dialog>
                    )}
                </Formik>
            </>

        </>
    );
};

export default TicketActionButtonsCustom;
