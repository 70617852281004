import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FlexBoxColumn } from '../(atoms)/FlexBox';
import { DownloadButton } from '../(molecules)/DownloadButton';
import useDocumentGenerator from '../../hooks/useDocumentGenerator';
import useDocumentTemplate from '../../hooks/useDocumentTemplate';
import { i18n } from '../../translate/i18n';

export default function DocumentGeneratorForm({ contact }) {
    const { generate } = useDocumentGenerator();
    const { list } = useDocumentTemplate();
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState();

    useEffect(() => {
        (async () => {
            const templates = await list();
            setTemplates(templates);
        })();
    }, []);

    const handleGenerate = async () => {
        setLoading(true);
        return await generate({ templateId: selectedTemplate.id, contactId: contact?.id });
    };

    return (
        <FlexBoxColumn>
            <Typography style={{ marginBottom: 8, marginTop: 12 }} variant="subtitle1">
                {i18n.t('document.generator.form.title')}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <FormControl variant="outlined" margin="dense" fullWidth disabled={loading}>
                        <InputLabel id="document-generator-template-selection-label">
                            {i18n.t('document.generator.form.template')}
                        </InputLabel>
                        <Select
                            label={i18n.t('document.generator.form.template')}
                            placeholder={i18n.t('document.generator.form.template')}
                            labelId="document-generator-template-selection-label"
                            variant="outlined"
                            margin="dense"
                            value={selectedTemplate?.id || ''}
                            onChange={(e) =>
                                setSelectedTemplate(templates.find((template) => template.id === e.target.value))
                            }
                            fullWidth
                        >
                            {templates.map((template) => (
                                <MenuItem key={template.id} value={template.id}>
                                    {template.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '10px' }}>
                    <DownloadButton
                        disabled={!selectedTemplate}
                        variant="contained"
                        color="primary"
                        onClick={handleGenerate}
                        onComplete={() => setLoading(false)}
                        fileName={`${selectedTemplate?.fileNamePrefix}-${contact?.name}`}
                        loading={loading}
                        style={{
                            width: '100%',
                        }}
                    >
                        {i18n.t('document.generator.form.buttons.generate')}
                    </DownloadButton>
                </Grid>
            </Grid>
        </FlexBoxColumn>
    );
}
