import api from '../../services/api';

const useExternalCalendar = () => {
    const list = async (params) => {
        const { data } = await api.request({
            url: '/external-calendars',
            method: 'GET',
            params,
        });
        return data;
    };

    const listCalendars = async (id) => {
        const { data } = await api.get(`/external-calendars/${id}/calendars`);
        return data;
    };

    const remove = async (id) => {
        await api.delete(`/external-calendars/${id}`);
    };

    const updateUsers = async (id, userIds) => {
        await api.put(`/external-calendars/${id}/users`, { userIds });
    };

    const updateCalendarId = async (id, calendarId) => {
        await api.patch(`/external-calendars/${id}/calendarId`, { calendarId });
    };

    return {
        list,
        listCalendars,
        remove,
        updateUsers,
        updateCalendarId,
    };
};

export default useExternalCalendar;
