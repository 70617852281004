import api from '../../services/api';

const useGoogle = () => {
    const getAuthUrl = async (type) => {
        const { data } = await api.request({
            url: `/google/auth`,
            method: 'GET',
            params: {
                type,
            },
        });
        return data.url;
    };

    return {
        getAuthUrl,
    };
};

export default useGoogle;
