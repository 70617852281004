import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import toastError from '../../errors/toastError';
import useEmailContactFilter from '../../hooks/useEmailContactFilter';
import useEmailFilter from '../../hooks/useEmailFilter';
import { i18n } from '../../translate/i18n';
import { ContactsFilter } from '../ContactsFilter';

const EmailContactSchema = Yup.object().shape({
    value: Yup.string().required('Campo obrigatório'),
    categoryId: Yup.string().required('Campo obrigatório'),
    contactId: Yup.string().required('Campo obrigatório'),
});

const initialState = {
    value: '',
    filterId: '-1',
    categoryId: '',
    contactId: '',
    verifyDaysAgo: '',
    verifyLastEmails: false,
};

const EmailContactFilterModal = ({ open, onClose, initialValues, emailContactFilterId }) => {
    const isMounted = useRef(true);
    const { list: listFilters } = useEmailFilter();
    const { find, listCategories, save } = useEmailContactFilter();
    const [filters, setFilters] = useState([]);
    const [categories, setCtegories] = useState([]);
    const [emailContactFilter, setEmailContactFilter] = useState(initialState);

    useEffect(() => {
        const fetchAll = async () => {
            try {
                setFilters(await listFilters());
                setCtegories(await listCategories());
            } catch (err) {
                console.error(err);
                toastError(err);
            }
        };
        fetchAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchEmailContactFilter = async () => {
            if (initialValues) {
                setEmailContactFilter((prevState) => ({ ...prevState, ...initialValues }));
            }

            if (!emailContactFilterId) return;

            try {
                const data = await find(emailContactFilterId);
                if (isMounted.current) {
                    setEmailContactFilter(data);
                }
            } catch (err) {
                toastError(err);
            }
        };

        fetchEmailContactFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailContactFilterId, open, initialValues]);

    const handleSaveFilter = async (values) => {
        try {
            await save({
                ...values,
                filterId: values.filterId === '-1' ? null : values.filterId,
            });
            onClose();
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth scroll="paper">
            <DialogTitle>{i18n.t('emailFilter.modal.title.add')}</DialogTitle>
            <Formik
                initialValues={emailContactFilter}
                validationSchema={EmailContactSchema}
                validateOnMount
                validateOnBlur
                enableReinitialize
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSaveFilter(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ values, setFieldValue, isValid, touched, errors, isSubmitting }) => (
                    <Form
                        style={{
                            width: '100%',
                        }}
                    >
                        <DialogContent dividers>
                            <Grid spacing={2} container>
                                {!emailContactFilterId && (
                                    <Grid item xs={12}>
                                        <ContactsFilter
                                            onFiltered={(value) => setFieldValue('contactId', value.id)}
                                            single
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" margin="dense" fullWidth>
                                        <InputLabel id="emailContactFilter-modal-form-filter-selection-label">
                                            {i18n.t('emailContact.filter.modal.form.filter')}
                                        </InputLabel>
                                        <Field
                                            as={Select}
                                            label={i18n.t('emailContact.filter.modal.form.filter')}
                                            placeholder={i18n.t('emailContact.filter.modal.form.filter')}
                                            labelId="emailContactFilter-modal-form-filter-selection-label"
                                            name="filterId"
                                            error={touched.contactId && Boolean(errors.contactId)}
                                            helperText={touched.contactId && errors.contactId}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        >
                                            <MenuItem value="-1">
                                                {i18n.t('emailContact.filter.modal.form.filterAll')}
                                            </MenuItem>
                                            {filters.map((filter) => (
                                                <MenuItem key={filter.id} value={filter.id}>
                                                    {filter.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" margin="dense" fullWidth>
                                        <InputLabel id="emailContactFilter-modal-form-filter-category-selection-label">
                                            {i18n.t('emailContact.filter.modal.form.category')}
                                        </InputLabel>
                                        <Field
                                            as={Select}
                                            label={i18n.t('emailContact.filter.modal.form.category')}
                                            placeholder={i18n.t('emailContact.filter.modal.form.category')}
                                            labelId="emailContactFilter-modal-form-filter-category-selection-label"
                                            name="categoryId"
                                            error={touched.category && Boolean(errors.category)}
                                            helperText={touched.category && errors.category}
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        >
                                            {categories.map((category) => (
                                                <MenuItem key={category.id} value={category.id}>
                                                    {i18n.t(
                                                        `emailContact.filter.list.filter.category.name.${category.name}`
                                                    )}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t('emailContact.filter.modal.form.value')}
                                        name="value"
                                        error={touched.value && Boolean(errors.value)}
                                        helperText={touched.value && errors.value}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t('emailContact.filter.modal.form.verifyDaysAgo')}
                                        name="verifyDaysAgo"
                                        error={touched.verifyDaysAgo && Boolean(errors.verifyDaysAgo)}
                                        helperText={
                                            (touched.verifyDaysAgo && errors.verifyDaysAgo) ||
                                            i18n.t('emailContact.filter.modal.form.verifyDaysAgoHelper')
                                        }
                                        variant="outlined"
                                        margin="dense"
                                        type="number"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} color="secondary" disabled={isSubmitting} variant="outlined">
                                {i18n.t('emailContact.filter.modal.buttons.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting || !isValid}
                                variant="contained"
                            >
                                {i18n.t('emailContact.filter.modal.buttons.okAdd')}
                                {isSubmitting && <CircularProgress size={24} />}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default EmailContactFilterModal;
