import api from '../../services/api';

const useAI = () => {
    const getSuggestion = async (params) => {
        const { data } = await api.request({
            url: `/ai/suggestions`,
            method: 'GET',
            params,
        });
        return data;
    };

    const getPrompts = async () => {
        const { data } = await api.request({
            url: '/ai/prompts',
            method: 'GET',
        });
        return data;
    };

    const getPrompt = async (promptId) => {
        const { data } = await api.request({
            url: `/ai/prompts/${promptId}`,
            method: 'GET',
        });
        return data;
    };

    const save = async (prompt) => {
        await api.request({
            url: '/ai/prompts',
            method: 'POST',
            data: prompt,
        });
    };

    const update = async (prompt) => {
        await api.request({
            url: `/ai/prompts/${prompt.id}`,
            method: 'PUT',
            data: prompt,
        });
    };

    return {
        getSuggestion,
        getPrompts,
        getPrompt,
        save,
        update,
    };
};

export default useAI;
