import React, { useEffect, useRef, useState } from 'react';

import { Field, FieldArray, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Box, FormControlLabel, MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import SaveIcon from '@material-ui/icons/Save';
import CustomToolTip from '../ToolTips';
import AttachmentIcon from '@material-ui/icons/Attachment';

import OptionsChatBot from '../ChatBots/options';

import { i18n } from '../../translate/i18n';

import { FormControl, Grid, IconButton, InputAdornment, InputLabel, Paper, Tab, Tabs } from '@material-ui/core';
import { Colorize } from '@material-ui/icons';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import ColorPicker from '../ColorPicker';
import SchedulesForm from '../SchedulesForm';
import FileUpload from '../FileUpload';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
    greetingMessage: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    custom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    selectField: {
        marginRight: theme.spacing(1),
        flex: 1,
        minWidth: '300px',
    },
    switchContainer: {
        display: 'flex',
        height: '100%',
        margin: 0,
        justifyContent: 'flex-end',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

function removeMediaUrlKey(obj) {
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            removeMediaUrlKey(obj[i]);
        }
    } else if (obj !== null && typeof obj === 'object') {
        for (const key in obj) {
            if (key === 'mediaUrl') {
                delete obj[key];
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                removeMediaUrlKey(obj[key]);
            }
        }
    }
}

const QueueSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Muito Curto!').max(50, 'Muito Longo!').required('Requerido'),
    color: Yup.string().min(3, 'Muito Curto!').max(9, 'Muito Longo!').required(),
    greetingMessage: Yup.string(),
});

const QueueModal = ({ open, onClose, queueId, onEdit }) => {
    const classes = useStyles();

    const initialState = {
        name: '',
        color: '',
        greetingMessage: '',
        outOfHoursMessage: '',
        chatbots: [],
        typeChatbot: '',
        n8n: '',
        n8nId: '',
        mediaUrl: null,
        workspaceTypebot: '',
        typebotId: '',
        resetChatbotMsg: false,
        tempoRoteador: 2,
        ativarRoteador: false,
    };

    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
    const [queue, setQueue] = useState(initialState);
    const [tab, setTab] = useState(0);
    const [schedulesEnabled, setSchedulesEnabled] = useState(false);
    const greetingRef = useRef();

    const [activeStep, setActiveStep] = React.useState(null);
    const [, setSelectedQueue] = useState(null);
    const [, setConfirmModalOpen] = useState(false);
    const [isStepContent, setIsStepContent] = React.useState(true);
    const [isNameEdit, setIsNamedEdit] = React.useState(null);
    const [isGreetingMessageEdit, setGreetingMessageEdit] = React.useState(null);
    const [workspaceTypebots, setWorkspaceTypebots] = useState([]);
    const [workflowN8N, setWorkFlowN8N] = useState([]);
    const [typeBots, setTypebots] = useState([]);
    const [filesData, setFilesData] = useState({ queue: null, chatbot: {} });

    const [schedules, setSchedules] = useState([
        {
            weekday: 'Segunda-feira',
            weekdayEn: 'monday',
            startTime: '',
            endTime: '',
            lunchTimeStart: '',
            lunchTimeEnd: '',
        },
        {
            weekday: 'Terça-feira',
            weekdayEn: 'tuesday',
            startTime: '',
            endTime: '',
            lunchTimeStart: '',
            lunchTimeEnd: '',
        },
        {
            weekday: 'Quarta-feira',
            weekdayEn: 'wednesday',
            startTime: '',
            endTime: '',
            lunchTimeStart: '',
            lunchTimeEnd: '',
        },
        {
            weekday: 'Quinta-feira',
            weekdayEn: 'thursday',
            startTime: '',
            endTime: '',
            lunchTimeStart: '',
            lunchTimeEnd: '',
        },
        {
            weekday: 'Sexta-feira',
            weekdayEn: 'friday',
            startTime: '',
            endTime: '',
            lunchTimeStart: '',
            lunchTimeEnd: '',
        },
        { weekday: 'Sábado', weekdayEn: 'saturday', startTime: '', endTime: '', lunchTimeStart: '', lunchTimeEnd: '' },
        { weekday: 'Domingo', weekdayEn: 'sunday', startTime: '', endTime: '', lunchTimeStart: '', lunchTimeEnd: '' },
    ]);

    useEffect(() => {
        api.get(`/settings`).then(({ data }) => {
            if (Array.isArray(data)) {
                const scheduleType = data.find((d) => d.key === 'scheduleType');
                if (scheduleType) {
                    setSchedulesEnabled(scheduleType.value === 'queue');
                }
            }
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (!queueId) return;
            try {
                const { data } = await api.get(`/queue/${queueId}`);
                setQueue((prevState) => {
                    return { ...prevState, ...data };
                });
                setSchedules(data.schedules);
            } catch (err) {
                toastError(err);
            }
        })();

        return () => {
            setQueue({
                name: '',
                color: '',
                greetingMessage: '',
                outOfHoursMessage: '',
                chatbots: [],
                typeChatbot: '',
                n8n: '',
                n8nId: '',
                mediaUrl: null,
                workspaceTypebot: '',
                typebotId: '',
                resetChatbotMsg: false,
                tempoRoteador: 2,
                ativarRoteador: false,
            });
        };
    }, [queueId, open]);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get(`/typebot/listworkspaces`);
                // console.log(data)
                if (data?.workspaces && data.workspaces?.length > 0) {
                    setWorkspaceTypebots(data?.workspaces);
                }
            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get(`/n8n/listworkFlow`);
                if (data?.data && data?.data?.length > 0) {
                    const extractedData = data?.data?.map((item) => ({
                        id: item.id, // Substitua 'id' pelo nome real da propriedade
                        name: item.name,
                    }));
                    console.log(extractedData);
                    setWorkFlowN8N(extractedData);
                }
            } catch (err) {
                toastError(err);
            }
        })();
    }, []);

    useEffect(() => {
        if (queueId && queue?.workspaceTypebot) {
            (async () => {
                try {
                    const { data } = await api.get(`/typebot/listtypebots`, {
                        params: {
                            workspaceId: queue?.workspaceTypebot,
                        },
                    });
                    // console.log("list ",data)
                    if (data?.typebots && data?.typebots.length > 0) {
                        setTypebots(data?.typebots);
                    }
                } catch (err) {
                    toastError(err);
                }
            })();
        }
    }, [queueId, queue]);

    const handleSaveFile = async (id, file, isQueue = false) => {
        try {
            const formData = new FormData();
            formData.append('medias', file);
            await api.post(`/${isQueue ? 'queue' : 'chatbot'}/m/${id}`, formData);
        } catch (err) {
            console.error(err);
        } finally {

        }
    };

    const handleDeleteFile = async (id, isQueue) => {
        try {
            const formData = new FormData();
            formData.append('medias', null);
            await api.post(`/${isQueue ? 'queue' : 'chatbot'}/m/${id}`, formData);
        } catch (err) {
            console.error(err)
        } finally {

        }
    };

    const handleSaveFileInQueue = async (id, isChatbot, file) => {
        if (isChatbot && id) {
            try {
                if (file) {
                    await handleSaveFile(id, file);
                    setFilesData(prevState => {
                        const updatedChatbot = { ...prevState.chatbot };
                        updatedChatbot[id] = file;
                        return {
                            ...prevState,
                            chatbot: updatedChatbot
                        };
                    });
                } else {
                    await handleDeleteFile(id);
                    setFilesData(prevState => {
                        const updatedChatbot = { ...prevState.chatbot };
                        delete updatedChatbot[id];
                        return {
                            ...prevState,
                            chatbot: updatedChatbot
                        };
                    });
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            if (queueId) { await handleSaveFile(queueId, file || null, true) }
            setFilesData(prevState => ({
                ...prevState,
                queue: file || null
            }));
        }
    };


    const handleChangeWorkspace = async (workspace) => {
        if (!workspace) {
            setTypebots([]);
            return;
        }
        try {
            const { data } = await api.get(`/typebot/listtypebots`, {
                params: {
                    workspaceId: workspace,
                },
            });
            // console.log(data)
            if (data?.typebots && data?.typebots?.length > 0) {
                setTypebots(data.typebots);
            } else {
                setTypebots([]);
            }
        } catch (err) {
            toastError(err);
        }
    };

    const handleClose = () => {
        onClose();
        setQueue(initialState);
    };

    const handleSaveQueue = async (values, filesData) => {
        try {
            const formData = new FormData();
            // Adiciona os schedules aos valores
            if (!!schedules && schedules.length > 0) {
                values.schedules = schedules;
            }

            // Adiciona os valores do formulário ao FormData
            for (const key in values) {
                if (values.hasOwnProperty(key)) {
                    // Verifica se é o array chatbots ou schedules
                    if (key === 'chatbots' || key === 'schedules') {
                        formData.append(key, JSON.stringify(values[key]));
                    } else {
                        formData.append(key, values[key]);
                    }
                }
            }

            // Adiciona arquivos ao FormData baseado em filesData
            if (filesData?.queue) {
                formData.append('medias', filesData.queue);
            };

            // Envia a requisição usando axios
            if (queueId) {
                await api.put(`/queue/${queueId}`, formData);
            } else {
                await api.post('/queue', formData);
            };

            toast.success('Fila salva com sucesso!');
            handleClose();
        } catch (err) {
            toastError(err);
        }
    };

    const handleSaveSchedules = async (values) => {
        toast.success('Clique em salvar para registar as alterações');
        setSchedules(values);
        setTab(0);
    };

    const handleSaveBot = async (values) => {
        try {
            if (queueId) {
                const { data } = await api.put(`/queue/${queueId}`, values);
                if (data.chatbots && data.chatbots.length) {
                    onEdit(data);
                    setQueue(data);
                }
            } else {
                const { data } = await api.post('/queue', values);
                if (data.chatbots && data.chatbots.length) {
                    setQueue(data);
                    onEdit(data);
                    handleClose();
                }
            }

            setIsNamedEdit(null);
            setGreetingMessageEdit(null);
            toast.success('Fila salva com sucesso!');
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <div className={classes.root}>
            <Dialog maxWidth="lg" fullWidth={true} open={open} onClose={handleClose} scroll="paper">
                <DialogTitle>
                    {queueId ? `${i18n.t('queueModal.title.edit')}` : `${i18n.t('queueModal.title.add')}`}
                </DialogTitle>
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(_, v) => setTab(v)}
                    aria-label="disabled tabs example"
                >
                    <Tab label="Dados da Fila" />
                    <Tab label="Integrações" />
                    {schedulesEnabled && <Tab label="Horários de Atendimento" />}
                </Tabs>

                {tab === 0 && (
                    <Paper>
                        <Formik
                            initialValues={queue}
                            validateOnChange={false}
                            enableReinitialize={true}
                            validationSchema={QueueSchema}
                            onSubmit={(values, actions) => {
                                setTimeout(() => {
                                    handleSaveQueue(values, filesData);
                                    actions.setSubmitting(false);
                                }, 400);

                            }}
                        >
                            {({ touched, errors, isSubmitting, values }) => (
                                <Form>
                                    <DialogContent dividers>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} sm={4}>
                                                <Field
                                                    fullWidth
                                                    as={TextField}
                                                    label={i18n.t('queueModal.form.name')}
                                                    autoFocus
                                                    name="name"
                                                    error={touched.name && Boolean(errors.name)}
                                                    helperText={touched.name && errors.name}
                                                    variant="outlined"
                                                    margin="dense"
                                                    className={classes.textField}
                                                />
                                            </Grid>

                                            <Grid item xs={4} sm={2}>
                                                <Field
                                                    fullWidth
                                                    as={TextField}
                                                    label={i18n.t('queueModal.form.color')}
                                                    name="color"
                                                    id="color"
                                                    onFocus={() => {
                                                        setColorPickerModalOpen(true);
                                                        // greetingRef?.current?.focus();
                                                    }}
                                                    error={touched.color && Boolean(errors.color)}
                                                    helperText={touched.color && errors.color}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <div
                                                                    style={{ backgroundColor: values.color }}
                                                                    className={classes.colorAdorment}
                                                                ></div>
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <IconButton
                                                                size="small"
                                                                color="default"
                                                                onClick={() => setColorPickerModalOpen(true)}
                                                            >
                                                                <Colorize />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                                <ColorPicker
                                                    open={colorPickerModalOpen}
                                                    handleClose={() => setColorPickerModalOpen(false)}
                                                    onChange={(color) => {
                                                        values.color = color;
                                                        setQueue(() => {
                                                            return { ...values, color };
                                                        });
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={2} sm={1}>
                                                <FormControlLabel
                                                    className={classes.switchContainer}
                                                    fullWidth
                                                    control={
                                                        <Field
                                                            as={Switch}
                                                            size="small"
                                                            color="primary"
                                                            name="ativarRoteador"
                                                            checked={values.ativarRoteador}
                                                        />
                                                    }
                                                    label="Rodízio"
                                                />
                                            </Grid>

                                            <Grid item xs={6} sm={2}>
                                                <FormControl margin="dense" variant="outlined" fullWidth>
                                                    <InputLabel htmlFor="status-tempoRoteador">Tempo</InputLabel>
                                                    <Field
                                                        fullWidth
                                                        as={Select}
                                                        label="Tempo"
                                                        name="tempoRoteador"
                                                        id="tempoRoteador"
                                                        error={touched.tempoRoteador && Boolean(errors.tempoRoteador)}
                                                        helperText={touched.tempoRoteador && errors.tempoRoteador}
                                                        variant="outlined"
                                                        margin="dense"
                                                        disabled={!values.ativarRoteador}
                                                    >
                                                        <MenuItem value="2">2 minutos</MenuItem>
                                                        <MenuItem value="5">5 minutos</MenuItem>
                                                        <MenuItem value="10">10 minutos</MenuItem>
                                                        <MenuItem value="15">15 minutos</MenuItem>
                                                        <MenuItem value="30">30 minutos</MenuItem>
                                                        <MenuItem value="45">45 minutos</MenuItem>
                                                        <MenuItem value="60">60 minutos</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={6} sm={3}>
                                                <FileUpload
                                                    typeComponent="queue"
                                                    onChange={file => {
                                                        handleSaveFileInQueue(null, false, file)
                                                    }}
                                                    file={values.mediaUrl || null}
                                                    styleComponent={{
                                                        borderRadius: '8px',
                                                        padding: '19px',
                                                        marginTop: '4px',
                                                        width: '100%'
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <div style={{ marginTop: 5 }}>
                                            <Grid spacing={1} container>
                                                <Grid xs={12} md={schedulesEnabled ? 6 : 12} item>
                                                    <Field
                                                        as={TextField}
                                                        label={i18n.t('queueModal.form.greetingMessage')}
                                                        multiline
                                                        minRows={5}
                                                        fullWidth
                                                        name="greetingMessage"
                                                        error={
                                                            touched.greetingMessage && Boolean(errors.greetingMessage)
                                                        }
                                                        helperText={touched.greetingMessage && errors.greetingMessage}
                                                        variant="outlined"
                                                        margin="dense"
                                                    />
                                                </Grid>
                                                {schedulesEnabled && (
                                                    <Grid xs={12} md={6} item>
                                                        <Field
                                                            as={TextField}
                                                            label={i18n.t('queueModal.form.outOfHoursMessage')}
                                                            placeholder={i18n.t('queueModal.form.outOfHoursMessage')}
                                                            multiline
                                                            minRows={5}
                                                            fullWidth
                                                            name="outOfHoursMessage"
                                                            error={
                                                                touched.outOfHoursMessage &&
                                                                Boolean(errors.outOfHoursMessage)
                                                            }
                                                            helperText={
                                                                touched.outOfHoursMessage && errors.outOfHoursMessage
                                                            }
                                                            variant="outlined"
                                                            margin="dense"
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </div>

                                        <div>
                                            <FieldArray name="chatbots">
                                                {({ push, remove }) => (
                                                    <>
                                                        <Stepper
                                                            nonLinear
                                                            activeStep={activeStep}
                                                            orientation="vertical"
                                                        >
                                                            {values.chatbots &&
                                                                values.chatbots.length > 0 &&
                                                                values.chatbots.map((info, index) => (
                                                                    <Step
                                                                        key={`${info.id ? info.id : index}-chatbots`}
                                                                        onClick={() => setActiveStep(index)}
                                                                    >
                                                                        <StepLabel key={`${info.id}-chatbots`}>
                                                                            {isNameEdit !== index &&
                                                                                queue.chatbots[index]?.name ? (
                                                                                <div
                                                                                    className={classes.greetingMessage}
                                                                                    variant="body1"
                                                                                >
                                                                                    {values.chatbots[index].name}

                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => {
                                                                                            setIsNamedEdit(index);
                                                                                            setIsStepContent(false);
                                                                                        }}
                                                                                    >
                                                                                        <EditIcon />
                                                                                    </IconButton>
                                                                                    <IconButton
                                                                                        onClick={() => {
                                                                                            setSelectedQueue(info);
                                                                                            setConfirmModalOpen(true);
                                                                                        }}
                                                                                        size="small"
                                                                                    >
                                                                                        <DeleteOutline />
                                                                                    </IconButton>
                                                                                    <Box>
                                                                                        <FileUpload
                                                                                            typeComponent="chatbot"
                                                                                            onChange={file => {
                                                                                                handleSaveFileInQueue(values.chatbots[index].id, true, file)
                                                                                            }}
                                                                                            file={values.chatbots[index].mediaUrl || null}
                                                                                            styleComponent={{
                                                                                                borderRadius: '6px',
                                                                                                height: '30px'
                                                                                            }}
                                                                                        />
                                                                                    </Box>
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    <Field
                                                                                        as={TextField}
                                                                                        name={`chatbots[${index}].name`}
                                                                                        variant="standard"
                                                                                        color="primary"
                                                                                        disabled={isSubmitting}
                                                                                        autoFocus
                                                                                        error={
                                                                                            touched?.chatbots?.[index]
                                                                                                ?.name &&
                                                                                            Boolean(
                                                                                                errors.chatbots?.[index]
                                                                                                    ?.name
                                                                                            )
                                                                                        }
                                                                                        className={classes.textField}
                                                                                    />

                                                                                    <FormControlLabel
                                                                                        control={
                                                                                            <Field
                                                                                                as={Switch}
                                                                                                color="primary"
                                                                                                name={`chatbots[${index}].isAgent`}
                                                                                                checked={
                                                                                                    values.chatbots[
                                                                                                        index
                                                                                                    ].isAgent || false
                                                                                                }
                                                                                            />
                                                                                        }
                                                                                        label="Atendente"
                                                                                    />

                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() =>
                                                                                            values.chatbots[index].name
                                                                                                ? handleSaveBot(values)
                                                                                                : null
                                                                                        }
                                                                                        disabled={isSubmitting}
                                                                                    >
                                                                                        <SaveIcon />
                                                                                    </IconButton>

                                                                                    <IconButton
                                                                                        size="small"
                                                                                        onClick={() => remove(index)}
                                                                                        disabled={isSubmitting}
                                                                                    >
                                                                                        <DeleteOutline />
                                                                                    </IconButton>
                                                                                </>
                                                                            )}
                                                                        </StepLabel>

                                                                        {isStepContent && queue.chatbots[index] && (
                                                                            <StepContent>
                                                                                <>
                                                                                    {isGreetingMessageEdit !== index ? (
                                                                                        <div
                                                                                            className={
                                                                                                classes.greetingMessage
                                                                                            }
                                                                                        >
                                                                                            <Typography
                                                                                                color="textSecondary"
                                                                                                variant="body1"
                                                                                            >
                                                                                                Message:
                                                                                            </Typography>

                                                                                            {
                                                                                                values.chatbots[index]
                                                                                                    .greetingMessage
                                                                                            }

                                                                                            {!queue.chatbots[index]
                                                                                                ?.greetingMessage && (
                                                                                                    <CustomToolTip
                                                                                                        title="A mensagem é necessária para ir para o próximo nível."
                                                                                                        content="Se a mensagem não for definida, o bot não seguirá."
                                                                                                    >
                                                                                                        <HelpOutlineOutlinedIcon
                                                                                                            color="secondary"
                                                                                                            style={{
                                                                                                                marginLeft:
                                                                                                                    '4px',
                                                                                                            }}
                                                                                                            fontSize="small"
                                                                                                        />
                                                                                                    </CustomToolTip>
                                                                                                )}

                                                                                            <IconButton
                                                                                                size="small"
                                                                                                onClick={() =>
                                                                                                    setGreetingMessageEdit(
                                                                                                        index
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <EditIcon />
                                                                                            </IconButton>

                                                                                        </div>
                                                                                    ) : (
                                                                                        <div
                                                                                            className={
                                                                                                classes.greetingMessage
                                                                                            }
                                                                                        >
                                                                                            <Field
                                                                                                as={TextField}
                                                                                                name={`chatbots[${index}].greetingMessage`}
                                                                                                variant="standard"
                                                                                                margin="dense"
                                                                                                fullWidth
                                                                                                multiline
                                                                                                error={
                                                                                                    touched.greetingMessage &&
                                                                                                    Boolean(
                                                                                                        errors.greetingMessage
                                                                                                    )
                                                                                                }
                                                                                                helperText={
                                                                                                    touched.greetingMessage &&
                                                                                                    errors.greetingMessage
                                                                                                }
                                                                                                className={
                                                                                                    classes.textField
                                                                                                }
                                                                                            />

                                                                                            <IconButton
                                                                                                size="small"
                                                                                                onClick={() =>
                                                                                                    handleSaveBot(
                                                                                                        values
                                                                                                    )
                                                                                                }
                                                                                                disabled={isSubmitting}
                                                                                            >
                                                                                                {' '}
                                                                                                <SaveIcon />
                                                                                            </IconButton>
                                                                                        </div>
                                                                                    )}

                                                                                    <OptionsChatBot
                                                                                        chatBotId={info.id}
                                                                                    />
                                                                                </>
                                                                            </StepContent>
                                                                        )}
                                                                    </Step>
                                                                ))}

                                                            <Step>
                                                                <StepLabel
                                                                    onClick={() => push({ name: '', value: '' })}
                                                                >
                                                                    Adicionar opções
                                                                </StepLabel>
                                                            </Step>
                                                        </Stepper>
                                                    </>
                                                )}
                                            </FieldArray>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleClose}
                                            // color="secondary"
                                            disabled={isSubmitting}
                                        // variant="outlined"
                                        >
                                            {i18n.t('queueModal.buttons.cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {queueId
                                                ? `${i18n.t('queueModal.buttons.okEdit')}`
                                                : `${i18n.t('queueModal.buttons.okAdd')}`}
                                            {isSubmitting && (
                                                <CircularProgress size={24} className={classes.buttonProgress} />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                )}

                {tab === 1 && (
                    <Paper>
                        <Formik
                            initialValues={queue}
                            enableReinitialize={true}
                            validationSchema={QueueSchema}
                            onSubmit={(values, actions) => {
                                setTimeout(() => {
                                    handleSaveQueue(values);
                                    actions.setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({ isSubmitting, values, setFieldValue }) => (
                                <Form>
                                    <DialogContent dividers>
                                        <FormControl className={classes.selectField} margin="dense" variant="outlined">
                                            <InputLabel htmlFor="typeChatbot-selection">Tipo Chatbot</InputLabel>
                                            <Field
                                                as={Select}
                                                id="typeChatbot-selection"
                                                label="Tipo Chatbot"
                                                labelId="typeChatbot-selection-label"
                                                name="typeChatbot"
                                                margin="dense"
                                                className={classes.textField}
                                            >
                                                <MenuItem value=""></MenuItem>
                                                <MenuItem value="n8n">N8N</MenuItem>
                                                <MenuItem value="typebot">Typebot</MenuItem>
                                                <MenuItem value="emailFilter">Filtro de Email</MenuItem>
                                            </Field>
                                        </FormControl>
                                        <FormControlLabel
                                            control={
                                                <Field
                                                    as={Switch}
                                                    color="primary"
                                                    name="resetChatbotMsg"
                                                    checked={values.resetChatbotMsg}
                                                />
                                            }
                                            label={i18n.t('queueModal.form.resetChatbot')}
                                        />
                                        {values.typeChatbot === 'n8n' && (
                                            <div>
                                                <FormControl
                                                    className={classes.selectField}
                                                    margin="dense"
                                                    variant="outlined"
                                                >
                                                    <InputLabel htmlFor="workflowN8N-selection">Workspaces</InputLabel>
                                                    <Field
                                                        as={Select}
                                                        id="workflowN8N-selection"
                                                        label="workFlows"
                                                        labelId="workflowN8N-selection-label"
                                                        name="n8nId"
                                                        onChange={(e) => {
                                                            setFieldValue('n8n', e.target?.value);
                                                            setFieldValue('n8nId', e.target?.value);
                                                        }}
                                                        margin="dense"
                                                        className={classes.textField}
                                                    >
                                                        <MenuItem value={''}>&nbsp;</MenuItem>
                                                        {workflowN8N.map((workflow, key) => (
                                                            <MenuItem key={key} value={workflow.id}>
                                                                {workflow.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                            </div>
                                        )}
                                        {values.typeChatbot === 'typebot' && (
                                            <div>
                                                <FormControl
                                                    className={classes.selectField}
                                                    margin="dense"
                                                    variant="outlined"
                                                >
                                                    <InputLabel htmlFor="workspaceTypebot-selection">
                                                        Workspaces
                                                    </InputLabel>
                                                    <Field
                                                        as={Select}
                                                        id="workspaceTypebot-selection"
                                                        label="workSpaces"
                                                        labelId="workspaceTypebot-selection-label"
                                                        name="workspaceTypebot"
                                                        onChange={(e) => {
                                                            setFieldValue('workspaceTypebot', e.target?.value);
                                                            handleChangeWorkspace(e.target?.value);
                                                        }}
                                                        margin="dense"
                                                        className={classes.textField}
                                                    >
                                                        <MenuItem value={''}>&nbsp;</MenuItem>
                                                        {workspaceTypebots.map((workspace, key) => (
                                                            <MenuItem key={key} value={workspace.id}>
                                                                {workspace.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                                <FormControl
                                                    className={classes.selectField}
                                                    margin="dense"
                                                    variant="outlined"
                                                >
                                                    <InputLabel htmlFor="typebotId-selection">TypeBot</InputLabel>
                                                    <Field
                                                        as={Select}
                                                        id="typebot-selection"
                                                        label="Typebot"
                                                        labelId="typebot-selection-label"
                                                        name="typebotId"
                                                        margin="dense"
                                                        className={classes.textField}
                                                    >
                                                        <MenuItem value={''}>&nbsp;</MenuItem>
                                                        {typeBots.map((typebot, key) => (
                                                            <MenuItem key={key} value={typebot.id}>
                                                                {typebot.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                            </div>
                                        )}
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleClose}
                                            color="secondary"
                                            disabled={isSubmitting}
                                            variant="outlined"
                                        >
                                            {i18n.t('queueModal.buttons.cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            disabled={isSubmitting}
                                            variant="contained"
                                            className={classes.btnWrapper}
                                        >
                                            {queueId
                                                ? `${i18n.t('queueModal.buttons.okEdit')}`
                                                : `${i18n.t('queueModal.buttons.okAdd')}`}
                                            {isSubmitting && (
                                                <CircularProgress size={24} className={classes.buttonProgress} />
                                            )}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                )}

                {tab === 2 && (
                    <Paper style={{ padding: 20 }}>
                        <SchedulesForm
                            loading={false}
                            onSubmit={handleSaveSchedules}
                            initialValues={schedules}
                            labelSaveButton="Adicionar"
                        />
                    </Paper>
                )}
            </Dialog>
        </div>
    );
};

export default QueueModal;
