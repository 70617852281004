import { Box, Typography, makeStyles } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    calendarContainer: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
    },
    calendarIndicator: {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: grey[400],
    },
    selectContainer: {
        width: '100%',
        textAlign: 'left',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const Calendar = ({ calendar }) => {
    const classes = useStyles();

    if (!calendar.id) {
        return <>-</>;
    }

    return (
        <Box className={classes.calendarContainer}>
            <Box
                className={classes.calendarIndicator}
                style={{
                    ...(calendar.backgroundColor && {
                        backgroundColor: calendar.backgroundColor,
                    }),
                }}
            />
            <Typography>{calendar.summary}</Typography>
        </Box>
    );
};
