import { Typography, useTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import { ItemContainer } from './style';

const EmailContactItemContainer = ({ title, item, variant }) => {
    const theme = useTheme();
    const primaryColor = theme.mode === 'light' ? grey[200] : grey[700];
    const secondaryColor = theme.mode === 'light' ? grey[50] : grey[800];
    return (
        <ItemContainer
            variant={variant}
            style={{
                backgroundColor: variant === 'primary' ? primaryColor : secondaryColor,
            }}
        >
            <Typography variant="h6">{title}</Typography>
            <hr />
            {item}
        </ItemContainer>
    );
};

export default EmailContactItemContainer;
