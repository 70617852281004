import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
        maxWidth: 450,
    },
    tooltipPopper: {
        textAlign: 'center',
    },
    buttonProgress: {
        color: green[500],
    },
}));

const ToolTip = ({ title, content, children }) => {
    const classes = useStyles();

    return (
        <Tooltip
            arrow
            // placement="bottom"
            classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper,
            }}
            title={
                <React.Fragment>
                    <Typography gutterBottom color="inherit">
                        {title}
                    </Typography>
                    {content && <Typography>{content}</Typography>}
                </React.Fragment>
            }
        >
            {children}
        </Tooltip>
    );
};

export default ToolTip;
