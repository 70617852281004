import React, { useEffect, useState } from 'react';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import Title from '../../components/Title';
import usePlans from '../../hooks/usePlans';
import { i18n } from '../../translate/i18n';
import EmailSettingsList from './EmailSettingsList';
import ExternalCalendarList from './ExternalCalendarList';
import WhatsAppList from './WhatsAppList';

const Connections = () => {
    const [planConfig, setPlanConfig] = useState(false);

    const { getPlanCompany } = usePlans();

    useEffect(() => {
        async function fetchData() {
            const companyId = localStorage.getItem('companyId');
            const planConfigs = await getPlanCompany(undefined, companyId);
            setPlanConfig(planConfigs);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t('connections.title')}</Title>
            </MainHeader>
            <WhatsAppList />
            {planConfig?.plan?.useEmail && <EmailSettingsList />}
            {planConfig?.plan?.useExternalCalendar && <ExternalCalendarList />}
        </MainContainer>
    );
};

export default Connections;
