const messages = {
    pt: {
        translations: {
            loading: 'carregando...',
            signup: {
                title: 'Cadastre-se',
                toasts: {
                    success: 'Usuário criado com sucesso! Faça seu login!!!.',
                    fail: 'Erro ao criar usuário. Verifique os dados informados.',
                },
                form: {
                    name: 'Nome',
                    email: 'Email',
                    password: 'Senha',
                    company: 'Nome da Organização',
                    phone: 'Whatsapp (DDD + NÚMERO)',
                },
                buttons: {
                    submit: 'Cadastrar',
                    login: 'Já tem uma conta? Entre!',
                },
            },
            login: {
                title: 'Login',
                form: {
                    email: 'Email',
                    password: 'Senha',
                    supportToken: "Token de acesso"
                },
                buttons: {
                    submit: 'Entrar',
                    register: 'Não tem um conta? Cadastre-se!',
                },
            },
            companies: {
                title: 'Cadastrar Empresa',
                form: {
                    name: 'Nome da Empresa',
                    plan: 'Plano',
                    token: 'Token',
                    submit: 'Cadastrar',
                    success: 'Empresa criada com sucesso!',
                },
            },
            auth: {
                toasts: {
                    success: 'Login efetuado com sucesso!',
                },
                dueDate: {
                    expiration: 'Sua assinatura expira em',
                    days: 'dias!',
                    day: 'dia!',
                    expirationToday: 'Sua assinatura expira hoje!',
                },
                token: 'Token',
            },
            dashboard: {
                charts: {
                    perDay: {
                        title: 'Atendimentos hoje: ',
                    },
                },
            },
            connections: {
                title: 'Conexões',
                toasts: {
                    deleted: 'Conexão excluída com sucesso!',
                },
                confirmationModal: {
                    deleteTitle: 'Deletar',
                    deleteMessage: 'Você tem certeza? Essa ação não pode ser revertida.',
                    disconnectTitle: 'Desconectar',
                    disconnectMessage: 'Tem certeza? Você precisará ler o QR Code novamente.',
                },
                buttons: {
                    add: 'Adicionar WhatsApp',
                    disconnect: 'desconectar',
                    tryAgain: 'Tentar novamente',
                    qrcode: 'QR CODE',
                    newQr: 'Novo QR CODE',
                    connecting: 'Conectando',
                },
                toolTips: {
                    disconnected: {
                        title: 'Falha ao iniciar sessão do WhatsApp',
                        content:
                            'Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code',
                    },
                    qrcode: {
                        title: 'Esperando leitura do QR Code',
                        content: "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
                    },
                    connected: {
                        title: 'Conexão estabelecida!',
                    },
                    timeout: {
                        title: 'A conexão com o celular foi perdida',
                        content:
                            "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
                    },
                },
                noConnections: 'Nenhuma conexão encontrada',
                whatsapp: {
                    title: 'WhatsApp',
                    channel: 'Canal',
                    restart: 'Reiniciar Conexões',
                    support: 'Chamar no suporte',
                    addNew: 'Nova Conexão',
                    name: 'Nome',
                    status: 'Status',
                    lastUpdate: 'Última atualização',
                    default: 'Padrão',
                    actions: 'Ações',
                    session: 'Sessão',
                    number: 'Número do Whatsapp',
                },
                emailSettings: {
                    title: 'Email IMAP',
                    addNew: 'Adicionar',
                    type: 'Tipo',
                    username: 'Usuário',
                    password: 'Senha',
                    host: 'Host',
                    port: 'Porta',
                    tls: 'TLS',
                    actions: 'Ações',
                },
                externalCalendar: {
                    title: 'Agendas',
                    addNew: 'Adicionar',
                    channel: 'Canal',
                    username: 'Usuário',
                    calendar: 'Agenda',
                    users: {
                        header: 'Usuários Autorizados',
                        all: 'Todos',
                    },
                    createdAt: 'Criado em',
                    actions: 'Ações',
                    userPermissionModal: {
                        title: 'Usuários Autorizados',
                        cancel: 'Cancelar',
                        save: 'Salvar',
                    },
                    confirmationModal: {
                        deleteTitle: 'Você tem certeza que quer excluir esta conexão?',
                        deleteMessage: 'Esta ação não pode ser revertida.',
                    },
                    toasts: {
                        userPermissionUpdated: 'Permissões de usuários atualizadas com sucesso',
                    },
                    updateCalendarName: {
                        title: 'Alterar Agenda',
                        calendar: 'Agenda',
                        save: 'Atualizar',
                        cancel: 'Cancelar',
                    },
                },
            },
            email: {
                modal: {
                    buttons: {
                        close: 'Fechar',
                    },
                    attachForm: {
                        title: 'Vincular a um contato',
                        value: 'Valor',
                        buttons: {
                            attach: 'Vincular',
                        },
                    },
                },
            },
            emailList: {
                modal: {
                    title: 'Emails',
                    list: {
                        table: {
                            id: 'Código',
                            sender: 'Remetente',
                            subject: 'Assunto',
                            messageDate: 'Data da Mensagem',
                            matchedPatternsCount: 'Encontrados/Vinculados',
                            actions: 'Ações',
                            empty: 'Nenhuma mensagem encontrada',
                            viewEmail: 'Ver Email',
                            viewMatchedPatterns: 'Ver Vínculos',
                        },
                    },
                    buttons: {
                        close: 'Fechar',
                    },
                },
            },
            emailMatchedPatterns: {
                modal: {
                    title: 'Padrões Encontrados',
                    list: {
                        table: {
                            id: 'Código',
                            value: 'Valor',
                            name: 'Nome',
                            attached: 'Vinculado',
                            empty: 'Nenhum padrão encontrado',
                        },
                    },
                    buttons: {
                        close: 'Fechar',
                    },
                },
            },
            emailSettingsModal: {
                title: {
                    add: 'Adicionar Email IMAP',
                    edit: 'Editar Email IMAP',
                },
                form: {
                    username: 'Usuário',
                    password: 'Senha',
                    host: 'Host',
                    port: 'Porta',
                    tls: 'TLS',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                    connectionValidation: 'Testar',
                    connectionValidating: 'Testando',
                },
                success: 'Configurações de Email salvas com sucesso.',
                connectionSuccess: 'Conexão estabelecida com sucesso!',
            },
            whatsappModal: {
                title: {
                    add: 'Adicionar WhatsApp',
                    edit: 'Editar WhatsApp',
                },
                form: {
                    name: 'Nome',
                    default: 'Padrão',
                    maxUseBotQueues: 'Quantidade máxima de vezes que o chatbot vai ser enviado',
                    expiresTicket: 'Encerrar chats abertos após x horas',
                    outOfHoursMessage: 'Mensagem de fora de expediente',
                    greetingMessage: 'Mensagem de saudação',
                    complationMessage: 'Mensagem de conclusão',
                    token: 'Token para integração externa',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'WhatsApp salvo com sucesso.',
            },
            qrCode: {
                message: 'Leia o QrCode para iniciar a sessão',
            },
            contacts: {
                title: 'Contatos',
                toasts: {
                    deleted: 'Contato excluído com sucesso!',
                },
                searchPlaceholder: 'Pesquisar...',
                confirmationModal: {
                    deleteTitle: 'Deletar ',
                    importTitlte: 'Importar contatos',
                    deleteMessage:
                        'Tem certeza que deseja deletar este contato? Todos os atendimentos relacionados serão perdidos.',
                    importMessage: 'Deseja importar todos os contatos do telefone?',
                },
                buttons: {
                    import: 'Importar Contatos',
                    add: 'Adicionar',
                    export: 'Exportar Contato',
                    info: 'Campos globais',
                },
                table: {
                    name: 'Nome',
                    whatsapp: 'WhatsApp',
                    email: 'Email',
                    document: 'Documento',
                    actions: 'Ações',
                },
            },
            forwardMessage: {
                text: 'Encaminhada',
            },
            forwardMessageModal: {
                title: 'Encaminhar mensagem',
                buttons: {
                    ok: 'Encaminhar',
                },
            },
            passwordReset: {
                title: 'Redefinir senha',
                voltar: 'Voltar para tela de login',
                form: {
                    email: 'Email',
                },
                buttons: {
                    submit: 'Verificar Email',
                    verify: 'Verificar Código',
                },
            },
            contactModal: {
                title: {
                    add: 'Adicionar contato',
                    edit: 'Editar contato',
                },
                form: {
                    mainInfo: 'Informações do contato',
                    extraInfo: 'Informações avançadas',
                    // Usar posteriormente para app mobile
                    mobile: {
                        mainInfo: 'Contato',
                        extraInfo: 'Avançado',
                    },
                    name: 'Nome',
                    number: 'Número do Whatsapp',
                    email: 'Email',
                    nationalId: 'RG',
                    address: "Endereço",
                    zipCode: "CEP",
                    maritalStatus: "Estado civil",
                    nationality: "Nacionalidade",
                    profession: "Profissão",
                    residentialNumber: "N°",
                    complement: "Complemento",
                    city: "Cidade",
                    state: "UF",
                    state: "UF",
                    dateBirth: "Data de Nascimento",
                    registrationDate: "Data de Abertura",
                    neighborhood: "Bairro",
                    extraName: 'Chave (Nome)',
                    extraValue: 'Valor',
                    globalExtraValue: 'Valor Padrão (Opcional)',
                },
                buttons: {
                    addExtraInfo: 'Adicionar informação',
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'Contato salvo com sucesso.',
            },
            queueModal: {
                title: {
                    add: 'Adicionar fila',
                    edit: 'Editar fila',
                },
                form: {
                    name: 'Nome',
                    color: 'Cor',
                    greetingMessage: 'Mensagem de saudação',
                    complationMessage: 'Mensagem de conclusão',
                    outOfHoursMessage: 'Mensagem de fora de expediente',
                    token: 'Token',
                    resetChatbot: 'Mensagem retorno Menu Inicial',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
            },
            userModal: {
                title: {
                    add: 'Adicionar usuário',
                    edit: 'Editar usuário',
                },
                form: {
                    name: 'Nome',
                    email: 'Email',
                    password: 'Senha',
                    farewellMessage: 'Mensagem de despedida',
                    profile: 'Perfil',
                    whatsapp: 'Conexão Padrão',
                    startWork: 'Inicio de trabalho',
                    endWork: 'Fim de trabalho',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'Usuário salvo com sucesso.',
                tabs: {
                    general: {
                        title: 'Dados Gerais',
                    },
                    permissions: {
                        title: 'Permissões',
                        isDefaultHistory: 'Permite ver histórico de conversas?',
                        isDefaultGroups: 'Permite ver conversas de outros usuários?',
                        isDefaultSemQueue: 'Permite ver conversas aguardando sem atribuições?',
                        isSignatureRequired: 'Assinatura permanente?',
                        isAllowedToSpy: 'Permite espiar conversas?',
                        isAllowedToCloseTickets: 'Permite fechar tickets?',
                    },
                },
            },
            companyModal: {
                title: {
                    add: 'Adicionar empresa',
                    edit: 'Editar empresa',
                },
                form: {
                    name: 'Nome',
                    email: 'Email',
                    passwordDefault: 'Senha',
                    numberAttendants: 'Usuários',
                    numberConections: 'Conexões',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'Empresa salvo com sucesso.',
            },
            scheduleModal: {
                title: {
                    add: 'Novo Agendamento',
                    edit: 'Editar Agendamento',
                },
                form: {
                    body: 'Mensagem',
                    contact: 'Contato',
                    sendAt: 'Data de Agendamento',
                    sentAt: 'Data de Envio',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'Agendamento salvo com sucesso.',
            },
            tagModal: {
                title: {
                    add: 'Nova Tag',
                    edit: 'Editar Tag',
                    addKanban: 'Nova Lane',
                    editKanban: 'Editar Lane',
                },
                form: {
                    name: 'Nome',
                    color: 'Cor',
                },
                buttons: {
                    okAdd: 'Adicionar',
                    okEdit: 'Salvar',
                    cancel: 'Cancelar',
                },
                success: 'Tag salvo com sucesso.',
                successKanban: 'Lane salva com sucesso.',
            },
            kanban: {
                title: 'Kanban',
                searchPlaceholder: 'Pesquisa',
                subMenus: {
                    list: 'Painel',
                    tags: 'Lanes',
                },
            },
            tagsKanban: {
                title: 'Lanes',
                laneDefault: 'Em aberto',
                confirmationModal: {
                    deleteTitle: 'Você tem certeza que quer excluir esta Lane?',
                    deleteMessage: 'Esta ação não pode ser revertida.',
                },
                table: {
                    name: 'Nome',
                    color: 'Cor',
                    tickets: 'Tickets',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Nova Lane',
                },
                toasts: {
                    deleted: 'Lane excluída com sucesso.',
                },
            },
            ratingModal: {
                title: {
                    add: 'Adicionar menu de avaliação',
                    edit: 'Editar menu de avaliação',
                },
                buttons: {
                    okAdd: 'Salvar',
                    okEdit: 'Editar',
                    cancel: 'Cancelar',
                    options: 'Adicionar opção',
                },
                form: {
                    name: 'Nome',
                    message: 'Mensagem da Avaliação',
                    options: 'Opções de Avaliação',
                    extraName: 'Nome da opção',
                    extraValue: 'Valor da opção',
                },
                success: 'Avaliação salva com sucesso!',
            },
            chat: {
                noTicketMessage: 'Selecione um ticket para começar a conversar.',
            },
            ticketsManager: {
                buttons: {
                    newTicket: 'Novo',
                },
            },
            ticketsQueueSelect: {
                placeholder: 'Filas',
            },
            tickets: {
                toasts: {
                    deleted: 'O atendimento que você estava foi deletado.',
                },
                notification: {
                    message: 'Mensagem de',
                },
                tabs: {
                    open: { title: 'Abertas' },
                    closed: { title: 'Resolvidos' },
                    search: { title: 'Busca' },
                },
                search: {
                    placeholder: 'Buscar atendimento e mensagens',
                },
                buttons: {
                    showAll: 'Todos',
                },
            },
            transferTicketModal: {
                title: 'Transferir Ticket',
                fieldLabel: 'Digite para buscar usuários',
                fieldQueueLabel: 'Transferir para fila',
                fieldQueuePlaceholder: 'Selecione uma fila',
                noOptions: 'Nenhum usuário encontrado com esse nome',
                buttons: {
                    ok: 'Transferir',
                    cancel: 'Cancelar',
                },
            },
            ticketsList: {
                pendingHeader: 'Aguardando',
                assignedHeader: 'Atendendo',
                noTicketsTitle: 'Nada aqui!',
                noTicketsMessage: 'Nenhum atendimento encontrado com esse status ou termo pesquisado',
                reopenTitle: 'Reabrir Ticket',
                buttons: {
                    accept: 'Aceitar',
                    reopen: 'Reabrir',
                    closed: 'Fechar',
                },
            },
            newTicketModal: {
                title: 'Criar Ticket',
                fieldLabel: 'Digite para pesquisar o contato',
                add: 'Adicionar',
                buttons: {
                    ok: 'Salvar',
                    cancel: 'Cancelar',
                },
            },
            mainDrawer: {
                listItems: {
                    dashboard: 'Dashboard',
                    connections: 'Conexões',
                    tickets: 'Tickets',
                    quickMessages: 'Respostas Rápidas',
                    contacts: 'Contatos',
                    queues: 'Filas & Chatbot',
                    tags: 'Tags',
                    administration: 'Administração',
                    companies: 'Empresas',
                    users: 'Usuários',
                    settings: 'Configurações',
                    ratings: 'Avaliação',
                    helps: 'Ajuda',
                    messagesAPI: 'API',
                    emailContacts: 'Contatos de Email',
                    emailFilter: 'Regras de Email',
                    aiPrompts: 'Prompts de IA',
                    schedules: 'Agendamentos',
                    campaigns: 'Campanhas',
                    annoucements: 'Informativos',
                    chats: 'Chat Interno',
                    financeiro: 'Financeiro',
                    documentTemplate: 'Modelos de Documentos',
                },
                appBar: {
                    user: {
                        profile: 'Perfil',
                        logout: 'Sair',
                    },
                    refresh: 'Atualizar',
                },
            },
            languages: {
                undefined: 'Idioma',
                'pt-BR': 'Português',
                es: 'Español',
                en: 'English',
                tr: 'Türkçe',
            },
            messagesAPI: {
                title: 'API',
                textMessage: {
                    number: 'Número',
                    body: 'Mensagem',
                    token: 'Token cadastrado',
                },
                mediaMessage: {
                    number: 'Número',
                    body: 'Nome do arquivo',
                    media: 'Arquivo',
                    token: 'Token cadastrado',
                },
            },
            notifications: {
                noTickets: 'Nenhuma notificação.',
            },
            quickMessages: {
                title: 'Respostas Rápidas',
                buttons: {
                    add: 'Nova Resposta',
                },
                dialog: {
                    shortcode: 'Atalho',
                    message: 'Resposta',
                },
            },
            contactLists: {
                title: 'Listas de Contatos',
                table: {
                    name: 'Nome',
                    contacts: 'Contatos',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Nova Lista',
                },
                dialog: {
                    name: 'Nome',
                    company: 'Empresa',
                    okEdit: 'Editar',
                    okAdd: 'Adicionar',
                    add: 'Adicionar',
                    edit: 'Editar',
                    cancel: 'Cancelar',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage: 'Esta ação não pode ser revertida.',
                },
                toasts: {
                    deleted: 'Registro excluído',
                },
            },
            contactListItems: {
                title: 'Contatos',
                searchPlaceholder: 'Pesquisa',
                buttons: {
                    add: 'Novo',
                    lists: 'Listas',
                    import: 'Importar',
                },
                dialog: {
                    name: 'Nome',
                    number: 'Número',
                    whatsapp: 'Whatsapp',
                    email: 'E-mail',
                    okEdit: 'Editar',
                    okAdd: 'Adicionar',
                    add: 'Adicionar',
                    edit: 'Editar',
                    cancel: 'Cancelar',
                },
                table: {
                    name: 'Nome',
                    number: 'Número',
                    whatsapp: 'Whatsapp',
                    email: 'E-mail',
                    actions: 'Ações',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage: 'Esta ação não pode ser revertida.',
                    importMessage: 'Deseja importar os contatos desta planilha? ',
                    importTitlte: 'Importar',
                },
                toasts: {
                    deleted: 'Registro excluído',
                },
            },
            campaigns: {
                title: 'Campanhas',
                searchPlaceholder: 'Pesquisa',
                buttons: {
                    add: 'Nova Campanha',
                    contactLists: 'Listas de Contatos',
                },
                table: {
                    name: 'Nome',
                    whatsapp: 'Conexão',
                    contactList: 'Lista de Contatos',
                    status: 'Status',
                    scheduledAt: 'Agendamento',
                    completedAt: 'Concluída',
                    confirmation: 'Confirmação',
                    actions: 'Ações',
                },
                dialog: {
                    new: 'Nova Campanha',
                    update: 'Editar Campanha',
                    readonly: 'Apenas Visualização',
                    form: {
                        name: 'Nome',
                        message1: 'Mensagem 1',
                        message2: 'Mensagem 2',
                        message3: 'Mensagem 3',
                        message4: 'Mensagem 4',
                        message5: 'Mensagem 5',
                        confirmationMessage1: 'Mensagem de Confirmação 1',
                        confirmationMessage2: 'Mensagem de Confirmação 2',
                        confirmationMessage3: 'Mensagem de Confirmação 3',
                        confirmationMessage4: 'Mensagem de Confirmação 4',
                        confirmationMessage5: 'Mensagem de Confirmação 5',
                        messagePlaceholder: 'Conteúdo da mensagem',
                        whatsapp: 'Conexão',
                        status: 'Status',
                        scheduledAt: 'Agendamento',
                        confirmation: 'Confirmação',
                        contactList: 'Lista de Contato',
                    },
                    buttons: {
                        add: 'Adicionar',
                        edit: 'Atualizar',
                        okadd: 'Ok',
                        cancel: 'Cancelar Disparos',
                        restart: 'Reiniciar Disparos',
                        close: 'Fechar',
                        attach: 'Anexar Arquivo',
                    },
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage: 'Esta ação não pode ser revertida.',
                },
                toasts: {
                    success: 'Operação realizada com sucesso',
                    cancel: 'Campanha cancelada',
                    restart: 'Campanha reiniciada',
                    deleted: 'Registro excluído',
                },
            },
            announcements: {
                title: 'Informativos',
                searchPlaceholder: 'Pesquisa',
                buttons: {
                    add: 'Novo Informativo',
                    contactLists: 'Listas de Informativos',
                },
                table: {
                    priority: 'Prioridade',
                    title: 'Título',
                    text: 'Texto',
                    mediaName: 'Arquivo',
                    status: 'Status',
                    actions: 'Ações',
                },
                dialog: {
                    edit: 'Edição de Informativo',
                    add: 'Novo Informativo',
                    update: 'Editar Informativo',
                    readonly: 'Apenas Visualização',
                    form: {
                        priority: 'Prioridade',
                        title: 'Título',
                        text: 'Texto',
                        mediaPath: 'Arquivo',
                        status: 'Status',
                    },
                    buttons: {
                        add: 'Adicionar',
                        edit: 'Atualizar',
                        okadd: 'Ok',
                        cancel: 'Cancelar',
                        close: 'Fechar',
                        attach: 'Anexar Arquivo',
                    },
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage: 'Esta ação não pode ser revertida.',
                },
                toasts: {
                    success: 'Operação realizada com sucesso',
                    deleted: 'Registro excluído',
                },
            },
            campaignsConfig: {
                title: 'Configurações de Campanhas',
            },
            queues: {
                title: 'Filas & Chatbot',
                table: {
                    name: 'Nome',
                    color: 'Cor',
                    greeting: 'Mensagem de saudação',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Adicionar fila',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage:
                        'Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.',
                },
            },
            queueSelect: {
                inputLabel: 'Filas',
                timeToMove: 'Após',
                moveQueue: 'Mover',
                timeToClose: 'Inatividade',
            },
            emailContact: {
                addSchedule: {
                    modal: {
                        toasts: {
                            success: 'Agendamento criado com sucesso',
                        },
                        title: 'Novo Agendamento',
                        form: {
                            date: 'Data',
                            time: 'Hora',
                            eventTitle: 'Título',
                            eventDescription: 'Descrição',
                            externalCalendars: 'Agendas',
                        },
                        buttons: {
                            okAdd: 'Salvar',
                            cancel: 'Cancelar',
                        },
                    },
                },
                listSchedules: {
                    toasts: {
                        deleted: 'Agendamento excluído com sucesso',
                    },
                    modal: {
                        title: 'Agendamentos',
                        futureSchedules: 'Futuros',
                        todaySchedules: 'Hoje',
                        deleteConfirmation: {
                            title: 'Você tem certeza que quer excluir este agendamento?',
                            message: 'Esta ação não pode ser revertida.',
                        },
                        buttons: {
                            close: 'Fechar',
                        },
                        table: {
                            datetime: 'Data e Hora',
                            contactName: 'Nome do Contato',
                            title: 'Título',
                            description: 'Descrição',
                            actions: 'Ações',
                            noSchedules: 'Nenhum agendamento encontrado',
                        },
                    },
                },
                addComment: {
                    modal: {
                        title: 'Novo Comentário',
                        form: {
                            quickMessage: 'Resposta Rápida',
                            comment: 'Comentário',
                            sendToContact: 'Notificar o Cliente?',
                            publicToContact: 'Deixar público? <em>(O cliente poderá visualizar)</em>',
                            aiSuggestion: 'Sugestão de IA',
                        },
                        buttons: {
                            addSchedule: 'Novo Agendamento',
                            listSchedules: 'Ver Agendamentos',
                            okAdd: 'Salvar',
                            cancel: 'Cancelar',
                        },
                    },
                },
                list: {
                    modal: {
                        buttons: {
                            close: 'Fechar',
                        },
                        filters: 'Filtros',
                        onlyUncommented: 'Apenas não comentados',
                    },
                    table: {
                        id: 'Código',
                        email: {
                            sender: 'Remetente',
                            subject: 'Assunto',
                            filter: 'Filtro',
                            messageDate: 'Data da Mensagem',
                            commented: 'Comentado',
                            sentToContact: 'Notificado',
                            sentToContactAt: 'Notificado em',
                            actions: {
                                header: 'Ações',
                                addComment: 'Comentar',
                            },
                            item: {
                                value: 'Citação',
                            },
                        },
                        empty: 'Nenhuma mensagem encontrada',
                    },
                },
                filter: {
                    modal: {
                        buttons: {
                            okAdd: 'Salvar',
                            cancel: 'Cancelar',
                        },
                        form: {
                            filter: 'Filtro',
                            filterAll: 'Todos',
                            category: 'Categoria',
                            value: 'Valor a filtrar',
                            verifyDaysAgo: 'Dias para verificar',
                            verifyDaysAgoHelper:
                                'Quantidade de dias atrás para verificar os emails já filtrados a partir de hoje (padrão 7 dias)',
                        },
                    },
                    list: {
                        title: 'Filtro de Email para Contato',
                        searchPlaceholder: 'Pesquisar...',
                        buttons: {
                            viewSchedules: 'Ver Agendamentos',
                            add: 'Novo',
                        },
                        table: {
                            id: 'Código',
                            name: 'Nome',
                            number: 'Número',
                            email: 'Email',
                            document: 'Documento',
                            filters: 'Filtros',
                            actions: 'Ações',
                        },
                        filter: {
                            category: {
                                name: {
                                    PROCESS_JUD: 'Processo',
                                    PROCESS_JUD_DIGITAL_CUT: 'Processo',
                                },
                            },
                        },
                    },
                },
            },
            document: {
                generator: {
                    form: {
                        title: 'Gerar Documento',
                        template: 'Modelo',
                        buttons: {
                            generate: 'Gerar',
                        },
                    },
                },
                template: {
                    confirmationModal: {
                        deleteTitle: 'Você tem certeza que quer excluir este modelo de documento?',
                        deleteMessage: 'Esta ação não pode ser revertida.',
                    },
                    toasts: {
                        deleteSuccess: 'Modelo de documento removido com sucesso',
                        addSuccess: 'Modelo de documento adicionado com sucesso',
                        editSuccess: 'Modelo de documento atualizado com sucesso',
                    },
                    list: {
                        title: 'Modelos de Documentos',
                        table: {
                            id: 'Código',
                            name: 'Nome',
                            fileNamePrefix: 'Prefixo do Arquivo',
                            actions: 'Ações',
                            empty: 'Nenhum modelo de documento encontrado',
                        },
                        buttons: {
                            add: 'Novo Modelo',
                        },
                    },
                    modal: {
                        title: {
                            add: 'Novo Modelo de Documento',
                            edit: 'Editar Modelo de Documento',
                        },
                        form: {
                            name: 'Nome',
                            fileNamePrefix: 'Prefixo do Arquivo',
                            file: 'Arquivo',
                        },
                        buttons: {
                            okAdd: 'Adicionar',
                            okEdit: 'Salvar',
                            cancel: 'Cancelar',
                        },
                    },
                },
            },
            emailFilter: {
                confirmationModal: {
                    deleteTitle: 'Você tem certeza que quer excluir este filtro?',
                    deleteMessage: 'Esta ação não pode ser revertida.',
                },
                toasts: {
                    deleteSuccess: 'Filtro removido com sucesso',
                    updateStatusSuccess: 'Status do filtro atualizado com sucesso',
                    syncSuccess: 'Filtro sincronizado com sucesso',
                },
                modal: {
                    title: {
                        add: 'Novo Filtro',
                        update: 'Atualizar Filtro',
                    },
                    form: {
                        name: 'Nome',
                        connection: 'Conexão',
                        rule: {
                            type: 'Tipo',
                            mode: 'Modo',
                            criteria: 'Critério',
                            buttons: {
                                add: 'Adicionar Regra',
                            },
                        },
                        success: 'Filtro salvo com sucesso.',
                    },
                    buttons: {
                        cancel: 'Cancelar',
                        okAdd: 'Adicionar',
                        okUpdate: 'Atualizar',
                    },
                },
                list: {
                    title: 'Fitros',
                    table: {
                        id: 'Código',
                        name: 'Nome',
                        hasNewEmail: 'Novos Emails',
                        status: 'Status',
                        lastSyncAt: 'Sincronizado em',
                        emailSettings: {
                            header: 'Conexão',
                            username: 'Usuário',
                            host: 'Host',
                        },
                        rules: 'Regras',
                        actions: 'Ações',
                        viewEmails: 'Ver Emails',
                        inactivate: 'Inativar',
                        activate: 'Ativar',
                        delete: 'Excluir',
                        edit: 'Editar',
                        sync: 'Sincronizar',
                    },
                    buttons: {
                        add: 'Novo Filtro',
                    },
                    status: {
                        ACTIVE: 'Ativo',
                        INACTIVE: 'Inativo',
                    },
                    rules: {
                        type: {
                            FROM: 'De',
                            SUBJECT: 'Assunto',
                        },
                    },
                },
            },
            users: {
                title: 'Usuários',
                table: {
                    id: 'Código',
                    status: 'Status',
                    name: 'Nome',
                    email: 'Email',
                    profile: 'Perfil',
                    whatsapp: 'Conexão Padrão',
                    startWork: 'Inicio de trabalho',
                    endWork: 'Fim de trabalho',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Adicionar usuário',
                },
                toasts: {
                    deleted: 'Usuário excluído com sucesso.',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage:
                        'Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.',
                },
            },
            compaies: {
                title: 'Empresas',
                table: {
                    status: 'Ativo',
                    name: 'Nome',
                    email: 'Email',
                    numberAttendants: 'Atendentes',
                    numberConections: 'Conexões',
                    value: 'Valor',
                    namePlan: 'Nome Plano',
                    numberQueues: 'Filas',
                    useCampaigns: 'Campanhas',
                    useExternalApi: 'Rest API',
                    useFacebook: 'Facebook',
                    useInstagram: 'Instagram',
                    useWhatsapp: 'Whatsapp',
                    useInternalChat: 'Chat Interno',
                    useSchedules: 'Agendamento',
                    createdAt: 'Criada Em',
                    dueDate: 'Vencimento',
                    lastLogin: 'Último Login',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Adicionar empresa',
                },
                toasts: {
                    deleted: 'Empresa excluído com sucesso.',
                },
                confirmationModal: {
                    deleteTitle: 'Excluir',
                    deleteMessage:
                        'Todos os dados da empresa serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.',
                },
            },
            helps: {
                title: 'Central de Ajuda',
            },
            schedules: {
                title: 'Agendamentos',
                confirmationModal: {
                    deleteTitle: 'Você tem certeza que quer excluir este Agendamento?',
                    deleteMessage: 'Esta ação não pode ser revertida.',
                },
                table: {
                    contact: 'Contato',
                    body: 'Mensagem',
                    sendAt: 'Data de Agendamento',
                    sentAt: 'Data de Envio',
                    status: 'Status',
                    actions: 'Ações',
                    repeatEvery: 'Repetições',
                    recorrenci: 'Envios',
                },
                buttons: {
                    add: 'Novo Agendamento',
                },
                toasts: {
                    deleted: 'Agendamento excluído com sucesso.',
                },
            },
            tags: {
                title: 'Tags',
                confirmationModal: {
                    deleteTitle: 'Você tem certeza que quer excluir esta Tag?',
                    deleteMessage: 'Esta ação não pode ser revertida.',
                },
                table: {
                    name: 'Nome',
                    color: 'Cor',
                    tickets: 'Registros Tags',
                    actions: 'Ações',
                },
                buttons: {
                    add: 'Nova Tag',
                },
                toasts: {
                    deleted: 'Tag excluído com sucesso.',
                },
            },
            ratings: {
                title: 'Avaliações',
                table: {
                    name: 'Nome',
                    contacts: 'Contatos',
                    actions: 'Ação',
                },
                toasts: {
                    deleted: 'Avaliação excluída com sucesso!',
                    deletedAll: 'Todas as avaliações foram excluídas com sucesso!',
                },
                buttons: {
                    add: 'Adicionar',
                    deleteAll: 'Deletar Todos',
                },
                confirmationModal: {
                    deleteTitle: 'Deletar ',
                    deleteAllTitle: 'Deletar Todos',
                    deleteMessage: 'Tem certeza que deseja deletar esta avaliação?',
                    deleteAllMessage: 'Tem certeza que deseja deletar todas as avaliações?',
                },
            },
            settings: {
                success: 'Configurações salvas com sucesso.',
                title: 'Configurações',
                settings: {
                    userCreation: {
                        name: 'Criação de usuário',
                        options: {
                            enabled: 'Ativado',
                            disabled: 'Desativado',
                        },
                    },
                },
            },
            messagesList: {
                header: {
                    assignedTo: 'Assinado à:',
                    dialogRatingTitle: 'Deseja deixar uma avaliação de atendimento para o cliente?',
                    dialogClosingTitle: 'Finalizando o atendimento com o cliente!',
                    dialogRatingCancel: 'Resolver COM Mensagem de Despedida',
                    dialogRatingSuccess: 'Resolver e Enviar Avaliação',
                    dialogRatingWithoutFarewellMsg: 'Resolver SEM Mensagem de Despedida',
                    ratingTitle: 'Escolha um menu de avaliação',
                    buttons: {
                        return: 'Retornar',
                        resolve: 'Resolver',
                        reopen: 'Reabrir',
                        accept: 'Aceitar',
                        rating: 'Enviar Avaliação',
                    },
                },
                others: {
                    editedMessage: 'Editada',
                },
            },
            messagesInput: {
                placeholderOpen: 'Digite uma mensagem',
                placeholderNotepad: 'Digite uma nota rápida',
                placeholderClosed: 'Reabra ou aceite esse ticket para enviar uma mensagem.',
                signMessage: 'Assinar',
            },
            messageVariablesPicker: {
                label: 'Variavéis disponíveis',
                vars: {
                    contactFirstName: 'Primeiro Nome',
                    contactName: 'Nome',
                    user: 'Atendente',
                    greeting: 'Saudação',
                    protocolNumber: 'Protocolo',
                    date: 'Data',
                    hour: 'Hora',
                    ticket_id: 'Nº do Chamado',
                    queue: 'Setor',
                    connection: 'Conexão',
                    filter: 'Filtro',
                },
            },
            contactDrawer: {
                header: 'Dados do contato',
                buttons: {
                    edit: 'Editar contato',
                },
                extraInfo: 'Outras informações',
            },
            ticketOptionsMenu: {
                schedule: 'Agendamento',
                delete: 'Deletar',
                transfer: 'Transferir',
                registerAppointment: 'Observações do Contato',
                resolveWithNoFarewell: 'Finalizar sem despedida',
                acceptAudioMessage: 'Aceitar áudios do contato?',
                appointmentsModal: {
                    title: 'Observações do Contato',
                    textarea: 'Observação',
                    placeholder: 'Insira aqui a informação que deseja registrar',
                },
                confirmationModal: {
                    title: 'Deletar o ticket do contato',
                    message: 'Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.',
                },
                buttons: {
                    delete: 'Excluir',
                    cancel: 'Cancelar',
                },
            },
            confirmationModal: {
                buttons: {
                    confirm: 'Ok',
                    cancel: 'Cancelar',
                },
            },
            notConnectionModal: {
                title: "Conexão perdida no atendimento",
                message: "Para continuar este atendimento, selecione uma nova conexão ou finalize o atendimento atual.",
                selectConnection: "Escolher nova conexão",
                buttons: {
                    closeTicket: "Encerrar atendimento",
                    changeConnection: "Salvar nova conexão",
                    cancel: "Cancelar",
                },
            },
            deleteConnectionModal: {
                title: "Remover Conexão",
                deleteMessage: "Esta ação não pode ser desfeita",
                message: "Ao remover esta conexão, todos os atendimentos associados serão permanentemente excluídos. Por favor, escolha entre migrar os atendimentos para uma nova conexão ou excluir todos os atendimentos.",
                selectConnection: "Selecionar Nova Conexão",
                count: "Total de atendimentos na conexão:",
                buttons: {
                    delete: "Excluir conexão",
                    closeTicket: "Excluir Atendimentos",
                    changeConnection: "Migrar e Excluir",
                    cancel: "Cancelar",
                },
            },
            supportTokenModal: {
                buttons: {
                    cancel: 'Cancelar e entrar com outro usuário',
                },
            },
            messageOptionsMenu: {
                edit: 'Editar',
                delete: 'Deletar',
                reply: 'Responder',
                forward: 'Encaminhar',
                toForward: 'Encaminhar',
                talkTo: 'Conversar Com',
                confirmationModal: {
                    title: 'Apagar mensagem?',
                    message: 'Esta ação não pode ser revertida.',
                },
            },
            quickemessage: {
                toasts: {
                    success: 'Atalho adicionado com sucesso!',
                    deleted: 'Atalho removido com sucesso!',
                    disabledVar: 'Mensagem reformatada para texto puro, pois contém variáveis desabilitadas.',
                },
                title: 'Respostas Rápidas',
                buttons: {
                    add: 'Nova Resposta',
                },
                dialog: {
                    add: 'Adicionar',
                    edit: 'Editar',
                    form: {
                        shortcode: 'Atalho',
                        message: 'Mensagem',
                        additionalMessage: 'Mensagem Complementar',
                        geral: 'Global',
                        type: 'Tipo',
                    },
                    buttons: {
                        cancel: 'Cancelar',
                        edit: 'Salvar',
                        attach: 'Anexar Arquivo',
                        add: 'Salvar',
                    },
                },
                confirmationModal: {
                    deleteTitle: 'Exclusão',
                    deleteMessage: 'Esta ação é irreversível! Deseja prosseguir?',
                },
            },
            quickemessages: {
                toasts: {
                    success: 'Atalho adicionado com sucesso!',
                    deleted: 'Atalho removido com sucesso!',
                },
                title: 'Respostas Rápidas',
                table: {
                    shortcode: 'Atalho',
                    type: {
                        header: 'Tipo',
                        COMMON: 'Comum',
                        EMAIL_FILTER_COMMENT: 'Filtro de Email',
                    },
                    mediaName: 'Arquivo',
                    status: 'Global',
                    actions: 'Ação',
                },
                searchPlaceholder: 'Procurar',
                buttons: {
                    add: 'Adicionar',
                    attach: 'Anexar Arquivo',
                    cancel: 'Cancelar',
                    edit: 'Salvar',
                },
                confirmationModal: {
                    deleteTitle: 'Exclusão',
                    deleteMessage: 'Esta ação é irreversível! Deseja prosseguir?',
                },
            },
            usersFilter: {
                placeholder: 'Filtro de usuários',
            },
            ai: {
                prompt: {
                    title: 'Prompts de IA',
                    table: {
                        type: 'Tipo',
                        target: 'Uso',
                        modelName: 'Modelo',
                        actions: 'Ações',
                    },
                    modal: {
                        title: {
                            edit: 'Editar Prompt',
                        },
                        form: {
                            type: 'Tipo',
                            modelName: 'Modelo',
                            target: 'Uso',
                            content: 'Prompt',
                        },
                        toasts: {
                            success: 'Prompt salvo com sucesso.',
                        },
                        buttons: {
                            okEdit: 'Salvar',
                            cancel: 'Cancelar',
                        },
                    },
                    enums: {
                        EMAIL_FILTER_COMMENT: 'Comentário de Filtro de Email',
                        JUD_PROCESS: 'Processos Judiciais',
                    },
                },
            },
            backendErrors: {
                ERR_NO_OTHER_WHATSAPP: 'Deve haver pelo menos um WhatsApp padrão.',
                ERR_NO_DEF_WAPP_FOUND: 'Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.',
                ERR_WAPP_NOT_INITIALIZED:
                    'Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.',
                ERR_WAPP_CHECK_CONTACT:
                    'Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões',
                ERR_WAPP_INVALID_CONTACT: 'Este não é um número de Whatsapp válido.',
                ERR_WAPP_DOWNLOAD_MEDIA: 'Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.',
                ERR_INVALID_CREDENTIALS: 'Erro de autenticação. Por favor, tente novamente.',
                ERR_SENDING_WAPP_MSG: 'Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.',
                ERR_DELETE_WAPP_MSG: 'Não foi possível excluir a mensagem do WhatsApp.',
                ERR_OTHER_OPEN_TICKET: 'Já existe um tíquete aberto para este contato.',
                ERR_SESSION_EXPIRED: 'Sessão expirada. Por favor entre.',
                ERR_USER_CREATION_DISABLED: 'A criação do usuário foi desabilitada pelo administrador.',
                ERR_NO_PERMISSION: 'Você não tem permissão para acessar este recurso.',
                ERR_DUPLICATED_CONTACT: 'Já existe um contato com este número.',
                ERR_NO_SETTING_FOUND: 'Nenhuma configuração encontrada com este ID.',
                ERR_NO_CONTACT_FOUND: 'Nenhum contato encontrado com este ID.',
                ERR_NO_TICKET_FOUND: 'Nenhum tíquete encontrado com este ID.',
                ERR_NO_USER_FOUND: 'Nenhum usuário encontrado com este ID.',
                ERR_NO_WAPP_FOUND: 'Nenhum WhatsApp encontrado com este ID.',
                ERR_CREATING_MESSAGE: 'Erro ao criar mensagem no banco de dados.',
                ERR_CREATING_TICKET: 'Erro ao criar tíquete no banco de dados.',
                ERR_FETCH_WAPP_MSG: 'Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.',
                ERR_QUEUE_COLOR_ALREADY_EXISTS: 'Esta cor já está em uso, escolha outra.',
                ERR_WAPP_GREETING_REQUIRED: 'A mensagem de saudação é obrigatório quando há mais de uma fila.',
                ERR_OUT_OF_HOURS: 'Fora do Horário de Expediente!',
                ERR_NO_EMAIL_CONTACT_FILTER_FOUND: 'Nenhum filtro de email encontrado com este ID.',
                ERR_MAX_ATTEMPTS_LOGIN: "Você excedeu o limite de tentativas de acesso. Por favor, tente novamente mais tarde.",
                ERR_EMAIL_CONTACT_FILTER_HAS_FILLED_FILTERS:
                    'Não é possível excluir um filtro de email que possui filtros preenchidos.',
                ERR_EMAIL_SETTINGS_HAS_FILTERS: 'Não é possível excluir uma conexão que já possui filtros de email.',
                ERR_EMAIL_FILTER_HAS_CONTACT_EMAILS:
                    'Não é possível excluir um filtro que possui algum email de um contato já filtrado',
                ERR_EMAIL_FILTER_MUST_HAVE_AT_LEAST_ONE_RULE: 'Um filtro de email deve ter ao menos uma regra',
            },
        },
    },
};

export { messages };
