import {
    Button,
    Chip,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { DeleteOutline, Edit, People } from '@material-ui/icons';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Calendar } from '../../components/(molecules)/Calendar';
import ConfirmationModal from '../../components/ConfirmationModal';
import ConnectionIcon from '../../components/ConnectionIcon';
import ExternalCalendarUpdateCalendarNameModal from '../../components/ExternalCalendar/CalendarNameUpdateModal';
import ExternalCalendarUserPermissionModal from '../../components/ExternalCalendar/UserPermissionModal';
import toastError from '../../errors/toastError';
import { useDate } from '../../hooks/useDate';
import useExternalCalendar from '../../hooks/useExternalCalendar';
import useGoogle from '../../hooks/useGoogle';
import { i18n } from '../../translate/i18n';
import ConnectionListContainer from './ConnectionListContainer';

const Users = ({ users }) => {
    return (
        <div style={{ display: 'flex', gap: '5px' }}>
            {users.map((user, index) => (
                <Chip key={index} label={user.name} size="small" />
            ))}
        </div>
    );
};

const ExternalCalendarsList = () => {
    const { getAuthUrl } = useGoogle();
    const { list, updateUsers, remove, updateCalendarId } = useExternalCalendar();
    const { datetimeToClient } = useDate();
    const [selected, setSelected] = useState(null);
    const [externalCalendars, setExternalCalendars] = useState([]);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [userPermissionModalOpen, setUserPermissionModalOpen] = useState(false);
    const [calendarNameUpdateModalOpen, setCalendarNameUpdateModalOpen] = useState(false);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = () => {
        list().then((data) => setExternalCalendars(data));
    };

    const handleGoogleCalendarClick = async () => {
        try {
            const url = await getAuthUrl('calendar');
            window.open(url, '_self');
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenUpdateCalendarNameModal = (externalCalendar) => {
        setSelected(externalCalendar);
        setCalendarNameUpdateModalOpen(true);
    };

    const handleCloseUpdateCalendarNameModal = async () => {
        setSelected(null);
        setCalendarNameUpdateModalOpen(false);
        fetchData();
    };

    const handleOpenUserPermissionModal = (externalCalendar) => {
        setSelected(externalCalendar);
        setUserPermissionModalOpen(true);
    };

    const handleCloseUserPermissionModal = async () => {
        setSelected(null);
        setUserPermissionModalOpen(false);
        fetchData();
    };

    const handleCalendarNameUpdateSuccess = async (calendarId) => {
        try {
            await updateCalendarId(selected.id, calendarId);
            setCalendarNameUpdateModalOpen(false);
            fetchData();
        } catch (err) {
            toastError(err);
        }
    };

    const handleSubmitConfirmationModal = async () => {
        try {
            await remove(selected.id);
            setConfirmModalOpen(false);
            fetchData();
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenConfirmationModal = (externalCalendar) => {
        setSelected(externalCalendar);
        setConfirmModalOpen(true);
    };

    const handleUserPermissionSuccess = async (users) => {
        try {
            await updateUsers(
                selected.id,
                users.map((u) => u.id)
            );
            fetchData();
            toast.success(i18n.t('connections.externalCalendar.toasts.userPermissionUpdated'));
        } catch (err) {
            toastError(err);
        }
    };

    const NewConnection = () => {
        return (
            <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                    <>
                        <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                            {i18n.t('connections.externalCalendar.addNew')}
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem onClick={handleGoogleCalendarClick}>
                                <ConnectionIcon connectionType="google" style={{ marginRight: '10px' }} />
                                Google Calendar
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </PopupState>
        );
    };
    return (
        <>
            <ConnectionListContainer
                title={i18n.t('connections.externalCalendar.title')}
                actionName={i18n.t('connections.externalCalendar.addNew')}
                otherActions={<NewConnection />}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t('connections.externalCalendar.channel')}</TableCell>
                            <TableCell>{i18n.t('connections.externalCalendar.username')}</TableCell>
                            <TableCell>{i18n.t('connections.externalCalendar.calendar')}</TableCell>
                            <TableCell>{i18n.t('connections.externalCalendar.users.header')}</TableCell>
                            <TableCell align="right">{i18n.t('connections.externalCalendar.createdAt')}</TableCell>
                            <TableCell width="150" align="center">
                                {i18n.t('connections.externalCalendar.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {externalCalendars.length > 0 ? (
                            externalCalendars.map((externalCalendar) => (
                                <TableRow key={externalCalendar.id}>
                                    <TableCell align="center">
                                        <ConnectionIcon connectionType={externalCalendar.type} />
                                    </TableCell>
                                    <TableCell>{externalCalendar.username}</TableCell>
                                    <TableCell>
                                        <Calendar
                                            calendar={{
                                                id: externalCalendar.calendarId,
                                                summary: externalCalendar.calendarSummary,
                                                backgroundColor: externalCalendar.calendarBackgroundColor,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {!externalCalendar.users || externalCalendar.users?.length <= 0 ? (
                                            i18n.t('connections.externalCalendar.users.all')
                                        ) : (
                                            <Users users={externalCalendar.users} />
                                        )}
                                    </TableCell>
                                    <TableCell align="right">{datetimeToClient(externalCalendar.createdAt)}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleOpenUpdateCalendarNameModal(externalCalendar)}
                                        >
                                            <Edit />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleOpenUserPermissionModal(externalCalendar)}
                                        >
                                            <People />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={() => handleOpenConfirmationModal(externalCalendar)}
                                        >
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={5}>{i18n.t('connections.noConnections')}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </ConnectionListContainer>

            <ConfirmationModal
                title={i18n.t('connections.externalCalendar.confirmationModal.deleteTitle')}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={handleSubmitConfirmationModal}
            >
                {i18n.t('connections.externalCalendar.confirmationModal.deleteMessage')}
            </ConfirmationModal>

            {userPermissionModalOpen && (
                <ExternalCalendarUserPermissionModal
                    open
                    connection={selected}
                    onClose={handleCloseUserPermissionModal}
                    onSuccess={handleUserPermissionSuccess}
                />
            )}

            {calendarNameUpdateModalOpen && selected && (
                <ExternalCalendarUpdateCalendarNameModal
                    open
                    connection={selected}
                    onClose={handleCloseUpdateCalendarNameModal}
                    onSuccess={handleCalendarNameUpdateSuccess}
                />
            )}
        </>
    );
};

export default ExternalCalendarsList;
