import React, { useEffect, useRef, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, makeStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import toastError from '../../errors/toastError';
import useAI from '../../hooks/useAI';
import { i18n } from '../../translate/i18n';

const AIPromptSchema = Yup.object().shape({
    content: Yup.string().required('Campo obrigatório'),
});

const initialState = {
    content: '',
};

const useStyle = makeStyles((theme) => ({
    formContainer: {
        width: '100%',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        '&> div': {
            height: '70dvh',
            alignItems: 'flex-start',
        },
    },
}));

const AIPromptModal = ({ open, onClose, promptId }) => {
    const classes = useStyle();
    const [prompt, setPrompt] = useState(initialState);
    const { getPrompt, update } = useAI();

    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (promptId) {
            (async function fetchPrompt() {
                try {
                    const prompt = await getPrompt(promptId);
                    setPrompt(prompt);
                } catch (err) {
                    toastError(err);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promptId]);

    const handleSavePrompt = async (prompt) => {
        try {
            await update({ id: promptId, content: prompt.content });
            toast.success(i18n.t('ai.prompt.modal.toasts.success'));
            onClose();
        } catch (err) {
            toastError(err);
        }
    };

    const handleClose = () => {
        onClose();
        setPrompt(initialState);
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">
            <DialogTitle>{i18n.t('ai.prompt.modal.title.edit')}</DialogTitle>
            <Formik
                initialValues={prompt}
                validationSchema={AIPromptSchema}
                enableReinitialize
                onSubmit={(values, actions) => {
                    setTimeout(() => {
                        handleSavePrompt(values);
                        actions.setSubmitting(false);
                    }, 400);
                }}
            >
                {({ values, touched, errors, isSubmitting, isValid }) => (
                    <Form className={classes.formContainer}>
                        <DialogContent dividers className={classes.dialogContent}>
                            <Field
                                as={TextField}
                                multiline
                                name="content"
                                inputRef={textareaRef}
                                label={i18n.t('ai.prompt.modal.form.content')}
                                placeholder={i18n.t('ai.prompt.modal.form.content')}
                                error={touched.comment && Boolean(errors.comment)}
                                helperText={touched.comment && errors.comment}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                className={classes.content}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                                {i18n.t('ai.prompt.modal.buttons.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting || !isValid || prompt.content === values.content}
                                variant="contained"
                            >
                                {i18n.t('ai.prompt.modal.buttons.okEdit')}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default AIPromptModal;
