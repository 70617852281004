import React, { useEffect, useState } from 'react';

import {
    Button,
    Chip,
    FormControlLabel,
    InputAdornment,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    useTheme,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import toastError from '@App/errors/toastError';
import { formatDocument } from '@App/helpers/documentHelper';
import useEmailContactFilter from '@App/hooks/useEmailContactFilter';
import { i18n } from '@App/translate/i18n';
import ConfirmationModal from '@Components/ConfirmationModal';
import EmailContactFilterModal from '@Components/EmailContactFilterModal';
import EmailContactModal from '@Components/EmailContactModals/EmailContact';
import ListSchedules from '@Components/EmailContactModals/ListSchedules';
import MainContainer from '@Components/MainContainer';
import MainHeader from '@Components/MainHeader';
import MainHeaderButtonsWrapper from '@Components/MainHeaderButtonsWrapper';
import TableRowSkeleton from '@Components/TableRowSkeleton';
import Title from '@Components/Title';
import { TagsContainer } from '../EmailFilter/style';
import { FilterContainer, MiniBadge } from './style';

const EmailContactFilter = () => {
    const theme = useTheme();
    const { list, deleteFilter } = useEmailContactFilter();
    const [loading, setLoading] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [onlyUncommented, setOnlyUncommented] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [selectedContact, setSelectedContact] = useState(null);
    const [selecedFilterToDelete, setSelecedFilterToDelete] = useState(null);
    const [openOnDeleteFilterDialog, setOpenOnDeleteFilterDialog] = useState(false);
    const [openEmailContactModal, setOpenEmailContactModal] = useState(false);
    const [openEmailContactFilterModal, setOpenEmailContactFilterModal] = useState(false);
    const [openSchedulesModal, setOpenSchedulesModal] = useState(false);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            list({ searchParam, onlyUncommented }).then((data) => {
                setContacts(data);
                setLoading(false);
            });
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, onlyUncommented]);

    const fetchContacts = async () => {
        setLoading(true);
        try {
            setContacts(await list());
        } catch (err) {
            toastError(err);
        }
        setLoading(false);
    };

    const handleCloseFilterModal = async () => {
        setOpenEmailContactFilterModal(false);
        setSelectedContact(null);
        fetchContacts();
    };

    const handleOpenEmailContactModal = (contact) => () => {
        setSelectedContact(contact);
        setOpenEmailContactModal(true);
    };

    const handleCloseContactModal = () => {
        setOpenEmailContactModal(false);
        setSelectedContact(null);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleDeleteFilter = async () => {
        try {
            await deleteFilter(selecedFilterToDelete.id);
            setSelecedFilterToDelete(null);
            await fetchContacts();
        } catch (err) {
            toastError(err, 5_000);
        }
    };

    return (
        <MainContainer>
            {openEmailContactFilterModal && (
                <EmailContactFilterModal
                    open={true}
                    onClose={handleCloseFilterModal}
                    emailContactFilterId={selectedContact?.id}
                />
            )}
            {openEmailContactModal && selectedContact && (
                <EmailContactModal open={true} onClose={handleCloseContactModal} contact={selectedContact} />
            )}
            {openOnDeleteFilterDialog && selecedFilterToDelete && (
                <ConfirmationModal
                    title="Excluir Filtro"
                    open={true}
                    onClose={() => {
                        setOpenOnDeleteFilterDialog(false);
                        setSelecedFilterToDelete(null);
                    }}
                    onConfirm={handleDeleteFilter}
                >
                    Deseja realmente excluir este filtro? <br /> <strong>{selecedFilterToDelete.value}</strong>
                </ConfirmationModal>
            )}
            {openSchedulesModal && <ListSchedules open={true} onClose={() => setOpenSchedulesModal(false)} showName />}
            <MainHeader>
                <Title>
                    {i18n.t('emailContact.filter.list.title')} ({contacts.length})
                </Title>
                <MainHeaderButtonsWrapper>
                    <FormControlLabel
                        control={<Switch color="primary" name="publicToContact" checked={onlyUncommented} />}
                        onChange={(e) => setOnlyUncommented(e.target.checked)}
                        labelPlacement="start"
                        style={{ color: theme.mode === 'dark' ? 'white' : theme.palette.colorPrimary }}
                        label={i18n.t('emailContact.list.modal.onlyUncommented')}
                    />
                    <TextField
                        placeholder={i18n.t('emailContact.filter.list.searchPlaceholder')}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="secondary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button variant="outlined" color="primary" onClick={() => setOpenSchedulesModal(true)}>
                        {i18n.t('emailContact.filter.list.buttons.viewSchedules')}
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => setOpenEmailContactFilterModal(true)}>
                        {i18n.t('emailContact.filter.list.buttons.add')}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper variant="outlined" style={{ maxHeight: '80dvh', overflowY: 'auto' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t('emailContact.filter.list.table.id')}</TableCell>
                            <TableCell>{i18n.t('emailContact.filter.list.table.name')}</TableCell>
                            <TableCell>{i18n.t('emailContact.filter.list.table.number')}</TableCell>
                            <TableCell>{i18n.t('emailContact.filter.list.table.email')}</TableCell>
                            <TableCell>{i18n.t('emailContact.filter.list.table.document')}</TableCell>
                            <TableCell>{i18n.t('emailContact.filter.list.table.filters')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[...contacts]
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((emailContact) => (
                                <TableRow
                                    key={emailContact.id}
                                    hover
                                    style={{ cursor: 'pointer' }}
                                    onClick={handleOpenEmailContactModal(emailContact)}
                                >
                                    <TableCell align="center">{emailContact.id}</TableCell>
                                    <TableCell>{emailContact.name}</TableCell>
                                    <TableCell>{emailContact.number}</TableCell>
                                    <TableCell>{emailContact.email}</TableCell>
                                    <TableCell>{formatDocument(emailContact.document)}</TableCell>
                                    <TableCell>
                                        <TagsContainer>
                                            {emailContact.emailContactFilters?.map((emailContactFilter) => (
                                                <Chip
                                                    key={emailContactFilter.id}
                                                    onDelete={() => {
                                                        setSelecedFilterToDelete(emailContactFilter);
                                                        setOpenOnDeleteFilterDialog(true);
                                                    }}
                                                    label={
                                                        <FilterContainer>
                                                            {emailContactFilter.qtyToComment > 0 && (
                                                                <MiniBadge>{emailContactFilter.qtyToComment}</MiniBadge>
                                                            )}
                                                            <span>
                                                                <strong>
                                                                    {i18n.t(
                                                                        `emailContact.filter.list.filter.category.name.${emailContactFilter.category.name}`
                                                                    )}
                                                                </strong>
                                                                : {emailContactFilter.value}
                                                            </span>
                                                        </FilterContainer>
                                                    }
                                                />
                                            ))}
                                        </TagsContainer>
                                    </TableCell>
                                </TableRow>
                            ))}
                        {loading && <TableRowSkeleton columns={6} />}
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default EmailContactFilter;
