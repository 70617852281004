import { Box, Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';

export function UsersFilter({ onFiltered, initialUsers }) {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await loadUsers();
        }
        fetchData();
    }, []);

    useEffect(() => {
        setSelectedUsers([]);
        if (Array.isArray(initialUsers) && Array.isArray(users) && users.length > 0) {
            onChange(initialUsers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialUsers, users]);

    const loadUsers = async () => {
        try {
            const { data } = await api.get(`/users/list`);
            const userList = data.map((u) => ({ id: u.id, name: u.name }));
            setUsers(userList);
        } catch (err) {
            toastError(err);
        }
    };

    const onChange = async (value) => {
        setSelectedUsers(value);
        onFiltered(value);
    };

    return (
        <Box style={{ padding: '0px 10px 10px' }}>
            <Autocomplete
                multiple
                size="small"
                options={users}
                value={selectedUsers}
                onChange={(e, v, r) => onChange(v)}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => {
                    return option?.id === value?.id || option?.name.toLowerCase() === value?.name.toLowerCase();
                }}
                renderTags={(value, getUserProps) =>
                    value.map((option, index) => (
                        <Chip variant="default" label={option.name} {...getUserProps({ index })} size="small" />
                    ))
                }
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        placeholder={!selectedUsers || selectedUsers.length <= 0 ? i18n.t('usersFilter.placeholder') : ''}
                    />
                )}
            />
        </Box>
    );
}
