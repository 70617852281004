/**
 * Gera uma senha de 8 caracteres aleatórios, incluindo letras maiúsculas, minúsculas e números.
 * @returns {string} 
 */
function generatePassword() {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let senha = '';

    for (let i = 0; i < 8; i++) {
        const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
        senha += caracteres.charAt(indiceAleatorio);
    }

    return senha;
}

export default generatePassword;
