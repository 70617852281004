import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutline, Edit, Email, Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/ConfirmationModal';
import EmailSettingsModal from '../../components/EmailSettingsModal';
import toastError from '../../errors/toastError';
import useEmailSettings from '../../hooks/useEmailSettings';
import { i18n } from '../../translate/i18n';
import ConnectionListContainer from './ConnectionListContainer';

const useStyle = makeStyles((theme) => ({
    password: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));

const EmailSettingsList = () => {
    const classes = useStyle();
    const { list, remove } = useEmailSettings();
    const [emailSettingsList, setEmailSettingsList] = useState([]);
    const [selected, setSelected] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setEmailSettingsList(await list());
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOpenModal = (emailSettings) => {
        setSelected(emailSettings);
        setModalOpen(true);
    };

    const handleCloseModal = useCallback(() => {
        setModalOpen(false);
        setSelected(null);
    }, [setSelected, setModalOpen]);

    const handleSubmitConfirmationModal = async () => {
        try {
            await remove(selected.id);
            setEmailSettingsList(await list());
            toast.success(i18n.t('connections.toasts.deleted'));
        } catch (err) {
            toastError(err, 4000);
        }
    };

    const Password = ({ value }) => {
        const [show, setShow] = useState(false);

        return (
            <Typography className={classes.password}>
                <IconButton size="small" onClick={() => setShow(!show)}>
                    {show ? <Visibility fontSize="inherit" /> : <VisibilityOff fontSize="inherit" />}
                </IconButton>
                {show ? value : '********'}
            </Typography>
        );
    };

    return (
        <>
            <ConnectionListContainer
                title={i18n.t('connections.emailSettings.title')}
                actionName={i18n.t('connections.emailSettings.addNew')}
                onAddNew={() => handleOpenModal(null)}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n.t('connections.emailSettings.type')}</TableCell>
                            <TableCell>{i18n.t('connections.emailSettings.username')}</TableCell>
                            <TableCell width="250">{i18n.t('connections.emailSettings.password')}</TableCell>
                            <TableCell>{i18n.t('connections.emailSettings.host')}</TableCell>
                            <TableCell>{i18n.t('connections.emailSettings.port')}</TableCell>
                            <TableCell>{i18n.t('connections.emailSettings.tls')}</TableCell>
                            <TableCell width="100" align="center">
                                {i18n.t('connections.emailSettings.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {emailSettingsList.map((emailSetting) => (
                            <TableRow key={emailSetting.id}>
                                <TableCell>
                                    <Email />
                                </TableCell>
                                <TableCell>{emailSetting.username}</TableCell>
                                <TableCell>
                                    <Password value={emailSetting.password} />
                                </TableCell>
                                <TableCell>{emailSetting.host}</TableCell>
                                <TableCell>{emailSetting.port}</TableCell>
                                <TableCell>{emailSetting.tls ? 'Sim' : 'Não'}</TableCell>
                                <TableCell align="center">
                                    <IconButton size="small" onClick={() => handleOpenModal(emailSetting)}>
                                        <Edit />
                                    </IconButton>
                                    <IconButton size="small" onClick={() => setConfirmModalOpen(true)}>
                                        <DeleteOutline />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </ConnectionListContainer>
            <ConfirmationModal
                title={i18n.t('connections.confirmationModal.disconnectTitle')}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={handleSubmitConfirmationModal}
            >
                {i18n.t('connections.confirmationModal.disconnectMessage')}
            </ConfirmationModal>
            <EmailSettingsModal open={modalOpen} onClose={handleCloseModal} emailSettingsId={selected?.id} />
        </>
    );
};

export default EmailSettingsList;
