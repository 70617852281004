import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import Title from '../../components/Title';

import { Grid } from '@material-ui/core';
import { isArray } from 'lodash';
import AnnouncementModal from '../../components/AnnouncementModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import socket from '../../hooks/useSocket';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';

const reducer = (state, action) => {
    if (action.type === 'LOAD_ANNOUNCEMENTS') {
        const announcements = action.payload;
        const newAnnouncements = [];

        if (isArray(announcements)) {
            announcements.forEach((announcement) => {
                const announcementIndex = state.findIndex((u) => u.id === announcement.id);
                if (announcementIndex !== -1) {
                    state[announcementIndex] = announcement;
                } else {
                    newAnnouncements.push(announcement);
                }
            });
        }

        return [...state, ...newAnnouncements];
    }

    if (action.type === 'UPDATE_ANNOUNCEMENTS') {
        const announcement = action.payload;
        const announcementIndex = state.findIndex((u) => u.id === announcement.id);

        if (announcementIndex !== -1) {
            state[announcementIndex] = announcement;
            return [...state];
        } else {
            return [announcement, ...state];
        }
    }

    if (action.type === 'DELETE_ANNOUNCEMENT') {
        const announcementId = action.payload;

        const announcementIndex = state.findIndex((u) => u.id === announcementId);
        if (announcementIndex !== -1) {
            state.splice(announcementIndex, 1);
        }
        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        // padding: theme.spacing(1),
        padding: theme.padding,
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}));

const Announcements = () => {
    const classes = useStyles();
    const history = useHistory();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [deletingAnnouncement, setDeletingAnnouncement] = useState(null);
    const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [announcements, dispatch] = useReducer(reducer, []);

    // trava para nao acessar pagina que não pode
    useEffect(() => {
        async function fetchData() {
            if (!user.super) {
                toast.error('Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando.');
                setTimeout(() => {
                    history.push(`/`);
                }, 1000);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            fetchAnnouncements();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');

        socket.on(`company-announcement`, (data) => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_ANNOUNCEMENTS', payload: data.record });
            }
            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_ANNOUNCEMENT', payload: +data.id });
            }
        });
        return () => { };
    }, []);

    const fetchAnnouncements = async () => {
        try {
            const { data } = await api.get('/announcements/', {
                params: { searchParam, pageNumber },
            });
            dispatch({ type: 'LOAD_ANNOUNCEMENTS', payload: data.records });
            setHasMore(data.hasMore);
            setLoading(false);
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenAnnouncementModal = () => {
        setSelectedAnnouncement(null);
        setAnnouncementModalOpen(true);
    };

    const handleCloseAnnouncementModal = () => {
        setSelectedAnnouncement(null);
        setAnnouncementModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditAnnouncement = (announcement) => {
        setSelectedAnnouncement(announcement);
        setAnnouncementModalOpen(true);
    };

    const handleDeleteAnnouncement = async (announcementId) => {
        try {
            await api.delete(`/announcements/${announcementId}`);
            toast.success(i18n.t('announcements.toasts.deleted'));
        } catch (err) {
            toastError(err);
        }
        setDeletingAnnouncement(null);
        setSearchParam('');
        setPageNumber(1);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const translatePriority = (val) => {
        if (val === 1) {
            return 'Alta';
        }
        if (val === 2) {
            return 'Média';
        }
        if (val === 3) {
            return 'Baixa';
        }
    };

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingAnnouncement &&
                    `${i18n.t('announcements.confirmationModal.deleteTitle')} ${deletingAnnouncement.name}?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteAnnouncement(deletingAnnouncement.id)}
            >
                {i18n.t('announcements.confirmationModal.deleteMessage')}
            </ConfirmationModal>
            <AnnouncementModal
                resetPagination={() => {
                    setPageNumber(1);
                    fetchAnnouncements();
                }}
                open={announcementModalOpen}
                onClose={handleCloseAnnouncementModal}
                aria-labelledby="form-dialog-title"
                announcementId={selectedAnnouncement && selectedAnnouncement.id}
            />
            <MainHeader>
                <Grid style={{ width: '99.6%' }} container>
                    <Grid xs={12} sm={8} item>
                        <Title>
                            {i18n.t('announcements.title')} ({announcements.length})
                        </Title>
                    </Grid>
                    <Grid xs={12} sm={4} item>
                        <Grid spacing={2} container>
                            <Grid xs={6} sm={6} item>
                                <TextField
                                    fullWidth
                                    placeholder={i18n.t('announcements.searchPlaceholder')}
                                    type="search"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: 'gray' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid xs={6} sm={6} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleOpenAnnouncementModal}
                                    color="primary"
                                >
                                    {i18n.t('announcements.buttons.add')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t('announcements.table.title')}</TableCell>
                            <TableCell align="center">{i18n.t('announcements.table.priority')}</TableCell>
                            <TableCell align="center">{i18n.t('announcements.table.mediaName')}</TableCell>
                            <TableCell align="center">{i18n.t('announcements.table.status')}</TableCell>
                            <TableCell align="center">{i18n.t('announcements.table.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {announcements.map((announcement) => (
                                <TableRow key={announcement.id}>
                                    <TableCell align="center">{announcement.title}</TableCell>
                                    <TableCell align="center">{translatePriority(announcement.priority)}</TableCell>
                                    <TableCell align="center">{announcement.mediaName ?? 'Sem anexo'}</TableCell>
                                    <TableCell align="center">{announcement.status ? 'ativo' : 'inativo'}</TableCell>
                                    <TableCell align="center">
                                        <IconButton size="small" onClick={() => handleEditAnnouncement(announcement)}>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={(e) => {
                                                setConfirmModalOpen(true);
                                                setDeletingAnnouncement(announcement);
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Announcements;
