import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';

import api from '../../services/api';
import { i18n } from '../../translate/i18n';


function filterById(arr, idToExclude) {
    return arr.filter(item => item.id !== idToExclude);
}

const DeleteConnectionModal = ({ open, onClose, onConfirm, whatsappId }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedConnection, setSelectedConnection] = useState(null);
    const [ticketsCount, setTicketsCount] = useState(0);
    const [connections, setConnections] = useState([]);
    const [loadingConnections, setLoadingConnections] = useState(false);

    useEffect(() => {
        if (open && whatsappId) {
            setLoadingConnections(true);
            (async () => {
                try {
                    const { data } = await api.get(`/whatsapp/`);
                    const filteredWhatsapps = filterById(data, whatsappId);
                    setConnections(filteredWhatsapps);
                } catch (error) {
                    console.error("Failed to load connections:", error);
                } finally {
                    setLoadingConnections(false);
                }
            })();
        }
    }, [open, whatsappId]);

    useEffect(() => {
        if (open && whatsappId) {
            setLoadingConnections(true);
            (async () => {
                try {
                    const { data } = await api.get(`/whatsapp/count-tickets/${whatsappId}`);
                    setTicketsCount(data.count);
                } catch (error) {
                    console.error("Failed to load connections:", error);
                } finally {
                    setLoadingConnections(false);
                }
            })();
        }
    }, [open, whatsappId]);

    const handleChangeConnection = async () => {
        setIsSubmitting(true);
        try {
            const { data } = await api.put(`/ticket/change-connection/${whatsappId}`, { whatsappId: selectedConnection.id });
            setConnections(data);
            await onConfirm();
        } catch (error) {
            console.error("Failed to load connections:", error);
        } finally {
            setIsSubmitting(false);
            setTimeout(() => {
                onClose(false);
            }, 500);
        }
    };

    const handleDeleteTicket = async () => {
        setIsSubmitting(true);
        try {
            await api.delete(`/ticket/delete-all/${whatsappId}`);
            await onConfirm();
        } catch (error) {
            console.error("Failed to delete ticket:", error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                onClose(false);
            }, 500);
        }
    };

    return (
        <Dialog open={open} onClose={() => onClose(false)} aria-labelledby="not-connection-dialog">
            <DialogTitle id="not-connection-dialog">
                {i18n.t('deleteConnectionModal.title')}
            </DialogTitle>
            <DialogContent dividers>
                <Typography variant="body1">
                    {i18n.t(`deleteConnectionModal.${ticketsCount == 0 ? 'deleteMessage' : 'message'}`)}
                </Typography>
                {ticketsCount > 0 && (
                    <>
                        <Typography variant="button">
                            {i18n.t('deleteConnectionModal.count')}{ticketsCount}
                        </Typography>

                        <Autocomplete
                            options={connections}
                            getOptionLabel={(option) => option.name}
                            value={selectedConnection}
                            onChange={(event, newValue) => {
                                if (newValue?.status === 'CONNECTED') {
                                    setSelectedConnection(newValue);
                                }
                            }}
                            loading={loadingConnections}
                            getOptionDisabled={(option) => option.status !== 'CONNECTED'}
                            renderOption={(option) => (
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    style={{ opacity: option.status === 'CONNECTED' ? 1 : 0.5 }}
                                >
                                    <Box
                                        style={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: '50%',
                                            backgroundColor:
                                                option.status === 'qrcode' ? '#ffcc00' :   // Amarelo para QR Code
                                                    option.status === 'CONNECTED' ? '#00cc00' : // Verde para conectado
                                                        '#ff0000'                                    // Vermelho para desconectado
                                        }}
                                    />
                                    <Box ml={1}>{option.name}{option?.number ? ` - ${option.number}` : ""}</Box>
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={i18n.t('deleteConnectionModal.selectConnection')}
                                    variant="outlined"
                                    margin="normal"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loadingConnections ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => onClose(false)}
                    color="default"
                    disabled={isSubmitting}
                >
                    {i18n.t('deleteConnectionModal.buttons.cancel')}
                </Button>

                {ticketsCount == 0 ? (
                    <>
                        <Button
                            variant="contained"
                            onClick={async () => {
                                setIsSubmitting(true);
                                await onConfirm();
                                setIsSubmitting(false);
                            }}
                            disabled={isSubmitting}
                            color="secondary"
                        >
                            {i18n.t('deleteConnectionModal.buttons.delete')}
                            {isSubmitting && <CircularProgress size={24} />}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            variant="contained"
                            onClick={() => { handleDeleteTicket() }}
                            disabled={isSubmitting}
                            color="secondary"
                        >
                            {i18n.t('deleteConnectionModal.buttons.closeTicket')}
                            {isSubmitting && <CircularProgress size={24} />}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => handleChangeConnection()}
                            disabled={isSubmitting || !selectedConnection}
                            color="primary"
                        >
                            {i18n.t('deleteConnectionModal.buttons.changeConnection')}
                            {isSubmitting && <CircularProgress size={24} />}
                        </Button>
                    </>
                )}

            </DialogActions>
        </Dialog >
    );
};

export default DeleteConnectionModal;
