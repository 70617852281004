import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Archive from '@material-ui/icons/Archive';
import SearchIcon from '@material-ui/icons/Search';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import IconButton from '@material-ui/core/IconButton';
import BlockIcon from '@material-ui/icons/Block';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

import ConfirmationModal from '../../components/ConfirmationModal/';
import ContactModal from '../../components/ContactModal';
import TableRowSkeleton from '../../components/TableRowSkeleton';
import api from '../../services/api';

import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Can } from '../../components/Can';
import ContactsExport from '../../components/ContactsExport';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper';
import NewTicketModal from '../../components/NewTicketModal';
import Title from '../../components/Title';
import { AuthContext } from '../../context/Auth/AuthContext';
import toastError from '../../errors/toastError';
import { i18n } from '../../translate/i18n';

import { Menu, MenuItem, Typography } from '@material-ui/core';
import { ArrowDropDown, Backup, ContactPhone } from '@material-ui/icons';
import { formatDocument } from '../../helpers/documentHelper';
import socket from '../../hooks/useSocket';
import ContactExtraInfoModal from '../../components/ContactExtraInfoModal';

const reducer = (state, action) => {
    if (action.type === 'LOAD_CONTACTS') {
        const contacts = action.payload;
        const newContacts = [];

        contacts.forEach((contact) => {
            const contactIndex = state.findIndex((c) => c.id === contact.id);
            if (contactIndex !== -1) {
                state[contactIndex] = contact;
            } else {
                newContacts.push(contact);
            }
        });

        return [...state, ...newContacts];
    }

    if (action.type === 'UPDATE_CONTACTS') {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contact.id);

        if (contactIndex !== -1) {
            state[contactIndex] = contact;
            return [...state];
        } else {
            return [contact, ...state];
        }
    }

    if (action.type === 'DELETE_CONTACT') {
        const contactId = action.payload;

        const contactIndex = state.findIndex((c) => c.id === contactId);
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1);
        }
        return [...state];
    }

    if (action.type === 'RESET') {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    exporte: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50 %, -50 %)',
        backgroundColor: 'white',
        padding: '20px',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
        zIndex: 0,
        width: '235px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const Contacts = () => {
    const classes = useStyles();
    const history = useHistory();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState('');
    const [contacts, dispatch] = useReducer(reducer, []);
    const [selectedContactId, setSelectedContactId] = useState(null);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [contactInfoModalOpen, setContactInfoModalOpen] = useState(false);
    const [deletingContact, setDeletingContact] = useState(null);
    const [ImportContacts, setImportContacts] = useState(null);
    const [blockingContact, setBlockingContact] = useState(null);
    const [unBlockingContact, setUnBlockingContact] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmChatsOpen, setConfirmChatsOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
    const [contactTicket, setContactTicket] = useState({});
    const fileUploadRef = useRef(null);
    const [showPopup, setShowPopup] = useState(false);
    const [totalContacts, setTotalContacts] = useState(0);
    const [, setQueue] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filters, setFilters] = useState([
        { type: "email", filter: false },
        { type: "document", filter: false }
    ]);

    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const handleImportExcel = async () => {
        try {
            const formData = new FormData();
            formData.append('file', fileUploadRef.current.files[0]);
            await api.request({
                url: `/contacts/upload`,
                method: 'POST',
                data: formData,
            });
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleFilter = (event) => {
        event.preventDefault();
        // Filtra os tickets por fila
    };

    const handleQueueChange = (event) => {
        setQueue(event.target.value);
    };

    useEffect(() => {
        dispatch({ type: 'RESET' });
        setPageNumber(1);
    }, [searchParam, filters]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get('/contacts/', {
                        params: { searchParam, pageNumber, filters: JSON.stringify(filters) },
                    });
                    setTotalContacts(data.count || 0);
                    dispatch({ type: 'LOAD_CONTACTS', payload: data.contacts });
                    setHasMore(data.hasMore);
                } catch (err) {
                    toastError(err);
                } finally {
                    setLoading(false);
                }
            };
            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, filters]);


    const handleFilterContacts = (filterName) => {
        setFilters((prevFilters) => {
            if (filterName === 'all') {
                return prevFilters.map(filter => ({ ...filter, filter: false }));
            } else {
                return prevFilters.map(filter =>
                    filter.type === filterName ? { ...filter, filter: !filter.filter } : filter
                );
            }
        });
    };

    const isChecked = (filterName) => {
        const filter = filters.find(f => f.type === filterName);
        return filter ? filter.filter : false;
    };

    useEffect(() => {
        const companyId = localStorage.getItem('companyId');

        socket.on(`company-${companyId}-contact`, (data) => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_CONTACTS', payload: data.contact });
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_CONTACT', payload: +data.contactId });
            }
        });

        return () => { };
    }, []);

    const handleCloseOrOpenTicket = (ticket) => {
        setNewTicketModalOpen(false);
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`);
        }
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenContactInfoModal = () => {
        setContactInfoModalOpen(true)
    }

    const handleCloseContactInfoModal = () => {
        setContactInfoModalOpen(false)
    }

    const handleOpenContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(true);
    };

    const handleCloseContactModal = () => {
        setSelectedContactId(null);
        setContactModalOpen(false);
    };

    const hadleEditContact = (contactId) => {
        setSelectedContactId(contactId);
        setContactModalOpen(true);
    };

    const handleDeleteContact = async (contactId) => {
        try {
            await api.delete(`/contacts/${contactId}`);
            toast.success(i18n.t('contacts.toasts.deleted'));
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam('');
        setPageNumber(1);
    };

    const handleBlockContact = async (contactId) => {
        try {
            await api.put(`/contacts/block/${contactId}`, { active: false });
            toast.success('Contato bloqueado');
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam('');
        setPageNumber(1);
    };

    const handleUnBlockContact = async (contactId) => {
        try {
            await api.put(`/contacts/block/${contactId}`, { active: true });
            toast.success('Contato desbloqueado');
        } catch (err) {
            toastError(err);
        }
        setDeletingContact(null);
        setSearchParam('');
        setPageNumber(1);
    };

    const handleimportContact = async () => {
        try {
            await api.post('/contacts/import');
            history.go(0);
            setImportContacts(false);
        } catch (err) {
            toastError(err);
            setImportContacts(false);
        }
    };

    const handleimportChats = async () => {
        try {
            await api.post('/contacts/import/chats');
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    function getDateLastMessage(contact) {
        if (!contact) return null;
        if (!contact.tickets) return null;

        if (contact.tickets.length > 0) {
            const date = new Date(contact.tickets[contact.tickets.length - 1].updatedAt);

            const day = (date.getDate()).toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = (date.getFullYear()).toString().padStart(2, '0');

            const hours = (date.getHours()).toString().padStart(2, '0');
            const minutes = (date.getMinutes()).toString().padStart(2, '0');

            return `${day}/${month}/${year} ${hours}:${minutes}`;
        }

        return null;
    }

    return (
        <MainContainer className={classes.mainContainer}>
            {showPopup && (
                <ContactsExport
                    className={classes.exporte}
                    handleClose={togglePopup}
                    handleFilter={handleFilter}
                    handleQueueChange={handleQueueChange}
                />
            )}
            <ContactExtraInfoModal
                open={contactInfoModalOpen}
                onClose={handleCloseContactInfoModal}
            />
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                initialContact={contactTicket}
                onClose={(ticket) => {
                    handleCloseOrOpenTicket(ticket);
                }}
            />
            <ContactModal
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}
            ></ContactModal>
            <ConfirmationModal
                title={
                    deletingContact
                        ? `${i18n.t('contacts.confirmationModal.deleteTitle')} ${deletingContact.name}?`
                        : blockingContact
                            ? `Bloquear Contato ${blockingContact.name}?`
                            : unBlockingContact
                                ? `Desbloquear Contato ${unBlockingContact.name}?`
                                : ImportContacts
                                    ? `${i18n.t('contacts.confirmationModal.importTitlte')}`
                                    : `${i18n.t('contactListItems.confirmationModal.importTitlte')}`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={() =>
                    deletingContact
                        ? handleDeleteContact(deletingContact.id)
                        : blockingContact
                            ? handleBlockContact(blockingContact.id)
                            : unBlockingContact
                                ? handleUnBlockContact(unBlockingContact.id)
                                : ImportContacts
                                    ? handleimportContact()
                                    : handleImportExcel()
                }
            >
                {deletingContact
                    ? `${i18n.t('contacts.confirmationModal.deleteMessage')}`
                    : blockingContact
                        ? `Tem certeza que deseja bloquear este contato?`
                        : unBlockingContact
                            ? `Tem certeza que deseja desbloquear este contato?`
                            : ImportContacts
                                ? `${i18n.t('contacts.confirmationModal.importMessage')}`
                                : `${i18n.t('contactListItems.confirmationModal.importMessage')}`}
            </ConfirmationModal>
            <ConfirmationModal
                title={'Importar Conversas'}
                open={confirmChatsOpen}
                onClose={setConfirmChatsOpen}
                onConfirm={() => handleimportChats()}
            >
                Deseja importar todas as conversas do telefone?
            </ConfirmationModal>
            <MainHeader>
                <Title>
                    {i18n.t('contacts.title')} ({contacts.length}/{totalContacts || 0})
                </Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t('contacts.searchPlaceholder')}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="secondary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <PopupState variant="popover" popupId="Filter">
                        {(popupState) => (
                            <React.Fragment>
                                <Button
                                    color="primary"
                                    style={{
                                        marginRight: 10,
                                    }}
                                    variant="contained"
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleFilterClick}
                                >
                                    Filtrar
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleFilterClose}
                                >
                                    <Box>
                                        <div
                                            onClick={() => handleFilterContacts('email')}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '0px 15px 0px 0px', cursor: 'pointer' }}
                                        >
                                            <Checkbox
                                                checked={isChecked('email')}
                                                onClick={(e) => { e.stopPropagation(); handleFilterContacts('email'); }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                            <Typography>Apenas com Email</Typography>
                                        </div>
                                    </Box>
                                    <Box>
                                        <div
                                            onClick={() => handleFilterContacts('document')}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '0px 15px 0px 0px', cursor: 'pointer' }}
                                        >
                                            <Checkbox
                                                checked={isChecked('document')}
                                                onClick={(e) => { e.stopPropagation(); handleFilterContacts('document'); }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                            <Typography>Apenas com Documento</Typography>
                                        </div>
                                    </Box>
                                    <Box>
                                        <div
                                            onClick={() => handleFilterContacts('all')}
                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: '0px 15px 0px 0px', cursor: 'pointer' }}
                                        >
                                            <Checkbox
                                                checked={filters.every(f => !f.filter)}
                                                onClick={(e) => { e.stopPropagation(); handleFilterContacts('all'); }}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                            <Typography>Sem Filtro</Typography>
                                        </div>
                                    </Box>
                                </Menu>
                                <Can
                                    role={user.profile}
                                    perform="contact-page:exports"
                                    yes={() => (
                                        <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                                            Importar / Exportar
                                            <ArrowDropDown />
                                        </Button>
                                    )}
                                />
                                <Menu {...bindMenu(popupState)}>
                                    <MenuItem
                                        onClick={() => {
                                            setConfirmOpen(true);
                                            setImportContacts(true);
                                            popupState.close();
                                        }}
                                    >
                                        <ContactPhone
                                            fontSize="small"
                                            color="primary"
                                            style={{
                                                marginRight: 10,
                                            }}
                                        />
                                        Agenda do Telefone
                                    </MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            fileUploadRef.current.value = null;
                                            fileUploadRef.current.click();
                                        }}
                                    >
                                        <Backup
                                            fontSize="small"
                                            color="primary"
                                            style={{
                                                marginRight: 10,
                                            }}
                                        />
                                        Importar CSV
                                    </MenuItem>
                                    <MenuItem onClick={togglePopup}>
                                        <Archive
                                            fontSize="small"
                                            color="primary"
                                            style={{
                                                marginRight: 10,
                                            }}
                                        />
                                        Exportar CSV
                                    </MenuItem>
                                    {/* <MenuItem
                                        onClick={() => {
                                            <>
                                                <CSVLink
                                                    className={classes.csvbtn}
                                                    separator=";"
                                                    filename={'contacts.xlsx'}
                                                    data={
                                                        contacts.map((contact) => ({
                                                            name: contact.name,
                                                            number: contact.number,
                                                            email: contact.email
                                                        }))
                                                    }>
                                                    <Button
                                                        variant="contained"
                                                        color="primary">
                                                        <Archive />
                                                          {i18n.t("contacts.buttons.export")}
                                                    </Button>
                                                </CSVLink>
                                            </>

                                            // handleExportExcel();
                                            // popupState.close();
                                        }}
                                    >
                                        <CloudDownload
                                            fontSize="small"
                                            color="primary"
                                            style={{
                                                marginRight: 10,
                                            }}
                                        />
                                        Exportar Excel
                                    </MenuItem> */}
                                </Menu>
                            </React.Fragment>
                        )}
                    </PopupState>
                    <Button variant="contained" color="primary" onClick={handleOpenContactModal}>
                        {i18n.t('contacts.buttons.add')}
                    </Button>
                    {user?.profile === "admin" && <Button variant="contained" color="primary" onClick={handleOpenContactInfoModal}>
                        {i18n.t('contacts.buttons.info')}
                    </Button>}
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
                <>
                    <input
                        style={{ display: 'none' }}
                        id="upload"
                        name="file"
                        type="file"
                        accept=".xls,.xlsx"
                        onChange={() => {
                            setConfirmOpen(true);
                        }}
                        ref={fileUploadRef}
                    />
                </>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" />
                            <TableCell>{i18n.t('contacts.table.name')}</TableCell>
                            <TableCell align="center">{i18n.t('contacts.table.whatsapp')}</TableCell>
                            <TableCell align="center">{i18n.t('contacts.table.email')}</TableCell>
                            <TableCell align="center">{i18n.t('contacts.table.document')}</TableCell>
                            <TableCell align="center">{'Última Mensagem'}</TableCell>
                            <TableCell align="center">{'Status'}</TableCell>
                            <TableCell align="center">{i18n.t('contacts.table.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {contacts.map((contact) => (
                                <TableRow key={contact.id}>
                                    <TableCell style={{ paddingRight: 0 }}>
                                        {<Avatar src={contact.profilePicUrl} />}
                                    </TableCell>
                                    <TableCell>{contact.name}</TableCell>
                                    <TableCell align="center">{contact.number}</TableCell>
                                    <TableCell align="center">{contact.email}</TableCell>
                                    <TableCell align="center">{formatDocument(contact.document)}</TableCell>
                                    <TableCell align="center">{getDateLastMessage(contact)}</TableCell>
                                    <TableCell align="center">
                                        {contact.active ? (
                                            <CheckCircleIcon style={{ color: 'green' }} fontSize="small" />
                                        ) : (
                                            <CancelIcon style={{ color: 'red' }} fontSize="small" />
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setContactTicket(contact);
                                                setNewTicketModalOpen(true);
                                                // handleSaveTicket(contact.id);
                                            }}
                                        >
                                            <WhatsAppIcon color="secondary" />
                                        </IconButton>

                                        <IconButton size="small" onClick={() => hadleEditContact(contact.id)}>
                                            <EditIcon color="secondary" />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            onClick={
                                                contact.active
                                                    ? () => {
                                                        setConfirmOpen(true);
                                                        setBlockingContact(contact);
                                                    }
                                                    : () => {
                                                        setConfirmOpen(true);
                                                        setUnBlockingContact(contact);
                                                    }
                                            }
                                        >
                                            {contact.active ? (
                                                <BlockIcon color="secondary" />
                                            ) : (
                                                <CheckCircleIcon color="secondary" />
                                            )}
                                        </IconButton>
                                        <Can
                                            role={user.profile}
                                            perform="contacts-page:deleteContact"
                                            yes={() => (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setConfirmOpen(true);
                                                        setDeletingContact(contact);
                                                    }}
                                                >
                                                    <DeleteOutlineIcon color="secondary" />
                                                </IconButton>
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton avatar columns={6} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    );
};

export default Contacts;
