import React, { useContext } from 'react';
import { Redirect, Route as RouterRoute } from 'react-router-dom';

import BackdropLoading from '../components/BackdropLoading';
import { AuthContext } from '../context/Auth/AuthContext';

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
    const { isAuth, loading } = useContext(AuthContext);

    if (!isAuth && isPrivate) {
        return (
            <>
                {loading && <BackdropLoading />}
                <Redirect to={{ pathname: '/login', state: { from: rest.location } }} />
            </>
        );
    }

    if (isAuth && !isPrivate) {
        return (
            <>
                {loading && <BackdropLoading />}
                <Redirect to={{ pathname: '/', state: { from: rest.location } }} />;
            </>
        );
    }

    return (
        <>
            {loading && <BackdropLoading />}
            <RouterRoute {...rest} component={Component} />
        </>
    );
};

export default Route;
