import { format, parseISO } from 'date-fns';
import React, { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import {
    Button,
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import {
    CheckCircle,
    CropFree,
    DeleteOutline,
    Edit,
    SignalCellular4Bar,
    SignalCellularConnectedNoInternet0Bar,
    SignalCellularConnectedNoInternet2Bar,
    WhatsApp
} from '@material-ui/icons';

import TableRowSkeleton from '../../components/TableRowSkeleton';

import ConfirmationModal from '../../components/ConfirmationModal';
import QrcodeModal from '../../components/QrcodeModal';
import WhatsAppModal from '../../components/WhatsAppModal';
import { WhatsAppsContext } from '../../context/WhatsApp/WhatsAppsContext';
import toastError from '../../errors/toastError';
import api from '../../services/api';
import { i18n } from '../../translate/i18n';
import formatSerializedId from '../../utils/formatSerializedId';
import ConnectionListContainer from './ConnectionListContainer';
import { useStyles } from './style';
import DeleteConnectionModal from '../../components/DeleteConnectionModal';

const CustomToolTip = ({ title, content, children }) => {
    const classes = useStyles();

    return (
        <Tooltip
            arrow
            classes={{
                tooltip: classes.tooltip,
                popper: classes.tooltipPopper,
            }}
            title={
                <React.Fragment>
                    <Typography gutterBottom color="inherit">
                        {title}
                    </Typography>
                    {content && <Typography>{content}</Typography>}
                </React.Fragment>
            }
        >
            {children}
        </Tooltip>
    );
};

const WhatsAppList = () => {
    const classes = useStyles();

    const { whatsApps, loading } = useContext(WhatsAppsContext);
    const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
    const [qrModalOpen, setQrModalOpen] = useState(false);
    const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [confirmModalDisconnectOpen, setConfirmModalDisconnectOpen] = useState(false);
    const confirmationModalInitialState = {
        action: '',
        title: '',
        message: '',
        whatsAppId: '',
        emailSettinsId: '',
        open: false,
    };
    const [confirmModalInfo, setConfirmModalInfo] = useState(confirmationModalInitialState);

    const handleStartWhatsAppSession = async (whatsAppId) => {
        try {
            await api.post(`/whatsappsession/${whatsAppId}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleRequestNewQrCode = async (whatsAppId) => {
        try {
            await api.put(`/whatsappsession/${whatsAppId}`);
        } catch (err) {
            toastError(err);
        }
    };

    const handleOpenWhatsAppModal = () => {
        setSelectedWhatsApp(null);
        setWhatsAppModalOpen(true);
    };

    const handleCloseWhatsAppModal = useCallback(() => {
        setWhatsAppModalOpen(false);
        setSelectedWhatsApp(null);
    }, [setSelectedWhatsApp, setWhatsAppModalOpen]);

    const handleOpenQrModal = (whatsApp) => {
        setSelectedWhatsApp(whatsApp);
        setQrModalOpen(true);
    };

    const handleCloseQrModal = useCallback(() => {
        setSelectedWhatsApp(null);
        setQrModalOpen(false);
    }, [setQrModalOpen, setSelectedWhatsApp]);

    const handleEditWhatsApp = (whatsApp) => {
        setSelectedWhatsApp(whatsApp);
        setWhatsAppModalOpen(true);
    };

    const openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleOpenConfirmationModal = (action, id) => {
        if (action === 'disconnect') {
            setConfirmModalInfo({
                action: action,
                title: i18n.t('connections.confirmationModal.disconnectTitle'),
                message: i18n.t('connections.confirmationModal.disconnectMessage'),
                whatsAppId: id,
            });
            setConfirmModalDisconnectOpen(true);
        }

        if (action === 'delete') {
            setConfirmModalInfo({
                action: action,
                title: i18n.t('connections.confirmationModal.deleteTitle'),
                message: i18n.t('connections.confirmationModal.deleteMessage'),
                whatsAppId: id,
            });
            setConfirmModalOpen(true);
        }

    };

    const handleSubmitConfirmationModal = async () => {
        if (confirmModalInfo.action === 'disconnect') {
            try {
                await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
            } catch (err) {
                toastError(err);
            }
        }

        if (confirmModalInfo.action === 'delete') {
            try {
                await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
                toast.success(i18n.t('connections.toasts.deleted'));
            } catch (err) {
                toastError(err, 4000);
            }
        }

        setConfirmModalInfo(confirmationModalInitialState);
    };

    const renderActionButtons = (whatsApp) => {
        return (
            <>
                {whatsApp.status === 'qrcode' && (
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenQrModal(whatsApp)}
                    >
                        {i18n.t('connections.buttons.qrcode')}
                    </Button>
                )}
                {whatsApp.status === 'DISCONNECTED' && (
                    <>
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => handleStartWhatsAppSession(whatsApp.id)}
                        >
                            {i18n.t('connections.buttons.tryAgain')}
                        </Button>{' '}
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleRequestNewQrCode(whatsApp.id)}
                        >
                            {i18n.t('connections.buttons.newQr')}
                        </Button>
                    </>
                )}
                {(whatsApp.status === 'CONNECTED' ||
                    whatsApp.status === 'PAIRING' ||
                    whatsApp.status === 'TIMEOUT') && (
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                handleOpenConfirmationModal('disconnect', whatsApp.id);
                            }}
                        >
                            {i18n.t('connections.buttons.disconnect')}
                        </Button>
                    )}
                {whatsApp.status === 'OPENING' && (
                    <Button size="small" variant="outlined" disabled color="default">
                        {i18n.t('connections.buttons.connecting')}
                    </Button>
                )}
            </>
        );
    };

    const renderStatusToolTips = (whatsApp) => {
        return (
            <div className={classes.customTableCell}>
                {whatsApp.status === 'DISCONNECTED' && (
                    <CustomToolTip
                        title={i18n.t('connections.toolTips.disconnected.title')}
                        content={i18n.t('connections.toolTips.disconnected.content')}
                    >
                        <SignalCellularConnectedNoInternet0Bar color="secondary" />
                    </CustomToolTip>
                )}
                {whatsApp.status === 'OPENING' && <CircularProgress size={24} className={classes.buttonProgress} />}
                {whatsApp.status === 'qrcode' && (
                    <CustomToolTip
                        title={i18n.t('connections.toolTips.qrcode.title')}
                        content={i18n.t('connections.toolTips.qrcode.content')}
                    >
                        <CropFree />
                    </CustomToolTip>
                )}
                {whatsApp.status === 'CONNECTED' && (
                    <CustomToolTip title={i18n.t('connections.toolTips.connected.title')}>
                        <SignalCellular4Bar style={{ color: green[500] }} />
                    </CustomToolTip>
                )}
                {(whatsApp.status === 'TIMEOUT' || whatsApp.status === 'PAIRING') && (
                    <CustomToolTip
                        title={i18n.t('connections.toolTips.timeout.title')}
                        content={i18n.t('connections.toolTips.timeout.content')}
                    >
                        <SignalCellularConnectedNoInternet2Bar color="secondary" />
                    </CustomToolTip>
                )}
            </div>
        );
    };

    const restartWhatsapps = async () => {
        // const companyId = localStorage.getItem("companyId");
        try {
            await api.post(`/whatsapp-restart/`);
            toast.success(i18n.t('Aguarde... Suas conexões serão reiniciadas!'));
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <>
            <ConnectionListContainer
                title={i18n.t('connections.whatsapp.title')}
                actionName={i18n.t('connections.whatsapp.addNew')}
                otherActions={
                    <>
                        <Button variant="contained" color="primary" onClick={restartWhatsapps}>
                            {i18n.t('connections.whatsapp.restart')}
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => openInNewTab(`https://wa.me/${process.env.REACT_APP_NUMBER_SUPPORT}`)}
                        >
                            {i18n.t('connections.whatsapp.support')}
                        </Button>
                    </>
                }
                onAddNew={handleOpenWhatsAppModal}
            >
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{i18n.t('connections.whatsapp.channel')}</TableCell>
                            <TableCell align="center">{i18n.t('connections.whatsapp.name')}</TableCell>
                            <TableCell align="center">{i18n.t('connections.whatsapp.number')}</TableCell>
                            <TableCell align="center">{i18n.t('connections.whatsapp.status')}</TableCell>
                            <TableCell align="center">{i18n.t('connections.whatsapp.session')}</TableCell>
                            <TableCell align="center">{i18n.t('connections.whatsapp.lastUpdate')}</TableCell>
                            <TableCell align="center">{i18n.t('connections.whatsapp.default')}</TableCell>
                            <TableCell align="center">{i18n.t('connections.whatsapp.actions')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRowSkeleton />
                        ) : (
                            <>
                                {whatsApps?.length > 0 &&
                                    whatsApps.map((whatsApp) => (
                                        <TableRow key={whatsApp.id}>
                                            <TableCell align="center">
                                                {<WhatsApp style={{ color: '#25d366' }} />}
                                            </TableCell>
                                            <TableCell align="center">{whatsApp.name}</TableCell>
                                            <TableCell align="center">
                                                {whatsApp.number ? <>{formatSerializedId(whatsApp.number)}</> : '-'}
                                            </TableCell>
                                            <TableCell align="center">{renderStatusToolTips(whatsApp)}</TableCell>
                                            <TableCell align="center">{renderActionButtons(whatsApp)}</TableCell>
                                            <TableCell align="center">
                                                {format(parseISO(whatsApp.updatedAt), 'dd/MM/yy HH:mm')}
                                            </TableCell>
                                            <TableCell align="center">
                                                {whatsApp.isDefault && (
                                                    <div className={classes.customTableCell}>
                                                        <CheckCircle style={{ color: green[500] }} />
                                                    </div>
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton size="small" onClick={() => handleEditWhatsApp(whatsApp)}>
                                                    <Edit />
                                                </IconButton>

                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        handleOpenConfirmationModal('delete', whatsApp.id);
                                                    }}
                                                >
                                                    <DeleteOutline />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </ConnectionListContainer>
            <DeleteConnectionModal
                whatsappId={confirmModalInfo?.whatsAppId}
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={handleSubmitConfirmationModal}
            />
            <ConfirmationModal
                title={confirmModalInfo.title}
                open={confirmModalDisconnectOpen}
                onClose={setConfirmModalDisconnectOpen}
                onConfirm={handleSubmitConfirmationModal}
            >
                {confirmModalInfo.message}
            </ConfirmationModal>
            <QrcodeModal
                open={qrModalOpen}
                onClose={handleCloseQrModal}
                whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
            />
            <WhatsAppModal
                open={whatsAppModalOpen}
                onClose={handleCloseWhatsAppModal}
                whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
            />
        </>
    );
};

export default WhatsAppList;
