import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import React from 'react';

import TicketAdvanced from '../TicketsAdvanced';
import Tickets from '../TicketsCustom';

function TicketResponsiveContainer(props) {
    if (isWidthUp('md', props.width)) {
        return <Tickets />;
    }
    return <TicketAdvanced />;
}

export default withWidth()(TicketResponsiveContainer);
