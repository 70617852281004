import { Button, Paper, Tab, Tabs, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import MainContainer from '../../components/MainContainer';
import MainHeader from '../../components/MainHeader';
import Title from '../../components/Title';

import TabPanel from '../../components/TabPanel';

import CompaniesManager from '../../components/CompaniesManager';
import HelpsManager from '../../components/HelpsManager';
import PlansManager from '../../components/PlansManager';
import SchedulesForm from '../../components/SchedulesForm';
import Options from '../../components/Settings/Options';

import { toast } from 'react-toastify';
import { i18n } from '../../translate/i18n.js';

import useAuth from '../../hooks/useAuth.js';
import useCompanies from '../../hooks/useCompanies';
import useSettings from '../../hooks/useSettings';

import { Grid, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import OnlyForSuperUser from '../../components/OnlyForSuperUser';
import api from '../../services/api.js';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
    },
    mainPaper: {
        ...theme.scrollbarStyles,
        overflowY: 'scroll',
        flex: 1,
    },
    tab: {
        // background: "#f2f5f3",
        backgroundColor: theme.mode === 'light' ? '#f2f2f2' : '#7f7f7f',
        borderRadius: 4,
    },
    paper: {
        ...theme.scrollbarStyles,
        overflowY: 'scroll',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    container: {
        width: '100%',
        maxHeight: '100%',
    },
    control: {
        padding: theme.spacing(1),
    },
    textfield: {
        width: '100%',
    },
}));

const SettingsCustom = () => {
    const classes = useStyles();
    const [tab, setTab] = useState('options');
    const [schedules, setSchedules] = useState([]);
    const [company, setCompany] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [settings, setSettings] = useState({});
    const [schedulesEnabled, setSchedulesEnabled] = useState(false);

    const { getCurrentUserInfo } = useAuth();
    const { find, updateSchedules } = useCompanies();
    const { getAll: getAllSettings } = useSettings();

    useEffect(() => {
        async function findData() {
            setLoading(true);
            try {
                const companyId = localStorage.getItem('companyId');
                const company = await find(companyId);
                const settingList = await getAllSettings();
                setCompany(company);
                setSchedules(company.schedules);
                setSettings(settingList);

                if (Array.isArray(settingList)) {
                    const scheduleType = settingList.find((d) => d.key === 'scheduleType');
                    if (scheduleType) {
                        setSchedulesEnabled(scheduleType.value === 'company');
                    }
                }

                const user = await getCurrentUserInfo();
                setCurrentUser(user);
            } catch (e) {
                toast.error(e);
            }
            setLoading(false);
        }
        findData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    const handleSubmitSchedules = async (data) => {
        setLoading(true);
        try {
            setSchedules(data);
            await updateSchedules({ id: company.id, schedules: data });
            toast.success('Horários atualizados com sucesso.');
        } catch (e) {
            toast.error(e);
        }
        setLoading(false);
    };

    const isSuper = () => {
        return currentUser.super;
    };

    return (
        <MainContainer className={classes.root}>
            <MainHeader>
                <Title>{i18n.t('settings.title')}</Title>
            </MainHeader>
            <Paper className={classes.mainPaper} elevation={1}>
                <Tabs
                    value={tab}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="on"
                    variant="scrollable"
                    onChange={handleTabChange}
                    className={classes.tab}
                >
                    <Tab label="Opções" value={'options'} />
                    <Tab label="MSG automática " value={'automaticMessage'} />
                    {schedulesEnabled && <Tab label="Horários" value={'schedules'} />}
                    {isSuper() ? <Tab label="Empresas" value={'companies'} /> : null}
                    {isSuper() ? <Tab label="Planos" value={'plans'} /> : null}
                    {isSuper() ? <Tab label="Ajuda" value={'helps'} /> : null}
                </Tabs>

                <Paper className={classes.paper} elevation={0}>
                    <TabPanel className={classes.container} value={tab} name={'schedules'}>
                        <SchedulesForm loading={loading} onSubmit={handleSubmitSchedules} initialValues={schedules} />
                    </TabPanel>

                    <TabPanel className={classes.container} value={tab} name={'automaticMessage'}>
                        <TransferMessagesForm />
                    </TabPanel>

                    <OnlyForSuperUser
                        user={currentUser}
                        yes={() => (
                            <TabPanel className={classes.container} value={tab} name={'companies'}>
                                <CompaniesManager />
                            </TabPanel>
                        )}
                    />

                    <OnlyForSuperUser
                        user={currentUser}
                        yes={() => (
                            <TabPanel className={classes.container} value={tab} name={'plans'}>
                                <PlansManager />
                            </TabPanel>
                        )}
                    />

                    <OnlyForSuperUser
                        user={currentUser}
                        yes={() => (
                            <TabPanel className={classes.container} value={tab} name={'helps'}>
                                <HelpsManager />
                            </TabPanel>
                        )}
                    />

                    <TabPanel className={classes.container} value={tab} name={'options'}>
                        <Options
                            settings={settings}
                            scheduleTypeChanged={(value) => setSchedulesEnabled(value === 'company')}
                        />
                    </TabPanel>
                </Paper>
            </Paper>
        </MainContainer>
    );
};

export default SettingsCustom;

const initialValues = {
    transferQueue: null,
    transferUser: null,
    transferQueueAndUser: null,
    messageFromVoiceCall: null,
    messageFromPosition: null,
    messageFromAudioFile: null,
};

export const TransferMessagesForm = () => {
    const [values, setValues] = useState(initialValues);

    useEffect(() => {
        handleGetMessages();
    }, []);

    const handleSaveMessages = async (values, actions) => {
        try {
            actions.setSubmitting(true);

            const { data } = await api.post('/companies/messages', values);
            setValues(data);
            toast.success('Mensagens salvas com sucesso');
        } catch (error) {
            console.log(error);
        } finally {
            actions.setSubmitting(false);
        }
    };

    const handleGetMessages = async () => {
        try {
            const { data } = await api.get('/automatic-messages');
            setValues(data);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Formik
            initialValues={values}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
                handleSaveMessages(values, actions);
            }}
        >
            {({ touched, errors, isSubmitting, values, setFieldValue }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Stack>
                                <TextField
                                    value={values.transferQueue}
                                    onChange={(e) => setFieldValue('transferQueue', e.target.value)}
                                    label="Mensagem de transferência de fila"
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                                <Typography variant="caption" color="textSecondary">
                                    Mensagem enviada ao transferir um cliente para outra fila. Utilize a variável{' '}
                                    {`{{queueName}}`} para exibir o nome da fila.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack>
                                <TextField
                                    value={values.transferUser}
                                    onChange={(e) => setFieldValue('transferUser', e.target.value)}
                                    label="Mensagem de transferência de usuário"
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />

                                <Typography variant="caption" color="textSecondary">
                                    Mensagem enviada ao transferir um cliente para outro atendente. Utilize a variável{' '}
                                    {`{{userName}}`} para exibir o nome do novo atendente.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack>
                                <TextField
                                    value={values.transferQueueAndUser}
                                    onChange={(e) => setFieldValue('transferQueueAndUser', e.target.value)}
                                    label="Mensagem de transferência de fila e usuário"
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                                <Typography variant="caption" color="textSecondary">
                                    Mensagem enviada ao transferir um cliente para outra fila e outro atendente. Utilize
                                    as variáveis {`{{queueName}}`} e {`{{userName}}`} para exibir o nome da fila e do
                                    novo atendente.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack>
                                <TextField
                                    value={values.messageFromVoiceCall}
                                    onChange={(e) => setFieldValue('messageFromVoiceCall', e.target.value)}
                                    label="Mensagem enviada após chamada telefônica perdida"
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                                <Typography variant="caption" color="textSecondary">
                                    Mensagem enviada ao receber uma chamada de audio ou video via Whatsapp. Nenhuma
                                    variável é suportada.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack>
                                <TextField
                                    value={values.messageFromPosition}
                                    onChange={(e) => setFieldValue('messageFromPosition', e.target.value)}
                                    label="Mensagem de posição na fila"
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                                <Typography variant="caption" color="textSecondary">
                                    Mensagem enviada para o cliente ao entrar na fila de atendimento. Utilize a variável{' '}
                                    {`{{position}}`} para exibir a posição atual na fila.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack>
                                <TextField
                                    value={values.messageFromAudioFile}
                                    onChange={(e) => setFieldValue('messageFromAudioFile', e.target.value)}
                                    label="Mensagem de recebimentos de áudio automática "
                                    InputLabelProps={{ shrink: true }}
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    margin="dense"
                                    fullWidth
                                />
                                <Typography variant="caption" color="textSecondary">
                                    Mensagem enviada ao receber um audio via Whatsapp. Nenhuma variável é suportada.
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <Stack direction="row" spacing={2}>
                                {/* <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  disabled={isSubmitting}
                >
                  cancelar
                </Button> */}

                                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                    Salvar
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};
