import api from '../../services/api';

const useEmailContactFilter = () => {
    const find = async (id) => {
        const { data } = await api.request({
            url: `/emails/contacts/filters/${id}`,
            method: 'GET',
        });
        return data;
    };
    
    const list = async (params) => {
        const { data } = await api.request({
            url: '/emails/contacts/filters',
            method: 'GET',
            params,
        });
        return data;
    };

    const listCategories = async () => {
        const { data } = await api.request({
            url: '/emails/contacts/filters/categories',
            method: 'GET',
        });
        return data;
    };

    const deleteFilter = async (id) => {
        await api.request({
            url: `/emails/contacts/filters/${id}`,
            method: 'DELETE',
        });
    };

    const save = async (values) => {
        await api.request({
            url: '/emails/contacts/filters',
            method: 'POST',
            data: values,
        });
    };

    return {
        find,
        list,
        listCategories,
        deleteFilter,
        save,
    };
};

export default useEmailContactFilter;
